import { action, computed, makeObservable } from 'mobx';
import { I18nModel } from 'src/context/i18n/I18nModel';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { DateFieldModel } from 'src/shared/ui/inputs/datetime/DateFieldModel';
import { TimePickerFieldModel } from 'src/shared/ui/inputs/datetime/TimePickerFieldModel';
import { TextFieldModel } from 'src/shared/ui/inputs/text/TextFieldModel';

interface LYGEditGameDataModalProps {
  i18n: I18nModel;
  game: LYGCompetitionGame;
}

interface EditGameData {
  date: Date;
  courtNumber: number;
  gameId: number;
  competitionId: number;
}

export class LYGEditGameDataModel {
  readonly date: DateFieldModel;
  readonly time: TimePickerFieldModel;
  readonly court: TextFieldModel;
  game: LYGCompetitionGame;

  constructor(props: LYGEditGameDataModalProps) {
    this.game = props.game;
    this.date = new DateFieldModel({
      i18n: props.i18n,
      title: 'Date',
      required: true,
      id: 'date',
      initialValue: props.game.date,
    });
    this.time = new TimePickerFieldModel({
      i18n: props.i18n,
      title: 'Time',
      required: true,
      id: 'time',
      initialValue: '',
    });
    this.time.setDefaultValueFromDate(props.game.date);
    this.court = new TextFieldModel({
      i18n: props.i18n,
      title: 'Court',
      required: true,
      id: 'court',
      initialValue: props.game.court.toString(),
    });
    makeObservable(this, {
      hasChanged: computed,
      hasError: computed,
      data: computed,
      reset: action.bound,
    });
  }

  get data(): EditGameData {
    const date = this.date.value || new Date();
    const startTime = this.time.valueAsNumbers;
    if (startTime) {
      date.setHours(startTime.hours);
      date.setMinutes(startTime.minutes);
    }

    return {
      date,
      courtNumber: Number(this.court.value),
      gameId: this.game.id,
      competitionId: this.game.competitionId,
    };
  }

  get hasChanged(): boolean {
    return this.date.hasChanged || this.time.hasChanged || this.court.hasChanged;
  }

  get hasError(): boolean {
    return this.date.hasError || this.time.hasError || this.court.hasError;
  }

  reset(): void {
    this.date.reset();
    this.time.reset();
    this.court.reset();
  }
}
