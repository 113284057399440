export interface PgSwimmingEntry {
  swimmingEvent: string;
  fourTimesTwentyFiveMetersBackstroke: boolean;
  fourTimesTwentyFiveMetersFreestyle: boolean;
  reserve: boolean;
}

export class PgSwimmingEntry {
  public constructor(params: OnlyData<PgSwimmingEntry>) {
    Object.assign(this, params);
  }
}
