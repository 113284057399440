import React from 'react';

type FlatLocaleData = Record<string, unknown>;

export type LocaleData<T = unknown | FlatLocaleData> = Record<string, T>;

function flattenLocaleData(localeData: LocaleData, prefix?: string): FlatLocaleData {
  const localeKeys = Object.keys(localeData);
  let flatLocaleData: FlatLocaleData = {};

  for (let index = 0, length = localeKeys.length; index < length; index += 1) {
    const key = localeKeys[index];
    const phrase = localeData[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof phrase === 'object' && !Array.isArray(phrase)) {
      flatLocaleData = {
        ...flatLocaleData,
        ...flattenLocaleData(phrase as LocaleData, prefixedKey),
      };
    } else {
      flatLocaleData[prefixedKey] = phrase;
    }
  }

  return flatLocaleData;
}

function interpolate(value: string, config: Record<string, string>): string {
  const matches = value.matchAll(/{{.+?}}/g);
  const replaced: string[] = [];

  for (const match of matches) {
    const token = match[0];

    if (replaced.indexOf(token) < 0) {
      const propertyValue = config[token.replace(/{{|}}/g, '')];

      if (propertyValue) {
        value = value.replace(new RegExp(token, 'g'), propertyValue);
        replaced.push(token);
      }
    }
  }

  return value;
}

export class I18nModel {
  private localeData: FlatLocaleData;

  constructor(localeData: LocaleData) {
    this.localeData = typeof localeData !== 'object' ? {} : flattenLocaleData(localeData);
  }

  /**
   * Translation function.
   *
   * @param key The key used to identify the term or phrase to translate.
   * @param config An object that contains properties needed for interpolation.
   */
  t<T = string>(key: string, config?: Record<string, string>, defaultValue?: string): T {
    const rawValue = this.localeData[key];
    let value = rawValue !== undefined ? rawValue : defaultValue || `[LOCALE KEY MISSING: ${key}]`;

    if (config && typeof value === 'string') {
      value = interpolate(value, config);
    }

    return value as T;
  }

  /**
   * Use this instead of t() method when you need to translate a phrase that contains HTML.
   */
  th(key: string, config?: Record<string, string>): JSX.Element {
    return <span dangerouslySetInnerHTML={{ __html: this.t(key, config) }} />;
  }
}
