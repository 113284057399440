import React from 'react';

export const MegaphoneIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24' {...props}>
      <defs>
        <clipPath id='clip-megaphone'>
          <rect width='24' height='24' />
        </clipPath>
      </defs>
      <g id='megaphone' clipPath='url(#clip-megaphone)'>
        <g id='megaphone-2' data-name='megaphone' transform='translate(2 3)'>
          <path
            id='Combined_Shape'
            data-name='Combined Shape'
            d='M4,16V12L1,11A4.38,4.38,0,0,1,0,8,4.38,4.38,0,0,1,1,5L14,0a27.674,27.674,0,0,1,1,8c0,5-1,7-1,7L8,13v3a2.148,2.148,0,0,1-2,2A2.148,2.148,0,0,1,4,16ZM19,6a2.6,2.6,0,0,1,1,2,2.6,2.6,0,0,1-1,2Z'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='2'
          />
        </g>
      </g>
    </svg>
  );
};
