import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { LYGCompetitionBracket } from 'src/lyg/entities/competitions/LYGCompetitionBracket';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { LYG_COMPETITIONS_KEYS } from 'src/lyg/pages/competitions/queryKeys';
import { ApiError } from 'src/lyg/utils/apiError';
import { apiClient } from 'src/shared/utils/apiClient';

interface GetCompetitionBracketResponse {
  result: {
    created_at: DateTimeString;
    lyg_competition_id: number;
    lyg_court_number: number;
    lyg_event_id: number;
    lyg_game_date: DateTimeString;
    lyg_game_id: number;
    lyg_is_bracket: 1 | 0;
    lyg_pool_id: number;
    lyg_pool_name: string;
    lyg_team_1: number;
    lyg_team_2: number;
    lyg_stage: string;
    team_1_scores: number;
    team_2_scores: number;
    team_1_name: string;
    team_2_name: string;
    venue_id: number;
    venue_name: string;
    scores: {
      lyg_score_1: number;
      lyg_score_2: number;
      lyg_quarter_number: number;
    }[];
  }[];
  canAdvance: boolean;
}

async function getBracket(competitionId: number, token?: AccessToken): Promise<GetCompetitionBracketResponse> {
  if (!token) {
    return apiClient(`/v1/lyg/competitions/${competitionId}/bracket`);
  }
  return apiClient(`/v1/lyg/competitions/${competitionId}/bracket`, { token });
}

export function useLYGCompetitionBracketQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }

  return useQuery<LYGCompetitionBracket, ApiError>(
    LYG_COMPETITIONS_KEYS.bracket(competitionId),
    async () => {
      const { result, canAdvance } = await getBracket(competitionId, token);

      const groupedGames = result.reduce((acc, game) => {
        const currentGame = new LYGCompetitionGame({
          competitionId: game.lyg_competition_id,
          court: game.lyg_court_number,
          date: new Date(game.lyg_game_date),
          teamAName: game.team_1_name,
          teamBName: game.team_2_name,
          teamAId: game.lyg_team_1,
          teamBId: game.lyg_team_2,
          id: game.lyg_game_id,
          teamAScore: game.team_1_scores || 0,
          teamBScore: game.team_2_scores || 0,
          scoreBreakdown: game.scores.length
            ? game.scores.map(score => ({
                period: score.lyg_quarter_number || 1,
                teamAScore: score.lyg_score_1,
                teamBScore: score.lyg_score_2,
              }))
            : [{ period: 1, teamAScore: 0, teamBScore: 0 }],
        });
        if (acc[game.lyg_stage]) acc[game.lyg_stage].push(currentGame);
        else acc[game.lyg_stage] = [currentGame];
        return acc;
      }, {} as Record<string, LYGCompetitionGame[]>);

      const rounds = Object.entries(groupedGames).map(([title, games]) => ({ title, games }));
      rounds.sort((a, b) => b.games.length - a.games.length);

      return new LYGCompetitionBracket({
        rounds,
        hasEnded: result.every(game => game.lyg_team_1 && game.lyg_team_2),
        // TODO: compute (or ask db) for this
        canMoveToNextPhase: canAdvance,
      });
    },
    { staleTime: 1000 * 60 * 5 },
  );
}
