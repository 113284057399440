import React from 'react';

export const PlayerUnknownIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_5182_19329)'>
      <path
        d='M16.0518 21.6211V19.6211C16.0518 18.5602 15.6303 17.5428 14.8802 16.7927C14.13 16.0425 13.1126 15.6211 12.0518 15.6211H5.05176C3.99089 15.6211 2.97348 16.0425 2.22333 16.7927C1.47319 17.5428 1.05176 18.5602 1.05176 19.6211V21.6211'
        stroke='#FC5352'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.55176 11.6211C10.7609 11.6211 12.5518 9.83023 12.5518 7.62109C12.5518 5.41195 10.7609 3.62109 8.55176 3.62109C6.34262 3.62109 4.55176 5.41195 4.55176 7.62109C4.55176 9.83023 6.34262 11.6211 8.55176 11.6211Z'
        stroke='#FC5352'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 7.00448C17.2351 6.33614 17.6992 5.77258 18.31 5.41361C18.9208 5.05463 19.6389 4.92341 20.3372 5.04319C21.0355 5.16296 21.6688 5.526 22.1251 6.068C22.5813 6.61001 22.8311 7.296 22.83 8.00448C22.83 10.0045 19.83 11.0045 19.83 11.0045'
        stroke='#FC5352'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.9102 15.0039H19.9202'
        stroke='#FC5352'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_5182_19329'>
        <rect width='24' height='24' fill='white' transform='translate(0.0517578 0.621094)' />
      </clipPath>
    </defs>
  </svg>
);
