import React from 'react';
import { CornerRightDown } from 'react-feather';
import { useLYGComputeNextBracketPhaseMutation } from 'src/lyg/pages/competitions/bracket/mutation';
import { CancelButton, PrimaryButton } from 'src/shared/ui/buttons';
import { ModalDialog } from 'src/shared/ui/modal/ModalDialog';
import { ErrorMessage } from 'src/shared/ui/utils/ErrorMessage';

interface LYGMoveBracketToNextPhaseButtonProps {
  competitionId: number;
  isDisabled: boolean;
}

export const LYGMoveBracketToNextPhaseButton: React.FC<LYGMoveBracketToNextPhaseButtonProps> = ({
  competitionId,
  isDisabled,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { mutate, isLoading, error } = useLYGComputeNextBracketPhaseMutation(competitionId);

  return (
    <>
      <PrimaryButton disabled={isDisabled} className='d-flex gap-2 align-items-center' onClick={() => setIsOpen(true)}>
        <CornerRightDown />
        Move to next phase
      </PrimaryButton>
      {isOpen && (
        <ModalDialog title='Move to next phase' closeDialog={() => setIsOpen(false)}>
          <p>
            Are you sure you want to move to the next bracket phase? This will pick the winners and assign them to the
            next game and cannot be undone
          </p>

          {error && <ErrorMessage message={error.message} className='mt-3' />}

          <div className='d-flex gap-2 w-100 mt-3'>
            <CancelButton disabled={isLoading} className='w-100' onClick={() => setIsOpen(false)}>
              Cancel
            </CancelButton>
            <PrimaryButton
              loading={isLoading}
              className='w-100'
              onClick={() => mutate(undefined, { onSuccess: () => setIsOpen(false) })}
            >
              Move to next phase
            </PrimaryButton>
          </div>
        </ModalDialog>
      )}
    </>
  );
};
