export interface AthleticsEntry {
  trackEvent: string;
  fieldEvent: string;
  fourTimesOneHundredMetersRelay: boolean;
}

export class AthleticsEntry {
  public constructor(params: OnlyData<AthleticsEntry>) {
    Object.assign(this, params);
  }
}
