import React from 'react';
import { UseQueryResult } from 'react-query';
import { RippleAnimation } from 'src/shared/ui/assets/RippleAnimation';
import { ErrorPage } from 'src/shared/ui/utils/ErrorPage';
import { ApiError } from 'src/shared/utils/apiError';

export type QueryResultWithData<TData, TError> = UseQueryResult<TData, TError> & {
  data: TData;
};

interface QueryLoaderProps<TData, TError> {
  /**
   * Property specified to enforce the fact that this component does not make sense being used without children.
   */
  children:
    | ((query: QueryResultWithData<TData, TError>) => React.ReactElement | React.ReactElement[] | null)
    | React.ReactElement;
  /**
   * The React Query object.
   */
  query: UseQueryResult<TData, TError>;
  /**
   * What to render when there is an error.
   */
  onError?: React.ReactElement | null;
  /**
   * What to render when the query is in loading state.
   *
   * By default, a generic loading message is displayed.
   */
  onLoading?: React.ReactElement | null;
  /**
   * What to render when there is no data.
   */
  onNoData?: React.ReactElement | null;
}

/**
 * This component helps process React Query results where children of this component are rendered only when the data
 * returned by the query is not undefined.
 */
export const QueryLoader = <TData, TError extends ApiError>({
  children,
  onError,
  onLoading: onLoad,
  onNoData,
  query,
}: QueryLoaderProps<TData, TError>): React.ReactElement | null => {
  if (query.data !== undefined) {
    return typeof children === 'function'
      ? (children(query as QueryResultWithData<TData, TError>) as React.ReactElement)
      : children;
  }

  if (query.error) {
    if (onError !== undefined) return onError;

    return <ErrorPage error={query.error} />;
  }

  if (query.isLoading || query.isFetching) {
    return onLoad || <RippleAnimation className='d-block mx-auto ts-color-primary' />;
  }

  return onNoData || null;
};
