import { Venue } from 'src/lyg/entities/venue';

export const LYG_EVENT_TYPE = {
  openGames: 'open-games',
  schoolGames: 'school-games',
} as const;
export type LYG_EVENT_TYPE = ValueOf<typeof LYG_EVENT_TYPE>;

export const LYG_EVENT_STATUS = {
  live: 'live',
  closed: 'closed',
  draft: 'draft',
} as const;
export type LYG_EVENT_STATUS = ValueOf<typeof LYG_EVENT_STATUS>;

export interface LYGEvent {
  id: number;
  startDate: Date;
  endDate: Date;
  name: string;
  rawType: string;
  image?: File | null;
  imageUrl?: string | null;
  entryDeadlineDate: Date;
  status: LYG_EVENT_STATUS;
  rules: string;
  competitionDayInformation: string | null;
  venues: Venue[];
  generalEnquiries: string;
  organiserName: string;
  organiserId: number;
  venueDetails: string;
  acronym: string;
  participantsCount: number;
}

type LYGEventProps = OnlyData<Omit<LYGEvent, 'type' | 'formatedStatus'>>;
export class LYGEvent {
  public constructor(init: LYGEventProps) {
    Object.assign(this, init);
  }

  get type(): LYG_EVENT_TYPE {
    if (this.rawType === 'open-games') return LYG_EVENT_TYPE.openGames;
    return LYG_EVENT_TYPE.schoolGames;
  }

  get formatedStatus(): string {
    switch (this.status) {
      case LYG_EVENT_STATUS.live:
        return 'Live';
      case LYG_EVENT_STATUS.draft:
        return 'Draft';
      case LYG_EVENT_STATUS.closed:
        return 'Closed';
      default:
        return 'Unkown';
    }
  }
}
