import React from 'react';
import { AlertCircle } from 'react-feather';

export const NoNetworkPage: React.FC = () => {
  return (
    <div className='d-flex align-items-center justify-content-center gap-2 ts-text-error ts-fw-500 ts-fs-16 text-center'>
      <AlertCircle />
      <span>No network available</span>
    </div>
  );
};
