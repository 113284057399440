import React from 'react';
import { AppSidebar } from 'src/lyg/features/appLayout/ui/AppSidebar';
import { PageHeaderBar } from 'src/lyg/features/appLayout/ui/PageHeaderBar';
import { useIsLandscape } from 'src/shared/hooks/useIsLandscape';

interface LYGAppNavigationProps {
  hideNavigationOnMobileLandscape?: boolean;
}

export const LYGAppNavigation: React.FC<LYGAppNavigationProps> = ({ hideNavigationOnMobileLandscape = false }) => {
  const isLandscape = useIsLandscape();

  const hideNavigation = hideNavigationOnMobileLandscape && isLandscape;

  return (
    <>
      {!hideNavigation && (
        <>
          <PageHeaderBar />
          <AppSidebar />
        </>
      )}
    </>
  );
};
