import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { I18nContextProvider } from 'src/context/i18n';
import { AuthContextProvider } from 'src/context/authContext';
import { MODAL_ROOT_ID } from 'src/shared/ui/modal/ModalDialog';
import { browserIsSafari } from 'src/shared/utils/common';
import { FallbackErrorPage } from 'src/shared/ui/layout/FallbackErrorPage';
import { FileUploadProgressModal } from 'src/features/appLayout/ui/FileUploadProgressModal';
import { SidebarContextProvider } from 'src/features/appLayout/SidebarContext';
import { LYGAppNavigation } from 'src/lyg/features/appLayout';
import { LYGPusherProvider } from 'src/lyg/features/appLayout/pusher/LYGPusherProvider';
import css from './LYGRoutesHelpers.module.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60,
      retry: false,
    },
  },
});

const LYGMetaTags: React.FC = () => (
  <Helmet defer>
    <title>TeamSportz platform</title>

    <meta name='title' content='TeamSportz platform' />
    <meta
      name='description'
      content='TeamSportz helps clubs, teams, coaches and players to accelerate their sport journey. Download our app and signed up for free.'
    />

    <meta property='og:type' content='website' />
    <meta property='og:url' content='https://lyg.teamsportz.pro/' />
    <meta property='og:title' content='TeamSportz sports platform' />
    <meta
      property='og:description'
      content='TeamSportz helps clubs, teams, coaches and players to accelerate their sport journey. Download our app and signed up for free.'
    />
    <meta property='og:image' content='https://files.teamsportz.app/assets/TEAMSPORTZandLYG.png' />

    <meta property='twitter:card' content='summary_large_image' />
    <meta property='twitter:url' content='https://lyg.teamsportz.pro/' />
    <meta property='twitter:title' content='TeamSportz platform' />
    <meta
      property='twitter:description'
      content='TeamSportz helps clubs, teams, coaches and players to accelerate their sport journey. Download our app and signed up for free.'
    />
    <meta property='twitter:image' content='https://files.teamsportz.app/assets/TEAMSPORTZandLYG.png' />
  </Helmet>
);

const LYGNotificationsProvider: React.FC = ({ children }) => {
  if ('PushManager' in window || browserIsSafari()) {
    return <LYGPusherProvider>{children}</LYGPusherProvider>;
  }
  return <>{children}</>;
};

export const LYGCommonRoute: React.FC = () => (
  <HelmetProvider>
    <LYGMetaTags />
    <Sentry.ErrorBoundary
      fallback={({ error }) => <FallbackErrorPage error={error} />}
      onError={({ message }) => {
        message?.includes('Failed to fetch dynamically imported module') && window.location.reload();
      }}
    >
      <I18nContextProvider>
        <QueryClientProvider client={queryClient}>
          <Outlet />
          <ToastContainer />
          <ScrollRestoration />
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
      </I18nContextProvider>
    </Sentry.ErrorBoundary>
  </HelmetProvider>
);

export const LYGAuthRoute: React.FC = () => (
  <AuthContextProvider>
    <LYGNotificationsProvider>
      <SidebarContextProvider>
        <LYGAppNavigation />
        <div id={MODAL_ROOT_ID} />
        <div className={css.mainContent}>
          <div className='container-fluid container-lg'>
            <Outlet />
          </div>
        </div>
        <FileUploadProgressModal />
      </SidebarContextProvider>
    </LYGNotificationsProvider>
  </AuthContextProvider>
);

export const LYGAuthRouteWithoutNav: React.FC = () => (
  <AuthContextProvider>
    <LYGNotificationsProvider>
      <Outlet />
    </LYGNotificationsProvider>
  </AuthContextProvider>
);
