import React from 'react';
import { MessageCircle, UserPlus, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { useI18nContext } from 'src/context/i18n';
import { TSNotification, TS_NOTIFICATION_TYPE } from 'src/entities/notifications/domain';
import { useDismissNotificationQuery } from 'src/entities/notifications/query/dismissNotificationQuery';
import { getPlayerNameWithLastNameInitial } from 'src/entities/players/domain';
import { useNotificationRoutes } from 'src/features/appLayout/ui/AppNotifications/notificationMap';
import { useDateTimeFormat } from 'src/shared/hooks/useDateTimeFormat';
import { MegaphoneIcon } from 'src/shared/ui/assets/MegaphoneIcon';
import { PlayerUnknownIcon } from 'src/shared/ui/assets/PlayerUnknownIcon';
import { WhistleIcon } from 'src/shared/ui/assets/WhistleIcon';
import { RoundedImage } from 'src/shared/ui/widgets/RoundedImage';
import { cx } from 'src/shared/utils/common';
import css from './NotificationItem.module.scss';

interface NotificationItemProps {
  notification: TSNotification;
  onNavigate: () => void;
}

const iconMap = new Map<string | null, JSX.Element>([
  ['feature', <MegaphoneIcon key='feature' className={css.icon} />],
  ['attendance-pending', <PlayerUnknownIcon key='attendance-pending' className={css.icon} />],
  ['game', <WhistleIcon key='game' className={css.icon} />],
  ['atendance-complete', <UserPlus key='attendance-complete' className={css.icon} />],
  [TS_NOTIFICATION_TYPE.youHaveUnreadMessages, <MessageCircle key='chat-unread-messages' className={css.icon} />],
]);

const JUST_NOW_MILLIS = 1000;

export const NotificationItem: React.FC<NotificationItemProps> = ({ notification, onNavigate }) => {
  const { title, body, created, type, id, fromUser } = notification;
  const i18n = useI18nContext();
  const route = useNotificationRoutes(notification);

  const { formatDateToNow } = useDateTimeFormat();
  const deltaTime = new Date().getTime() - created.getTime();

  const { execute: dismiss } = useDismissNotificationQuery(id);

  return (
    <div className='d-flex gap-3'>
      <Link
        className='d-flex gap-3 ts-fw-400 ts-text flex-grow-1'
        to={route}
        onClick={() => {
          dismiss();
          onNavigate();
        }}
      >
        {iconMap.get(type) || (
          <RoundedImage
            url={fromUser.avatarUrl}
            name={getPlayerNameWithLastNameInitial(fromUser)}
            className={css.icon}
          />
        )}
        <div className='flex-grow-1'>
          <p className='ts-fs-16 mb-2 ts-fw-500'>{title}</p>
          <p className='mb-2'>{body}</p>
          <p className={cx('fs-12', deltaTime > JUST_NOW_MILLIS ? 'ts-text-lighter' : 'ts-color-primary')}>
            {formatDateToNow(created)}
          </p>
        </div>
        <button
          title={i18n.t('shared.phrases.markAsRead')}
          className={cx(css.readBtn, 'align-self-start bg-transparent p-0')}
          onClick={dismiss}
        >
          <X className='d-block' />
        </button>
      </Link>
    </div>
  );
};
