import React from 'react';
import { X } from 'react-feather';
import { useI18nContext } from 'src/context/i18n';
import { SafariNotificationModel } from 'src/features/appLayout/ui/AppNotifications/SafariNotificationsModel';
import { beamsClient } from 'src/features/pusher/PusherProvider';
import { usePusher } from 'src/features/pusher/usePusher';
import { PrimaryButton, TransparentButton } from 'src/shared/ui/buttons';
import { cx } from 'src/shared/utils/common';
import css from './index.module.scss';

interface EnableNotificationsButtonProps {
  model: SafariNotificationModel;
}

export const EnableNotificationsButton: React.FC<EnableNotificationsButtonProps> = ({ model }) => {
  const i18n = useI18nContext();
  const { onInit } = usePusher();
  const [loading, setLoading] = React.useState(false);

  return (
    <PrimaryButton
      loading={loading}
      className='ts-fw-500 w-100'
      onClick={async () => {
        /* Safari does not work if you call start() inside a wrapper fn */
        setLoading(true);
        let error = null;
        try {
          await beamsClient.start();
        } catch (e) {
          error = e;
        }
        !error && (await onInit());
        setLoading(false);
        model.setNotificationsAlreadyEnabled(true);
      }}
    >
      {i18n.t('shared.phrases.enableNotifications')}
    </PrimaryButton>
  );
};

interface SafariEnableNotificationsPromptProps {
  model: SafariNotificationModel;
}

export const SafariEnableNotificationsPrompt: React.FC<SafariEnableNotificationsPromptProps> = ({ model }) => {
  const i18n = useI18nContext();

  return (
    <div className={cx('ts-fade-in-03 d-flex flex-column gap-2 p-3', css.safariPrompt)}>
      <TransparentButton
        className='d-flex align-items-center align-self-end p-0 mt-n1 me-n1 h-auto'
        onClick={() => model.setUserPreference(false)}
      >
        <X className='ts-color-primary me-2' />
        <span className='ts-color-primary'>{i18n.t('shared.actions.dismiss')}</span>
      </TransparentButton>
      <p className='mb-0'>{i18n.t('shared.phrases.enableNotificationsText')}</p>
      <EnableNotificationsButton model={model} />
    </div>
  );
};
