import { action, computed, makeObservable, observable } from 'mobx';
import { PERMISSIONS_KEY, localStorageKeys } from 'src/context/authContext/localStorageKeys';

export enum CURRENT_USER_ROLE {
  admin = 'Admin',
  coach = 'Coach',
  headCoach = 'Head Coach',
  lygAdmin = 'LYG Admin',
  lygBto = 'LYG BTO',
  lygBtm = 'LYG BTM',
  lygSgo = 'LYG SGO',
  lygStm = 'LYG STM',
  lygSgoBto = 'LYG SGO-BTO',
  referee = 'Referee',
}

export interface CurrentUserPermissions {
  clubName: string;
  clubId: number;
  userRole: CURRENT_USER_ROLE;
  sportName: string | null;
  sportId: number | null;
  paymentAmount: number;
  paymentPriceId: string;
  paymentSubscriptionId: string;
  paymentQuantity: number;
  paymentPlan: string | null;
  paymentFrequency: string | null;
  clubAvatar: string | null;
  clubSiteCode: string;
  isDefault: boolean;
}

interface CurrentUserProps {
  avatarUrl?: string;
  email: string;
  emailReminders: boolean;
  emailTeamManagement: boolean;
  emailNewsletter: boolean;
  emailGlobal: boolean;
  id: number;
  intercomHash: string;
  name: string;
  gender: string;
  onboardingCompleted: boolean;
  onboardingPaymentCompleted: boolean;
  clubId: number;
  clubName: string;
  paymentAmount: number;
  paymentPriceId: string;
  paymentSubscriptionId: string;
  paymentQuantity: number;
  pendingVerification: boolean;
  paymentPlan: string | null;
  paymentFrequency: string | null;
  pushReminders: boolean;
  pushTeamManagement: boolean;
  pushNewsletter: boolean;
  pushGlobal: boolean;
  joined: Date;
  role: CURRENT_USER_ROLE;
  sport: string;
  teamId: number;
  boroughId: number | null;
  boroughName: string | null;
  boroughLogo: string | null;
  schoolId: number | null;
  schoolName: string | null;
  permissions: CurrentUserPermissions[];
}
export class CurrentUser {
  /**
   * This stores the actual role of the current usr, whilst the role variable stores the role that can be changed via
   * the role switch dropdown (visible to admins only).
   */
  readonly actualRole: CURRENT_USER_ROLE;
  readonly avatarUrl?: string;
  readonly email: string;
  readonly emailReminders: boolean;
  readonly emailTeamManagement: boolean;
  readonly emailNewsletter: boolean;
  readonly emailGlobal: boolean;
  readonly id: number;
  readonly intercomHash: string;
  readonly name: string;
  readonly onboardingCompleted: boolean;
  readonly gender: string;
  readonly paymentAmount: number;
  readonly paymentPriceId: string | null;
  readonly paymentPlan: string;
  readonly paymentSubscriptionId: string;
  readonly paymentQuantity: number;
  readonly paymentFrequency: string | null;
  readonly pendingVerification: boolean;
  readonly pushReminders: boolean;
  readonly pushTeamManagement: boolean;
  readonly pushNewsletter: boolean;
  readonly pushGlobal: boolean;
  readonly onboardingPaymentCompleted: boolean;
  readonly joined: Date;
  readonly clubId: number;
  readonly clubName: string;
  readonly sport: string;
  readonly teamId: number;
  readonly boroughId: number | null;
  readonly boroughName: string | null;
  readonly schoolId: number | null;
  readonly schoolName: string | null;
  readonly boroughLogo: string | null;
  readonly permissions: CurrentUserPermissions[];

  currentRole: CURRENT_USER_ROLE;
  currentPaymentAmount: number;
  currentPaymentPriceId: string | null;
  currentPaymentPlan: string;
  currentPaymentSubscriptionId: string;
  currentPaymentQuantity: number;
  currentPaymentFrequency: string | null;
  currentClub: number;

  constructor(props: CurrentUserProps) {
    this.boroughId = props.boroughId;
    this.boroughName = props.boroughName;
    this.boroughLogo = props.boroughLogo;

    this.schoolId = props.schoolId;
    this.schoolName = props.schoolName;

    this.actualRole = props.role;
    this.avatarUrl = props.avatarUrl;
    this.email = props.email;
    this.emailReminders = props.emailReminders;
    this.emailNewsletter = props.emailNewsletter;
    this.emailTeamManagement = props.emailTeamManagement;
    this.emailGlobal = props.emailGlobal;
    this.gender = props.gender;
    this.pushReminders = props.pushReminders;
    this.pushTeamManagement = props.pushTeamManagement;
    this.pushNewsletter = props.pushNewsletter;
    this.pushGlobal = props.pushGlobal;
    this.name = props.name;
    this.id = props.id;
    this.intercomHash = props.intercomHash;
    this.onboardingCompleted = props.onboardingCompleted;
    this.onboardingPaymentCompleted = props.onboardingPaymentCompleted;
    this.clubId = props.clubId;
    this.clubName = props.clubName;
    this.currentRole = props.role;
    this.sport = props.sport;
    this.teamId = props.teamId;
    this.joined = props.joined;
    this.pendingVerification = props.pendingVerification;
    this.paymentPriceId = props.paymentPriceId;
    this.paymentSubscriptionId = props.paymentSubscriptionId;
    this.paymentQuantity = props.paymentQuantity;
    this.paymentPlan = props.paymentPlan || 'free';
    this.paymentAmount = props.paymentAmount;
    this.paymentFrequency = props.paymentFrequency;
    this.permissions = props.permissions || [];

    if (props.permissions.length) {
      const pickedPermissions = CurrentUser.getPickedPermissions();
      let defaultPermissions = props.permissions.find(permission => permission.isDefault);
      if (!defaultPermissions) defaultPermissions = props.permissions[0];
      this.currentClub = pickedPermissions?.clubId ?? defaultPermissions.clubId;
      this.currentRole = pickedPermissions?.userRole ?? defaultPermissions.userRole;
      this.currentPaymentAmount = pickedPermissions?.paymentAmount ?? defaultPermissions.paymentAmount;
      this.currentPaymentFrequency = pickedPermissions?.paymentFrequency ?? defaultPermissions.paymentFrequency;
      this.currentPaymentPlan = pickedPermissions?.paymentPlan ?? (defaultPermissions.paymentPlan || 'free');
      this.currentPaymentPriceId = pickedPermissions?.paymentPriceId ?? defaultPermissions.paymentPriceId;
      this.currentPaymentQuantity = pickedPermissions?.paymentQuantity ?? defaultPermissions.paymentQuantity;
      this.currentPaymentSubscriptionId =
        pickedPermissions?.paymentSubscriptionId ?? defaultPermissions.paymentSubscriptionId;
    } else {
      this.currentClub = props.clubId;
      this.currentRole = props.role;
      this.currentPaymentAmount = props.paymentAmount;
      this.currentPaymentFrequency = props.paymentFrequency;
      this.currentPaymentPlan = props.paymentPlan || 'free';
      this.currentPaymentPriceId = props.paymentPriceId;
      this.currentPaymentQuantity = props.paymentQuantity;
      this.currentPaymentSubscriptionId = props.paymentSubscriptionId;
    }

    makeObservable(this, {
      currentRole: observable,
      currentClub: observable,
      isAdmin: computed,
      isCoach: computed,
      isHeadCoach: computed,
      changePermission: action.bound,
    });
  }

  get isAdmin(): boolean {
    return this.currentRole === CURRENT_USER_ROLE.admin;
  }

  get isLYGAdmin(): boolean {
    return this.currentRole === CURRENT_USER_ROLE.lygAdmin || this.currentRole === CURRENT_USER_ROLE.admin;
  }

  get isLYBoroughAndSchool(): boolean {
    return this.currentRole === CURRENT_USER_ROLE.lygSgoBto;
  }

  get isLYGSchool(): boolean {
    return this.currentRole === CURRENT_USER_ROLE.lygSgo || this.currentRole === CURRENT_USER_ROLE.lygStm;
  }

  get isLYGBorough(): boolean {
    return this.currentRole === CURRENT_USER_ROLE.lygBtm || this.currentRole === CURRENT_USER_ROLE.lygBto;
  }

  get isCoach(): boolean {
    return this.currentRole === CURRENT_USER_ROLE.coach;
  }

  get isHeadCoach(): boolean {
    return this.currentRole === CURRENT_USER_ROLE.headCoach;
  }

  private static getPickedPermissions(): CurrentUserPermissions | null {
    return JSON.parse(localStorage.getItem(PERMISSIONS_KEY) || 'null');
  }

  changePermission(permissions: CurrentUserPermissions): void {
    this.currentClub = permissions.clubId;
    this.currentRole = permissions.userRole;
    localStorageKeys.filter(key => key !== 'TS_JWT').forEach(key => localStorage.removeItem(key));
    localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
    window?.location.reload();
  }
}
