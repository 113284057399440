import { UseQueryResult, useQuery } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { LYGSchool } from 'src/lyg/entities/schools';
import { LYG_SCHOOLS_KEYS } from 'src/lyg/pages/schools/shared/queryKeys';
import { apiClient } from 'src/lyg/utils/apiClient';
import { ApiError } from 'src/lyg/utils/apiError';

interface GetSchoolsRequest {
  limit: number;
  page?: number;
  search?: string;
  boroughId?: number;
}
interface GetSchoolsResponse {
  result: {
    data: {
      lyg_school_id: number;
      lyg_school_name: string;
      lyg_school_address: string;
      lyg_school_postcode: string;
      lyg_borough_id: number;
      lyg_borough_name: string;
    }[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: { active: boolean; label: string; url: null | string }[];
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null | string;
    to: number;
    total: number;
  };
}

interface QueryResponse {
  count: number;
  schools: LYGSchool[];
}

async function getSchools(request: GetSchoolsRequest, token: AccessToken): Promise<GetSchoolsResponse> {
  const params = [`limit=${request.limit}`];
  if (request.search) params.push(`search=${request.search}`);
  if (request.page) params.push(`page=${request.page}`);
  if (request.boroughId) params.push(`borough_id=${request.boroughId}`);
  const query = params.join('&');

  return apiClient(`/v1/lyg/schools?${query}`, { token });
}

export function useLYGSchoolsQuery(request: GetSchoolsRequest): UseQueryResult<QueryResponse, ApiError> {
  const { token } = useAuthContext();

  return useQuery<QueryResponse, ApiError>(
    LYG_SCHOOLS_KEYS.filtered(request),
    async () => {
      const { result } = await getSchools(request, token);

      return {
        schools: result.data.map<LYGSchool>(school => ({
          id: school.lyg_school_id,
          name: school.lyg_school_name,
          address: school.lyg_school_address,
          postcode: school.lyg_school_postcode,
          boroughId: school.lyg_borough_id,
          boroughName: school.lyg_borough_name,
        })),
        count: result.total,
      };
    },
    {
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );
}
