import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { LYG_MEMBER_ROLE } from 'src/lyg/entities/member';
import { useLYGMembersQuery } from 'src/lyg/pages/members/home/query';
import { DropdownField } from 'src/shared/ui/inputs/dropdown/DropdownField';
import { DropdownFieldModel } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';

interface MemberPickerProps {
  model: DropdownFieldModel;
  role?: LYG_MEMBER_ROLE;
  sport?: number;
  schoolId?: number;
}

export const MemberPicker: React.FC<MemberPickerProps> = observer(({ model, role, sport, schoolId }) => {
  const query = useLYGMembersQuery({
    page: 1,
    name: model.debouncedSearchValue,
    role: role ?? LYG_MEMBER_ROLE.admin,
    sport: sport,
    schoolId: schoolId,
    limit: 20,
  });

  useEffect(() => {
    if (!query.data) return;
    model.setOptions(
      query.data.members.map(member => ({
        id: member.id.toString(),
        label: `${member.fullName}${member.schoolName ? ` (${member.schoolName})` : ''}`,
      })),
    );
  }, [model, query.data]);

  return <DropdownField model={model} />;
});
