import { Observer } from 'mobx-react-lite';
import React from 'react';
import { LYGEntryGeneralInfoModel } from 'src/lyg/pages/entries/shared/LYGEntryGeneralInfoModel';
import { FormFieldWrapper } from 'src/shared/ui/inputs/FormFieldWrapper';
import { DropdownField } from 'src/shared/ui/inputs/dropdown/DropdownField';
import { TextField } from 'src/shared/ui/inputs/text/TextField';
import { useAuthContext } from 'src/context/authContext';
import { BoroughPicker } from 'src/lyg/features/boroughPicker';

interface QuestionsStepProps {
  model: LYGEntryGeneralInfoModel;
  showHowSelectSquad: boolean;
}

export const LYGEntryQuestionsStep: React.FC<QuestionsStepProps> = ({ model, showHowSelectSquad }) => {
  const { currentUser } = useAuthContext();

  React.useEffect(() => {
    model.setDefaultValues(null, currentUser.boroughId);
  }, [currentUser.boroughId, model]);

  return (
    <Observer>
      {() => (
        <>
          <FormFieldWrapper model={model.boroughPicker}>
            <BoroughPicker model={model.boroughPicker} />
          </FormFieldWrapper>
          {/* <FormFieldWrapper model={model.didRunTrials}>
            <div className='d-flex gap-2'>
              <p className='mb-0'>No</p>
              <ToggleField model={model.didRunTrials} />
              <p className='mb-0'>Yes</p>
            </div>
          </FormFieldWrapper> */}
          {/* {!model.didRunTrials.value && <div style={{ height: '50px' }} />} */}
          {/* {model.didRunTrials.value ? ( */}
          <>
            {showHowSelectSquad && (
              <FormFieldWrapper model={model.howSelectSquad}>
                <DropdownField model={model.howSelectSquad} />
              </FormFieldWrapper>
            )}
            <p
              style={{
                color: '#06050fbf',
                fontSize: '0.938rem',
              }}
            >
              Enter the number of participants to your selection:{' '}
              <span aria-label='Required field' className='fw-bolder ms-1 ts-color-primary'>
                *
              </span>
            </p>

            <div className='d-flex gap-2 flex-nowrap'>
              <FormFieldWrapper model={model.totalBoysParticipants}>
                <TextField model={model.totalBoysParticipants} />
              </FormFieldWrapper>
              <FormFieldWrapper model={model.totalGirlsParticipants}>
                <TextField model={model.totalGirlsParticipants} />
              </FormFieldWrapper>
              <FormFieldWrapper model={model.totalNonBinaryParticipants}>
                <TextField model={model.totalNonBinaryParticipants} />
              </FormFieldWrapper>
            </div>
            <div className='d-flex gap-2 flex-nowrap'>
              <FormFieldWrapper model={model.totalOtherParticipants}>
                <TextField model={model.totalOtherParticipants} />
              </FormFieldWrapper>
              <FormFieldWrapper model={model.totalNoResponseParticipants}>
                <TextField model={model.totalNoResponseParticipants} />
              </FormFieldWrapper>
            </div>
          </>
          {/* ) : null} */}
        </>
      )}
    </Observer>
  );
};
