import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import { cx } from 'src/shared/utils/common';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enGB } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import { Calendar } from 'react-feather';
import { useOnClickOutside } from 'src/shared/hooks/useOnClickOutside';
import { DateFieldModel } from 'src/shared/ui/inputs/datetime/DateFieldModel';
import { useI18nContext } from 'src/context/i18n';
import { useDateTimeFormat } from 'src/shared/hooks/useDateTimeFormat';
import { SpinnerAnimation } from 'src/shared/ui/assets/SpinnerAnimation';
import css from './DateField.module.scss';

interface DateFieldProps {
  className?: string;
  wrapperClassName?: string;
  dayClassName?: (day: Date) => string;
  model: DateFieldModel;
  displayValue?: (day: Date) => string;
  isLoading?: boolean;
}

export const getMonthContainerHeight = () => {
  const wrapperEl = document.getElementsByClassName('react-datepicker__month')[0] as HTMLDivElement;
  return wrapperEl
    ? wrapperEl.getBoundingClientRect().height + +window.getComputedStyle(wrapperEl).marginTop.replace('px', '') * 2
    : '100%';
};

export const DateField: React.FC<DateFieldProps> = observer(
  ({ className, model, dayClassName, displayValue, isLoading, wrapperClassName }) => {
    const {
      errorIsVisible,
      id,
      minDate,
      maxDate,
      placeholder,
      title,
      value,
      makeErrorVisible,
      setValue,
      setCurrentMonthView,
      helperText,
    } = model;
    const selectRef = React.useRef<HTMLButtonElement>(null);
    const i18n = useI18nContext();

    const [focused, setFocused] = React.useState(false);
    const [opened, setOpened] = React.useState(false);
    const [overlayHeight, setOverlayHeight] = React.useState<string | number>('100%');
    const fieldRef = useOnClickOutside(opened, () => {
      setOpened(false);
      setFocused(false);
      makeErrorVisible();
    });

    const { localeCode, supportedLocales } = useDateTimeFormat();

    React.useEffect(() => {
      registerLocale(localeCode, supportedLocales[localeCode] || enGB);
    }, [localeCode, supportedLocales]);

    React.useEffect(() => {
      setOverlayHeight(getMonthContainerHeight());
    }, [opened]);

    return (
      <div
        className={cx('position-relative d-inline-block w-100', wrapperClassName)}
        ref={fieldRef}
        onBlur={() => setFocused(false)}
      >
        {helperText && <p className='ts-text-lighter ts-fs-12'>{helperText}</p>}
        <button
          aria-label={title}
          className={cx(
            'd-flex align-items-center w-100',
            css.fieldHeader,
            errorIsVisible && css.invalidField,
            focused && css.fieldFocused,
            className,
          )}
          ref={selectRef}
          title={title}
          type='button'
          onBlur={makeErrorVisible}
          onClick={() => setOpened(previous => !previous)}
          onFocus={() => setFocused(true)}
        >
          <div className={cx('flex-grow-1 text-start', !value && css.placeholder)}>
            {value
              ? displayValue
                ? displayValue(value)
                : value.toLocaleDateString(i18n.t('region.localeCode'))
              : placeholder}
          </div>
          <div className={cx('ms-2 flex-shrink-0', css.calendarIcon)}>
            <Calendar className='d-block' />
          </div>
        </button>

        {opened && (
          <div className={cx('position-absolute', css.calendarWrapper)}>
            <DatePicker
              calendarClassName='border-0 ts-box-shadow'
              dayClassName={dayClassName}
              inline
              locale={localeCode}
              minDate={minDate}
              maxDate={maxDate}
              name={id}
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
              excludeDates={model.excludedDates}
              selected={value}
              onChange={(date: Date | null) => {
                setValue(date);
                setOpened(false);
                makeErrorVisible();
                selectRef.current?.focus();
              }}
              onMonthChange={day => {
                setCurrentMonthView(day);
                setOverlayHeight(getMonthContainerHeight());
              }}
            >
              {isLoading && (
                <div className={cx(css.overlay, 'position-absolute')} style={{ height: overlayHeight }}>
                  <SpinnerAnimation className={css.loader} />
                </div>
              )}
            </DatePicker>
          </div>
        )}
      </div>
    );
  },
);

// TODO: show clear button only when specified via model property
// TODO: fix calendar dropdown showing offscreen on small screens - maybe show on modal dialog
// TODO: once a date is selected from dropdown calendar, the field goes into error mode for a split-second before the value appears on the field header
// TODO: make field value editable - maybe use a separate input for each date part
