import { action, makeObservable, observable } from 'mobx';
import React from 'react';
import { DataTableColumnModel } from 'src/shared/ui/data/DataTableColumnModel';
import {
  CellRendererProps,
  DataTableColumnDef,
  DataTableDataItem,
  DATA_TABLE_SORT_ORDER,
} from 'src/shared/ui/data/types';

interface ExtraRowDataCell<T extends DataTableDataItem> {
  (props: CellRendererProps<T>): string | JSX.Element | null;
}
interface DataTableModelProps<T extends DataTableDataItem> {
  columns: DataTableColumnDef<T>[];
  /**
   * The index of each item in the array correspond to the index of the row.
   * Use undefined if no data to display
   */
  extraRowDataCell?: ExtraRowDataCell<T>;
  noDataMessage?: string | React.ReactNode;
  onSort(sortKey: string, sortOrder: DATA_TABLE_SORT_ORDER): void;
}

export class DataTableModel<T extends DataTableDataItem> {
  columns: DataTableColumnModel<T>[] = [];
  extraRowDataCell?: ExtraRowDataCell<T>;
  width = 0;

  readonly props: DataTableModelProps<T>;

  constructor(props: DataTableModelProps<T>) {
    this.props = props;
    this.columns = props.columns.map(column => new DataTableColumnModel({ ...column, table: this }));
    this.extraRowDataCell = props.extraRowDataCell;

    makeObservable(this, {
      width: observable,
      setWidth: action.bound,
    });
  }

  get hasHiddenColumns(): boolean {
    return !!this.columns.find(column => column.overflows);
  }

  get showColumnHeaders(): boolean {
    return this.columns.filter(column => column.props.title && !column.overflows).length > 1;
  }

  setWidth(width: number): void {
    this.width = width;
  }
}
