import { LYGNavItemId } from 'src/lyg/features/appLayout/navigationItems';

export function useCheckNotificationStatus(): Record<LYGNavItemId, boolean> {
  return {
    divider: false,
    events: false,
    entries: false,
    reports: false,
    schools: false,
    venues: false,
    sports: false,
    videos: false,
    photos: false,
    members: false,
  };
}
