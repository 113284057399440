import { observer } from 'mobx-react-lite';
import React from 'react';
import { useAuthContext } from 'src/context/authContext';
import { RoundedImage } from 'src/shared/ui/widgets/RoundedImage';
import { cx } from 'src/shared/utils/common';
import { AccountDropdownButton } from 'src/shared/ui/buttons/AccountDropdownButton';
import { truncateString } from 'src/shared/utils/strings';

export const HeaderUserAvatar: React.FC = observer(() => {
  const { currentUser } = useAuthContext();

  const clubOptions = currentUser.permissions.map(permission => ({
    label: (
      <span
        className={cx(
          currentUser.currentClub === permission.clubId &&
            currentUser.currentRole === permission.userRole &&
            'ts-color-primary',
        )}
      >
        {truncateString(permission.clubName, 25)}
        {/* ({toTitleCase(permission.userRole)}) */}
      </span>
    ),
    onClick: () => {
      currentUser.changePermission(permission);
      window.location.href = '/';
    },
  }));

  return (
    <>
      <AccountDropdownButton options={clubOptions} currentUser={currentUser}>
        <RoundedImage name={currentUser.name} url={currentUser.avatarUrl} />
      </AccountDropdownButton>
    </>
  );
});
