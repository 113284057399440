import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from 'src/context/authContext';
import { useI18nContext } from 'src/context/i18n';
import { useLYGAppRoutesContext } from 'src/context/routes';
import { LYGSchoolEntryForm } from 'src/lyg/pages/entries/shared/LYGSchoolEntryForm';
import { LYGEntrySchoolModel } from 'src/lyg/pages/entries/shared/LYGSchoolGamesEntryModel';
import { useLYGSchoolGameEntry, useUploadLYGSchoolGamesEntriesMutation } from 'src/lyg/pages/entries/shared/entryQuery';
import { PrimaryButton } from 'src/shared/ui/buttons';
import { AuthenticatedPage } from 'src/shared/ui/layout/AuthenticatedPage';

export const EditSchoolGamesEntryPage: React.FC = () => {
  const params = useParams();
  const entryId = Number(params.entryId);
  const entryQuery = useLYGSchoolGameEntry(entryId);
  const i18n = useI18nContext();
  const { currentUser } = useAuthContext();
  const [model] = React.useState(new LYGEntrySchoolModel({ i18n, eventType: 'school-games' }));
  const { execute, isLoading } = useUploadLYGSchoolGamesEntriesMutation(model, entryQuery.data?.id ?? NaN);
  const navigate = useNavigate();
  const routes = useLYGAppRoutesContext();
  const onSuccess = () => navigate(routes.entries.base);

  React.useEffect(() => {
    model.setDefaultValues(
      currentUser.boroughId,
      currentUser.schoolId && currentUser.schoolName
        ? {
            schoolId: currentUser.schoolId,
            schoolName: currentUser.schoolName,
          }
        : null,
      entryQuery.data ?? null,
    );
  }, [currentUser.boroughId, currentUser.schoolId, currentUser.schoolName, entryQuery.data, model]);

  return (
    <AuthenticatedPage title={entryQuery.data?.schoolName}>
      {() => (
        <>
          <LYGSchoolEntryForm model={model} />
          <PrimaryButton className='mt-3 w-100' onClick={() => execute(undefined, { onSuccess })} loading={isLoading}>
            Save
          </PrimaryButton>
        </>
      )}
    </AuthenticatedPage>
  );
};
