import { apiClient } from 'src/shared/utils/apiClient';

interface StartChunkUploadRequest {
  file: File;
  teamId: number;
  token: AccessToken;
  type: string;
}

interface StartChunkUploadResponse {
  file_key: string;
  upload_id: string;
}

export async function startChunkUpload(request: StartChunkUploadRequest): Promise<StartChunkUploadResponse> {
  const queryString = [
    `category=${request.type}`,
    'stage=upload',
    `team_id=${request.teamId}`,
    `type=${request.file.type}`,
    `video_filename=${request.file.name}`,
  ].join('&');

  return apiClient(`/v1/service/providers/aws/upload?${queryString}`, {
    method: 'POST',
    token: request.token,
  });
}
