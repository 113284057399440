import { action, makeObservable, observable } from 'mobx';
import React from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useAuthContext } from 'src/context/authContext';
import { cancelVideoUpload } from 'src/entities/videos/apis/upload/cancelVideoUpload';
import { uploadVideoFile } from 'src/entities/videos/apis/upload/uploadVideoFile';
import { VIDEO_QUERY_KEYS } from 'src/entities/videos/queries/queryKeys';
import { VideoFormModel } from 'src/features/videoForm/viewModel';
import { createVideo } from 'src/pages/videos/create/apis/createVideo';

type VideoFormData = Exclude<VideoFormModel['data'], 'id'>;

export class VideoToUpload {
  id: string;
  formData: VideoFormData;
  progress = 0;
  hasError = false;
  isLoading = false;
  uploadId: string | null = null;
  fileKey: string | null = null;

  constructor({ formData, id }: { formData: VideoFormData; id: string }) {
    this.id = id;
    this.formData = formData;

    makeObservable(this, {
      isLoading: observable,
      uploadId: observable,
      fileKey: observable,
      setUploadData: action.bound,
      setIsLoading: action.bound,
      progress: observable,
      setProgress: action.bound,
      hasError: observable,
      setHasError: action.bound,
    });
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  setHasError(value: boolean): void {
    this.hasError = value;
  }

  setProgress(value: number): void {
    this.progress = value;
  }

  setUploadData({ fileKey, uploadId }: { uploadId: string; fileKey: string }): void {
    this.uploadId = uploadId;
    this.fileKey = fileKey;
  }
}

class VideoUploadProgressModel {
  videos: VideoToUpload[] = [];
  private queryClient: QueryClient | null = null;
  private token: AccessToken | null = null;

  constructor() {
    makeObservable(this, {
      videos: observable.ref,
      addVideo: action.bound,
      cancelUpload: action.bound,
      retryUpload: action.bound,
    });
  }

  addVideo(videoModel: VideoFormModel): void {
    const video = new VideoToUpload({ formData: videoModel.data, id: videoModel.data.name + this.videos.length });
    this.addToList(video);
    this.uploadVideo(video);
  }

  async cancelUpload(fileId: string): Promise<void> {
    const video = this.videos.find(({ id }) => fileId === id);
    if (!video || !video.fileKey || !video.uploadId) return;
    video.setHasError(false);
    try {
      video.setIsLoading(true);
      await cancelVideoUpload({ fileKey: video.fileKey, uploadId: video.uploadId }, this.token as AccessToken);
      video.setIsLoading(false);
      this.removeFromList(fileId);
      toast.info(`${video.formData.videoFile.name} upload canceled`);
    } catch (err) {
      video.setHasError(true);
      toast.error("We couldn't cancel the upload. Please try again");
      video.setIsLoading(false);
    }
  }

  async retryUpload(fileId: string): Promise<void> {
    const video = this.videos.find(({ id }) => fileId === id);
    if (!video) return;
    video.setIsLoading(true);
    await this.uploadVideo(video);
    video.setIsLoading(false);
  }

  init({ queryClient, token }: { token: AccessToken; queryClient: QueryClient }): void {
    this.token = token;
    this.queryClient = queryClient;
  }

  private async uploadVideo(video: VideoToUpload): Promise<void> {
    const {
      formData: { videoFile, teamId, type },
    } = video;
    video.setProgress(0);
    video.setHasError(false);
    try {
      const uploadResponse = await uploadVideoFile({
        file: videoFile,
        teamId,
        type,
        token: this.token as AccessToken,
        onChunkUploaded: (chunkNumber, chunkCount) => {
          video.setProgress(Math.floor((100 * chunkNumber) / chunkCount));
        },
        onChunkStarted: response => {
          video.setUploadData(response);
        },
      });
      if (!uploadResponse) return;
      await createVideo({ ...video.formData, url: uploadResponse.fileKey }, this.token as AccessToken);

      this.queryClient?.invalidateQueries(VIDEO_QUERY_KEYS.base);
      this.removeFromList(video.id);
      toast.success(`${video.formData.videoFile.name} uploaded succesfully`);
    } catch (err) {
      video.setHasError(true);
      toast.error("We couldn't upload your video. Please try again");
      video.setIsLoading(false);
    }
  }

  private addToList(newFile: VideoToUpload): void {
    this.videos = [...this.videos, newFile];
  }

  private removeFromList(fileId: string): void {
    this.videos = this.videos.filter(({ id }) => id !== fileId);
  }
}

const FileUploadContext = React.createContext(new VideoUploadProgressModel());

export function useFileUploadModal(): VideoUploadProgressModel {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  const ctx = React.useContext(FileUploadContext);

  ctx.init({ token, queryClient });

  return ctx;
}
