export interface TrampolineEntry {
  reserve: boolean;
  tier: string;
  membershipNumber: string;
}

export class TrampolineEntry {
  public constructor(params: OnlyData<TrampolineEntry>) {
    Object.assign(this, params);
  }
}
