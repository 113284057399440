import { getAWSUrlForChunkUpload } from 'src/entities/videos/apis/upload/getAWSUrlForChunkUpload';

interface UploadFileChunkRequest {
  chunk: Blob;
  chunkNumber: number;
  fileKey: string;
  token: AccessToken;
  uploadId: string;
  onChunkUploaded(etag: string, chunkNumber: number): void;
}

export async function uploadChunk(request: UploadFileChunkRequest): Promise<boolean> {
  // const wait = Math.floor(Math.random() * (10000 - 5000) + 5000);
  // await new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve('');
  //   }, wait);
  // });
  // request.onChunkUploaded(`ETag for part: ${request.chunkNumber}`, request.chunkNumber);

  const urlResponse = await getAWSUrlForChunkUpload(request);

  const response = await fetch(urlResponse.presigned_url, { body: request.chunk, method: 'PUT' });

  request.onChunkUploaded(response.headers.get('ETag') || '', request.chunkNumber);

  return response.ok;
}
