import React, { CSSProperties } from 'react';
import { cx } from 'src/shared/utils/common';
import { useI18nContext } from 'src/context/i18n';
import { SpinnerAnimation } from 'src/shared/ui/assets/SpinnerAnimation';
import css from './BaseButton.module.scss';

export interface BaseButtonProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  title?: string;
  noCapitalize?: boolean;
  style?: CSSProperties;
  tabIndex?: number;
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export const BaseButton: React.FC<BaseButtonProps> = ({
  className,
  children,
  disabled,
  loading,
  selected,
  onClick,
  noCapitalize = false,
  ...rest
}) => {
  const i18n = useI18nContext();

  let title = '';
  if (disabled) {
    title = i18n.t('shared.phrases.buttonDisabled');
  }
  if (loading) {
    title = i18n.t('shared.phrases.pleaseWait');
  }

  return (
    <button
      aria-label={title}
      className={cx(
        'user-select-none',
        !noCapitalize && 'text-capitalize',
        css.button,
        loading && css.loading,
        selected && css.selected,
        className,
      )}
      disabled={disabled}
      title={title}
      type='button'
      onClick={disabled || loading || selected ? undefined : event => onClick && onClick(event)}
      {...rest}
    >
      {loading ? <SpinnerAnimation className='mx-auto' /> : children}
    </button>
  );
};
