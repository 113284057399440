import React, { CSSProperties, PropsWithChildren, forwardRef } from 'react';
import { cx } from 'src/shared/utils/common';
import css from './Card.module.scss';

interface BaseCardProps {
  className?: string;
  style?: CSSProperties;
}
interface CardProps extends BaseCardProps {
  onClick?: (() => void) | null;
}

export const Card = forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
  ({ children, className, style, onClick }, ref) => (
    <div className={cx('text-break', css.card, className)} style={style} ref={ref}>
      {onClick ? (
        <button className='bg-transparent w-100 h-100 position-relative d-flex flex-column gap-2' onClick={onClick}>
          {children}
        </button>
      ) : (
        children
      )}
    </div>
  ),
);

export const CardTitle: React.FC<BaseCardProps> = ({ children, className }) => (
  <div className={cx('fs-6 ts-fw-500', className)}>{children}</div>
);

export const CardSubtitle: React.FC<BaseCardProps> = ({ children, className }) => (
  <div className={cx('ts-text-lighter ts-fs-13 mt-1', className)}>{children}</div>
);
