import React from 'react';
import { FullScreenSpinner } from 'src/shared/ui/layout/FullScreenSpinner';

export function load(lazyFactory: Promise<{ default: React.FC }>): JSX.Element {
  const Component = React.lazy(() => lazyFactory);

  return (
    <React.Suspense fallback={<FullScreenSpinner />}>
      <Component />
    </React.Suspense>
  );
}

export function parseAndRenderText(text: string) {
  const regex = /(https?:\/\/[^\s]+)/g; // Regular expression to match URLs
  const parts = text.split(regex); // Split the text by URLs

  return parts.map((part, index) => {
    if (part.match(regex)) {
      return (
        <a key={index} href={part} style={{ whiteSpace: 'pre-line' }}>
          {part}
        </a>
      );
    }

    if (!part) return null;
    return (
      <span key={index} className='me-2' style={{ whiteSpace: 'pre-line' }}>
        {part}
      </span>
    );
  });
}
