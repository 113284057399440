interface ApiErrorProps {
  message: string;
  code?: number;
}

export class ApiError extends Error {
  code?: number;

  constructor(props: ApiErrorProps) {
    super(props.message);
    this.name = this.constructor.name;

    Object.setPrototypeOf(this, ApiError.prototype);

    if (props.code) this.code = props.code;
  }
}
