import React from 'react';
import { toast } from 'react-toastify';
import { TS_GA_EVENT, useAnalytics } from 'src/shared/hooks/useAnalytics';
import { TransparentButton } from 'src/shared/ui/buttons';
import { cx } from 'src/shared/utils/common';
import css from './InstallAppButton.module.scss';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export const InstallAppButton: React.FC = () => {
  const { sendGAEvent } = useAnalytics();
  const [deferredPrompt, setDeferredPrompt] = React.useState<BeforeInstallPromptEvent | null>(null);
  const [notShowFlag, setNotShowFlag] = React.useState(false);

  React.useEffect(() => {
    function init(e: BeforeInstallPromptEvent) {
      e.preventDefault();
      if (notShowFlag) return;
      setDeferredPrompt(e);
      toast.info('Try installing the app!');
    }

    window.addEventListener('beforeinstallprompt', e => init(e as BeforeInstallPromptEvent));
    return () => {
      window.removeEventListener('beforeinstallprompt', e => init(e as BeforeInstallPromptEvent));
    };
  }, []);

  React.useEffect(() => {
    function listener() {
      setDeferredPrompt(null);
      sendGAEvent(TS_GA_EVENT.installAppInstalled);
    }
    window.addEventListener('appinstalled', listener);
    return () => {
      window.removeEventListener('appinstalled', listener);
    };
  }, [sendGAEvent]);

  async function onShowPrompt() {
    if (!deferredPrompt) return;
    await deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    sendGAEvent(outcome === 'accepted' ? TS_GA_EVENT.installAppAccepted : TS_GA_EVENT.installAppDismiss);
    setDeferredPrompt(null);
    if (outcome === 'dismissed') setNotShowFlag(true);
  }

  return deferredPrompt ? (
    <TransparentButton onClick={onShowPrompt} className={cx('w-100 mt-3 ts-fw-500', css.btn)} title='install app'>
      Install the app!
    </TransparentButton>
  ) : null;
};
