import { LYGCompetitionGame, LYGCompetitionPoolSchedule } from 'src/lyg/entities/competitions/LYGCompetitionGame';

interface FormatedDate {
  day: Date;
  startTime: { hour: number; min: number };
  endTime: { hour: number; min: number };
}

function minutesToMiliseconds(minutes: number): number {
  return minutes * 60 * 1000;
}

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function hasCommonElement<T extends string | number | boolean>(tuppleA: [T, T], array: T[]): boolean {
  if (!array.length) return false;
  return array.includes(tuppleA[0]) || array.includes(tuppleA[1]);
}

function pickRandomItemFromSetAndRemoveIt<T>(set: Set<T>): T {
  if (set.size === 0) {
    console.error('pickRandomItemFromSetAndRemoveIt: The set is empty.');
  }

  const randomIndex = Math.floor(Math.random() * set.size);
  const randomItem = Array.from(set)[randomIndex];
  set.delete(randomItem);

  return randomItem;
}

function pickItemFromSetAndRemoveIt<T>(set: Set<T>): T {
  if (set.size === 0) {
    console.error('pickItemFromSetAndRemoveIt: The set is empty.');
  }

  const item = set.values().next().value;
  set.delete(item);

  return item;
}

function getRandomMatchAndRemoveIt(matches: [number, number][], exclude: number[]): [number, number] {
  let match;
  let iterations = 0;
  do {
    match = matches[iterations];
    if (!match) break;
    iterations++;
  } while (hasCommonElement(match, exclude) && iterations < matches.length);
  matches.splice(matches.indexOf(match), 1);
  return match;
}

function generateAllMatchPairs(numbers: Set<number>, matchupFrequency: number): [number, number][] {
  const numbersArray = Array.from(numbers);
  const pairs: [number, number][] = [];

  for (let i = 0; i < numbersArray.length; i++) {
    for (let j = i + 1; j < numbersArray.length; j++) {
      for (let k = 0; k < matchupFrequency; k++) {
        pairs.push([numbersArray[i], numbersArray[j]]);
      }
    }
  }
  return shuffleArray(pairs);
}

function generateAllDayTimeSet(matchDuration: number, breakTime: number, days: FormatedDate[]): Set<Date> {
  const dayTimeSet = new Set<Date>();
  for (const { day, startTime, endTime } of days) {
    let currentTimeInMinutes = startTime.hour * 60 + startTime.min;
    const endTimeInMinutes = endTime.hour * 60 + endTime.min;

    while (currentTimeInMinutes < endTimeInMinutes) {
      const date = new Date(day);
      const currentTime = { hour: Math.floor(currentTimeInMinutes / 60), min: currentTimeInMinutes % 60 };
      date.setHours(currentTime.hour);
      date.setMinutes(currentTime.min);
      dayTimeSet.add(date);
      currentTimeInMinutes += matchDuration + breakTime;
    }
  }
  return dayTimeSet;
}

function getMatchTuple(game: Pick<LYGCompetitionGame, 'teamAId' | 'teamBId'>): [number, number] {
  return [game.teamAId, game.teamBId];
}

function getFormatedDates(dates: { start: Date; end: Date }[]): FormatedDate[] {
  return dates.map<FormatedDate>(({ start, end }) => ({
    day: new Date(start),
    endTime: { hour: end.getHours(), min: end.getMinutes() },
    startTime: { hour: start.getHours(), min: start.getMinutes() },
  }));
}

/**
 *
 * @returns {LYGCompetitionPoolSchedule[]} Each item in the inner array represents a game within the group.
 */
export function generateSchedule({
  pools,
  matchupFrequency,
  dates,
  numberOfCourts,
  breakTime,
  matchDuration,
  spreadGamesEvenlyBetweenDates,
}: {
  pools: { name: string; teamIds: number[] }[];
  /**
   * How many times each team will play against each other
   */
  matchupFrequency: number;
  dates: { start: Date; end: Date }[];
  numberOfCourts: number;
  /**
   * In minutes
   */
  breakTime: number;
  /**
   * In minutes
   */
  matchDuration: number;
  spreadGamesEvenlyBetweenDates?: boolean;
}): LYGCompetitionPoolSchedule[] {
  const games: LYGCompetitionPoolSchedule[] = [];
  const formatedDates = getFormatedDates(dates);
  let totalGamesCounter = 0;
  const availableDates = pools.map(() => generateAllDayTimeSet(matchDuration, breakTime, formatedDates));

  for (const [index, pool] of pools.entries()) {
    const teamIds = new Set(pool.teamIds);
    const gamePerPool: LYGCompetitionGame[] = [];
    const allPosibleMatches = generateAllMatchPairs(teamIds, matchupFrequency);
    const teamsCount = teamIds.size;
    const gamesCount = ((teamsCount * (teamsCount - 1)) / 2) * matchupFrequency;
    const gamesPerCourt = Math.ceil(gamesCount / numberOfCourts);
    // const availableDates = generateAllDayTimeSet(matchDuration, breakTime, formatedDates);

    for (let game = 0; game < gamesPerCourt; game++) {
      for (let court = 1; court < numberOfCourts + 1; court++) {
        const numberOfgamesToBePlayedPerCourt = Math.ceil((gamesCount * pools.length) / numberOfCourts);
        const courtGamesExceedGameCount = numberOfgamesToBePlayedPerCourt > gamesCount;

        if (
          !availableDates[
            courtGamesExceedGameCount ? Math.floor(totalGamesCounter / numberOfgamesToBePlayedPerCourt) : index
          ].size
        )
          throw Error(`Missing dates/times for games. You can either:
                                - Add more days
                                - Increment the time span of the days
                                - Add more courts
                                - Reduce the number of teams per pool
                                - Reduce how many times each team will play against each other (matchupFrequency)
                                `);

        const date = spreadGamesEvenlyBetweenDates
          ? /* FIXME: make random function to actually return dates evenly distributed */
            pickRandomItemFromSetAndRemoveIt(
              availableDates[
                courtGamesExceedGameCount ? Math.floor(totalGamesCounter / numberOfgamesToBePlayedPerCourt) : index
              ],
            )
          : pickItemFromSetAndRemoveIt(
              availableDates[
                courtGamesExceedGameCount ? Math.floor(totalGamesCounter / numberOfgamesToBePlayedPerCourt) : index
              ],
            );

        /**
         * Considering there are more than 1 court, there will be 2+ games playing at the same time
         * We need to avoid having a team playing 2 games at the same time
         * AND we also would like to avoid back to back games
         */
        const gamesAlreadyScheduledAtSameTime = gamePerPool.filter(g => g.date.getTime() === date.getTime());
        const lastGamesInPreviousTime = gamePerPool.filter(
          g =>
            g.date.getTime() === date.getTime() + minutesToMiliseconds(matchDuration) + minutesToMiliseconds(breakTime),
        );
        const gamesToAvoid = [
          ...gamesAlreadyScheduledAtSameTime.flatMap(getMatchTuple),
          ...lastGamesInPreviousTime.flatMap(getMatchTuple),
        ];
        const match = getRandomMatchAndRemoveIt(allPosibleMatches, gamesToAvoid);
        if (!match) break;
        const [teamAId, teamBId] = match;

        gamePerPool.push({
          date,
          teamAId,
          teamBId,
          court: Math.ceil((totalGamesCounter + 1) / numberOfgamesToBePlayedPerCourt),
          // court,
          teamAName: 'Unknown team',
          teamBName: 'Unknown team',
          teamAScore: 0,
          teamBScore: 0,
          // Since the backend will create the ids, we can just set it to NaN
          id: NaN,
          competitionId: NaN,
          scoreBreakdown: [{ teamAScore: 0, teamBScore: 0, period: 1 }],
        });

        if (gamePerPool.length > gamesCount) break;
        totalGamesCounter = totalGamesCounter + 1;
      }
    }

    games.push({ id: NaN, name: pool.name, games: gamePerPool });
  }
  return games;
}
