import React from 'react';
import { cx } from 'src/shared/utils/common';
import css from './SpinnerAnimation.module.scss';

export const SpinnerAnimation: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      color='white'
      height='1em'
      width='1em'
      stroke='currentColor'
      {...props}
      className={cx(css.spinner, props.className)}
      viewBox='0 0 100 100'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='50' cy='50' r='45' />
    </svg>
  );
};
