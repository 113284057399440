import { InputFieldModel } from 'src/shared/ui/inputs/InputFieldModel';
import { numberWithZeroPadding } from 'src/shared/utils/dates';

interface ValueAsNumbers {
  hours: number;
  minutes: number;
}

export class TimePickerFieldModel extends InputFieldModel<string> {
  get hasChanged(): boolean {
    return this.value !== this.defaultValue;
  }

  get valueAsNumbers(): ValueAsNumbers | null {
    const timeSplit = this.value.split(':');
    if (timeSplit.length === 2) {
      return { hours: Number(timeSplit[0]), minutes: Number(timeSplit[1]) };
    }
    return null;
  }

  protected get requiredIsValid(): boolean {
    return this.value.length > 0;
  }

  static dateToTimeString(date: Date): string {
    return `${numberWithZeroPadding(date.getHours(), 2)}:${numberWithZeroPadding(date.getMinutes(), 2)}`;
  }

  setDefaultValueFromDate(date: Date): void {
    super.setDefaultValue(TimePickerFieldModel.dateToTimeString(date));
  }
}
