import React from 'react';
import { Seed, SeedItem, SeedTeam, IRenderSeedProps } from 'react-brackets';
import { Edit2, Play } from 'react-feather';
import { PrimaryInvertedButton } from 'src/shared/ui/buttons';
import { cx } from 'src/shared/utils/common';
import { THEME_COLORS } from 'src/constants';
import css from './BracketMatch.module.scss';
import { CompetitionGame, CompetitionGameStatus } from 'src/entities/competition/domain';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppRoutesContext } from 'src/context/routes';

interface TeamItemProps {
  name: string | undefined;
  score: number | undefined;
  status: CompetitionGameStatus;
}

const TeamItem: React.FC<TeamItemProps> = ({ name, score, status }) => {
  return (
    <SeedTeam
      style={{
        justifyContent: 'space-between',
        gap: '18px',
        height: 50,
        backgroundColor: !name
          ? 'white'
          : status === CompetitionGame.Status.win
          ? THEME_COLORS.fourth
          : status === CompetitionGame.Status.lose
          ? THEME_COLORS.first
          : THEME_COLORS.third,
      }}
    >
      <span className='text-start'>{name || 'TBD '}</span>
      <span>{score || 0}</span>
    </SeedTeam>
  );
};

interface BracketMatch extends IRenderSeedProps {
  disableActions?: boolean;
  onEditScore(game: CompetitionGame): void;
  onEditDate(game: CompetitionGame): void;
}

export const BracketMatch: React.FC<BracketMatch> = ({ seed, breakpoint, disableActions, onEditScore, onEditDate }) => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const routes = useAppRoutesContext();
  const game = seed.game as CompetitionGame;

  return (
    <Seed mobileBreakpoint={breakpoint}>
      <SeedItem style={{ backgroundColor: 'white', color: 'black', borderRadius: '10px', overflow: 'hidden' }}>
        <button
          className={cx(css.match, 'p-0 w-100')}
          onClick={() => !disableActions && onEditScore(game)}
          disabled={!seed.teams[0].name || !seed.teams[1].name}
        >
          <TeamItem name={seed.teams[0]?.name} score={seed.teams[0]?.score} status={seed.teams[0]?.status} />
          <div className='ts-h-divider w-100' />
          <TeamItem name={seed.teams[1]?.name} score={seed.teams[1]?.score} status={seed.teams[1]?.status} />
        </button>
      </SeedItem>
      <p className='mt-2 mb-0'>
        {seed.date}
        <span>
          <PrimaryInvertedButton onClick={() => !disableActions && onEditDate(game)} loading={disableActions}>
            <Edit2 className='align-middle' />
          </PrimaryInvertedButton>
          <PrimaryInvertedButton
            onClick={() =>
              navigate(`${routes.competitions.liveEditor(Number(eventId), game.competitionId, game.id)}?from=bracket`)
            }
            loading={disableActions}
          >
            <Play className='align-middle' />
          </PrimaryInvertedButton>
        </span>
      </p>
    </Seed>
  );
};
