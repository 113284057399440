export enum TS_NOTIFICATION_TYPE {
  playerRequestFeedback = 'playerRequestFeedback',
  playerRequestToJoinTeam = 'playerRequestToJoinTeam',
  youHaveUnreadMessages = 'youHaveUnreadMessages',
}

export interface TSNotification {
  body: string;
  created: Date;
  id: number;
  title: string;
  type: TS_NOTIFICATION_TYPE | null;
  teamId: number;
  fromUser: {
    id: number | null;
    firstName: string;
    lastName: string;
    avatarUrl: string | null;
  };
}
