import React from 'react';
import { cx } from 'src/shared/utils/common';
import css from './HorizontalSelect.module.scss';
import { useIsMobile } from 'src/shared/hooks/useIsMobile';

interface HorizontalSelectOptionProps {
  label: string | React.ReactNode;
  selected?: boolean;
  isLastItem?: boolean;
}

export const HorizontalSelectOption: React.FC<HorizontalSelectOptionProps> = ({ label, selected, isLastItem }) => {
  const isMobile = useIsMobile();
  return (
    <div className={`ts-fw-500 ts-fs-16 ${isMobile && isLastItem ? 'me-5 pe-2' : ''}`}>
      {label}
      <div className={cx(css.underline, !selected && 'bg-transparent')} />
    </div>
  );
};

export interface HorizontalSelectOption {
  badge?: string;
  className?: string;
  id: string;
  label: string | React.ReactNode;
}

interface HorizontalSelectProps {
  className?: string;
  selectedId?: string;
  defaultSelectedId?: string;
  options: (HorizontalSelectOption | false)[];
  onSelect(option: HorizontalSelectOption): void;
}

export const HorizontalSelect: React.FC<HorizontalSelectProps> = ({
  className,
  defaultSelectedId,
  options,
  onSelect,
  selectedId,
}) => {
  const [_selectedId, setSelectedId] = React.useState(
    defaultSelectedId || (options.filter(i => i)[0] as HorizontalSelectOption).id,
  );

  return (
    <div className={css.container}>
      <div className={cx('d-flex align-items-center flex-nowrap overflow-auto', className)}>
        {options
          .filter(i => i)
          .map((_option, index) => {
            const option = _option as HorizontalSelectOption;
            const selected = option.id === (selectedId || _selectedId);
            return (
              <button
                className={cx(
                  'bg-transparent p-0 position-relative',
                  index !== options.length - 1 && 'me-4',
                  css.option,
                  option.className,
                )}
                key={option.id}
                type='button'
                onClick={() => {
                  setSelectedId(option.id);
                  onSelect(option);
                }}
              >
                <HorizontalSelectOption
                  label={option.label}
                  selected={selected}
                  isLastItem={index === options.length - 1}
                />
                {option.badge && (
                  <div className={css.badge}>
                    <p>{option.badge}</p>
                  </div>
                )}
              </button>
            );
          })}
      </div>
    </div>
  );
};
