import React from 'react';
import ReactDOM from 'react-dom';
import { Menu } from 'react-feather';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { cx } from 'src/shared/utils/common';
import { AuthContextProvider } from 'src/context/authContext';
import { HeaderUserAvatar } from 'src/lyg/features/appLayout/ui/HeaderUserAvatar';
import { useSidebarVisibilityContext } from 'src/features/appLayout/SidebarContext';
import { HeaderAccountIcon } from 'src/features/appLayout/ui/HeaderAccountIcon';
import { Breadcrumb } from 'src/features/appLayout/ui/PageHeader/Breadcrumb';
import { LYGAppNotifications } from 'src/lyg/features/appLayout/ui/AppNotifications';
import css from './PageHeaderBar.module.scss';

const HeaderBar: React.FC = observer(() => {
  const { toggleVisibility, isTopbarDisplay } = useSidebarVisibilityContext();
  const position = useLocalObservable(() => {
    return {
      showShadow: false,
      setPosition(scrollPosition: number) {
        this.showShadow = scrollPosition > 0;
      },
    };
  });

  React.useEffect(() => {
    const handleScroll = () => position.setPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [position]);

  return (
    <header
      className={cx(
        'position-fixed top-0 start-0 end-0',
        css.headerBar,
        position.showShadow && css.showShadow,
        isTopbarDisplay && css.showBar,
      )}
    >
      <div className={cx('h-100 d-flex align-items-center', css.headerPadding)}>
        <button
          className={cx('p-0', css.menuButton)}
          onClick={toggleVisibility}
          name='Open menu'
          aria-label='Open menu'
        >
          <Menu className='d-block' />
        </button>
        <Breadcrumb className='ms-3' showOnlyFirstPath defaultTitle='London Youth Games' />
        <div className={cx('d-flex align-items-center ms-auto', css.controls)}>
          <HeaderAccountIcon />
          <AuthContextProvider>
            <LYGAppNotifications />
            <HeaderUserAvatar />
          </AuthContextProvider>
        </div>
      </div>
    </header>
  );
});

const TOP_BAR_ROOT_ID = 'root';
const topBarRoot = document.getElementById(TOP_BAR_ROOT_ID);

export const PageHeaderBar: React.FC = () => {
  if (!topBarRoot) {
    throw new Error(`Element with "${TOP_BAR_ROOT_ID}" ID could not be found!`);
  }

  return ReactDOM.createPortal(<HeaderBar />, topBarRoot);
};
