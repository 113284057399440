import { apiClient } from 'src/shared/utils/apiClient';

interface CancelVideoUploadRequest {
  uploadId: string;
  fileKey: string;
}

export function cancelVideoUpload(request: CancelVideoUploadRequest, token: AccessToken): Promise<void> {
  return apiClient(`/v1/service/providers/aws/abort`, {
    token,
    method: 'POST',
    body: JSON.stringify({ upload_id: request.uploadId, file_key: request.fileKey }),
  });
}
