import { useEffect, useState } from 'react';

type Orientation = 'portrait' | 'landscape';

export const checkOrientation = (): Orientation => {
  let currMode: Orientation = 'portrait';

  switch (window.orientation) {
    case 0:
      currMode = 'portrait';
      break;

    case -90:
      currMode = 'landscape';
      break;

    case 90:
      currMode = 'landscape';
      break;

    case 180:
      currMode = 'portrait';
      break;
  }

  return currMode;
};

export const useIsLandscape = () => {
  const [orientation, setOrientation] = useState<Orientation>();

  const onOrientationChange = () => {
    setOrientation(checkOrientation());
  };

  useEffect(() => {
    onOrientationChange();
  }, []);

  useEffect(() => {
    // Fix error on iOS phones
    if (!window?.screen || !screen?.orientation || typeof screen?.orientation?.angle !== 'number') return;

    screen.orientation.addEventListener('change', onOrientationChange);

    return () => {
      screen.orientation.removeEventListener('change', onOrientationChange);
    };
  }, []);

  return orientation === 'landscape';
};
