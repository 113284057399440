export const FILE_STORAGE_HOSTNAME = 'https://files.teamsportz.app';
export const SCORING_SHEET_TEMPLATE = '/assets/cc_files/TraditionalScoringSheet.xlsx';
export const VIDEO_STORAGE_HOSTNAME = 'https://videos.teamsportz.pro';
export const VIDEO_SHARE_BASE_URL = 'https://api.teamsportz.app/v1/share/highlight/';

// Needs to match the breakpoints in variables.scss
export const CSS_BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const THEME_COLORS = {
  first: '#fc5352',
  firstHover: '#fc535280',
  second: '#4b8ee2',
  secondHover: '#4b8ee280',
  third: '#ffbd00',
  thirdHover: '#ffbd0080',
  fourth: '#90c765',
  fourthHover: '#90c76535',
  track: '#d5dae1',
  disabled: '#BBC3D3',
  grey: '#EFF1F9',
};
