import { action, computed, makeObservable, observable, reaction } from 'mobx';
import React from 'react';
import { IntroStep } from 'src/features/appLayout/ui/IntroHelper/introConfig';

type IntroStepWithElement = IntroStep & { element: HTMLElement };
type IntroHelperPreferences = Record<string, boolean>;
export class IntroHelperModel {
  stepsConfig: IntroStep[] | null;
  subscribedSteps = observable.array<IntroStepWithElement>([], {
    equals: (a, b) => a.element === b.element,
  });
  stepIndex: number | null = 0;
  stepsCount = 0;
  isReady = false;
  readonly helperRef = React.createRef<HTMLDivElement>();
  readonly tooltipRef = React.createRef<HTMLDivElement>();
  readonly STORAGE_KEY = 'INTRO_HELPER';
  readonly currentPageId: string | null;

  constructor(initialStepsConfig: IntroStep[] | undefined, pageId: string | undefined) {
    this.currentPageId = pageId ?? null;
    const shouldShow = pageId ? this.shouldShowIntro(pageId) : true;
    this.stepsConfig = shouldShow ? initialStepsConfig ?? null : null;
    this.stepsCount = initialStepsConfig?.length ?? 0;

    makeObservable(this, {
      helperRef: observable.ref,
      tooltipRef: observable.ref,
      stepIndex: observable,
      prevStep: action.bound,
      nextStep: action.bound,
      end: action.bound,
      isFirstStep: computed,
      isLastStep: computed,
      currentElement: computed,
      currentStep: computed,
      subscribe: action.bound,
      checkIfReady: action.bound,
      isReady: observable,
      shouldHide: computed,
    });

    reaction(
      () => this.stepIndex,
      () => this.computeHelperSize(),
    );
  }

  get currentStep(): IntroStep | null {
    if (this.stepIndex === null || !this.subscribedSteps.length) return null;
    return this.subscribedSteps[this.stepIndex] ?? null;
  }

  get isFirstStep(): boolean {
    return this.stepIndex === 0;
  }

  get isLastStep(): boolean {
    return this.stepIndex === this.stepsCount - 1;
  }

  get currentElement(): HTMLElement | null {
    if (this.stepIndex === null) return null;
    return this.subscribedSteps?.[this.stepIndex]?.element ?? null;
  }

  get shouldHide(): boolean {
    return !this.subscribedSteps.length || !this.currentStep || !this.isReady;
  }

  shouldShowIntro(pageId: string): boolean {
    const preferences = localStorage.getItem(this.STORAGE_KEY);
    if (!preferences) return true;
    const parsedPreferences = JSON.parse(preferences) as IntroHelperPreferences;
    return Boolean(parsedPreferences[pageId]);
  }

  savePreference(): void {
    if (!this.currentPageId) return;
    const preferences = localStorage.getItem(this.STORAGE_KEY);
    if (!preferences) return localStorage.setItem(this.STORAGE_KEY, JSON.stringify({ [this.currentPageId]: false }));

    const newPreferences = JSON.parse(preferences) as IntroHelperPreferences;
    newPreferences[this.currentPageId] = false;
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(newPreferences));
  }

  checkIfReady(): void {
    if (this.subscribedSteps.length !== this.stepsConfig?.length) return;
    const areAllElementsSubscribed = this.stepsConfig
      .map(({ id }) => id)
      .every(subscribedId => this.subscribedSteps.map(({ id }) => id).includes(subscribedId));
    if (!areAllElementsSubscribed) return;

    const orderedSteps: IntroStepWithElement[] = [];
    this.subscribedSteps.forEach(step => {
      const realIndex = this.stepsConfig?.findIndex(({ id }) => step.id === id) as number;
      orderedSteps[realIndex] = step;
    });

    this.subscribedSteps.replace(orderedSteps);
    this.isReady = true;
    this.computeHelperSize();
  }

  subscribe(elementId: string, ref: HTMLElement) {
    if (!this.stepsConfig) return;
    const currentStepConfig = this.stepsConfig.find(({ id }) => id === elementId);
    if (!currentStepConfig) return console.warn(`Cannot subscribe element with id '${elementId}' on intro steps`);
    const subscribedStepIndex = this.subscribedSteps.findIndex(({ id }) => currentStepConfig.id === id);
    subscribedStepIndex >= 0
      ? this.subscribedSteps.splice(subscribedStepIndex, 1, { element: ref, ...currentStepConfig })
      : this.subscribedSteps.push({ element: ref, ...currentStepConfig });
    this.checkIfReady();
  }

  computeHelperSize(): void {
    if (!this.isReady) return;
    const helper = this.helperRef.current;
    const tooltip = this.tooltipRef.current;
    const boundingClient = this.currentElement?.getBoundingClientRect();
    if (!boundingClient || !helper || !tooltip) return;

    const padding = 16;
    const absoluteTop = boundingClient.top + window.scrollY;
    helper.style.width = `${boundingClient.width + padding}px`;
    helper.style.height = `${boundingClient.height + padding}px`;
    helper.style.top = `${absoluteTop - padding / 2}px`;
    helper.style.left = `${boundingClient.left - padding / 2}px`;
    tooltip.style.bottom = `-${tooltip.getBoundingClientRect().height + 15}px`;

    const scrollValue = absoluteTop - window.innerHeight / 4;
    window.scrollTo({ top: scrollValue, behavior: 'auto' });
  }

  end(): void {
    this.stepIndex = null;
    this.savePreference();
  }

  prevStep(): void {
    if (this.isFirstStep || this.stepIndex === null) return;
    this.stepIndex--;
  }

  nextStep(): void {
    if (this.stepIndex === null) return;
    if (this.isLastStep) return this.end();
    this.stepIndex++;
  }
}
