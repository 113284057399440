import { useState, useCallback } from 'react';

export function useIsLeague() {
  const [isLeague, setIsLeague] = useState(false);

  const updateIsLeague = useCallback((value: boolean) => {
    setIsLeague(value);
  }, []);

  return { isLeague, updateIsLeague };
}
