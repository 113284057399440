import { UseQueryResult, useQuery } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { LYGBorough } from 'src/lyg/entities/boroughs';
import { apiClient } from 'src/lyg/utils/apiClient';
import { ApiError } from 'src/lyg/utils/apiError';

interface BoroughsResponse {
  lyg_borough_id: number;
  lyg_borough_image: string;
  lyg_borough_name: string;
}

interface GetBoroughsResponse {
  result: BoroughsResponse[];
}

async function getBoroughs(token: AccessToken): Promise<GetBoroughsResponse> {
  return apiClient(`/v1/lyg/boroughs`, { token });
}

const BOROUGHS_KEY = 'LYG_BOROUGHS';

export function useLYGBoroughsQuery(): UseQueryResult<LYGBorough[], ApiError> {
  const { token } = useAuthContext();

  return useQuery<LYGBorough[], ApiError>(
    [BOROUGHS_KEY],
    async () => {
      const { result } = await getBoroughs(token);

      return result.map<LYGBorough>(borough => ({
        id: borough.lyg_borough_id,
        logoUrl: borough.lyg_borough_image,
        name: borough.lyg_borough_name,
      }));
    },
    {
      onSuccess(data) {
        localStorage.setItem(BOROUGHS_KEY, JSON.stringify(data));
      },
      initialData() {
        const result = localStorage.getItem(BOROUGHS_KEY);
        if (!result) return undefined;
        return JSON.parse(result) as LYGBorough[];
      },
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );
}
