import { makeObservable } from 'mobx';
import { I18nModel } from 'src/context/i18n/I18nModel';
import { createBoroughPickerDropdown } from 'src/lyg/features/boroughPicker';
import { createSchoolPickerDropdown } from 'src/lyg/features/schoolPicker';
import { DROPDOWN_BLANK_VALUE, DropdownFieldModel } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';
import { TEXT_FIELD_TYPE, TextFieldModel } from 'src/shared/ui/inputs/text/TextFieldModel';
import { DateFieldModel } from 'src/shared/ui/inputs/datetime/DateFieldModel';
import { BooleanFieldModel } from 'src/shared/ui/inputs/boolean/BooleanFieldModel';
import { LYGEntry } from 'src/lyg/entities/entries/entry';

interface FormData {
  name: string;
  gender: string;
  email: string;
  parentName: string;
  parentEmail: string;
  dob: Date;
  borough: string;
  school: string;
  ethnicity: string;
  disability: string;
  homePostCode: string;
  emergencyContactNumber: string;
  emergencyContactName: string;
  imageryConsent: boolean;
  aquathlonReserve: boolean;
  athleticsTrackEvent: string;
  athleticsFieldEvent: string;
  athleticsFourTimesOneHundredMetersRelay: boolean;
  bmxLicenseNumber: string;
  bmxGrade: string;
  bmxReserve: boolean;
  indoorRowingReserve: boolean;
  indoorRowingCategoryAgeGroup: string;
  judoLicenseNumber: string;
  judoDanGrade: string;
  judoSection: string;
  judoWeightBoysSectionA: string;
  judoWeightBoysSectionB: string;
  judoWeightBoysSectionC: string;
  judoWeightGirlsSectionA: string;
  judoWeightGirlsSectionB: string;
  judoWeightGirlsSectionC: string;
  kayakSlalomSeniority: string;
  kayakSprintAgeCategory: string;
  kayakSprintReserve: boolean;
  pgAthleticsDisabilityCategory: string;
  pgAthleticsEvent1: string;
  pgAthleticsEvent2: string;
  pgAthleticsFourTimesOneHundredMetersRelay: boolean;
  pgSwimmingFourTimesTwentyFiveMetersBreaststroke: boolean;
  pgSwimmingFourTimesTwentyFiveMetersFreestyle: boolean;
  pgSwimmingEvent: string;
  pgSwimmingReserve: boolean;
  swimmingEvent: string;
  swimmingReserve: boolean;
  swimmingMembershipNumber: string;
  swimmingEntryTime: string;
  swimmingEntryTimeCourse: string;
  swimmingRecreationalEvent: string;
  swimmingRecreationalEventNumber: string;
  swimmingRecreationalAgeGroup: string;
  swimmingRecreationalEventSex: string;
  swimmingRecreationalLevel: string;
  swimmingCompetitionEvent: string;
  swimmingParaSwimmingClassification: string;
  swimmingFourTimesFiftyMetersMedley: boolean;
  swimmingFourTimesFiftyMetersFreestyle: boolean;
  swimmingCompetitiveEvent: string;
  swimmingCompetitiveEventNumber: string;
  swimmingCompetitiveAgeGroup: string;
  swimmingCompetitiveEventSex: string;
  swimmingCompetitiveLevel: string;
  swimmingCompetitiveRegistrationNumber: string;
  swimmingCompetitiveEntryTime: string;
  swimmingCompetitiveEntryTimeCourse: string;
  tennisMembershipNumber: string;
  tennisItfWorldTennisNumber: string;
  tennisSeniority: string;
  trampolineReserve: boolean;
  trampolineTier: string;
  trampolineMembershipNumber: string;
  crossCountryAgeCategory: string;
  roadCyclingMembershipNumber: string;
  roadCyclingTimeTrialCompetitor: boolean;
  roadCyclingRoadRaceCompetitor: boolean;
  roadCyclingTimeTrialCategory: string;
  roadCyclingReserve: boolean;
}

interface LYGEntryImportModelProps {
  i18n: I18nModel;
}

export class LYGEntryOpenGamesModel {
  nameField: TextFieldModel;
  genderField: DropdownFieldModel;
  emailField: TextFieldModel;
  parentNameField: TextFieldModel;
  parentEmailField: TextFieldModel;
  dobField: DateFieldModel;
  boroughPicker: DropdownFieldModel;
  ethnicityField: TextFieldModel;
  disabilityField: TextFieldModel;
  homePostCodeField: TextFieldModel;
  schoolPicker: DropdownFieldModel;
  emergencyContactNumber: TextFieldModel;
  emergencyContactName: TextFieldModel;
  imageryConsent: BooleanFieldModel;

  aquathlonReserve: BooleanFieldModel;

  athleticsTrackEvent: TextFieldModel;
  athleticsFieldEvent: TextFieldModel;
  athleticsFourTimesOneHundredMetersRelay: BooleanFieldModel;

  bmxLicenseNumber: TextFieldModel;
  bmxGrade: TextFieldModel;
  bmxReserve: BooleanFieldModel;

  indoorRowingReserve: BooleanFieldModel;
  indoorRowingCategoryAgeGroup: TextFieldModel;
  judoLicenseNumber: TextFieldModel;

  judoDanGrade: TextFieldModel;
  judoSection: TextFieldModel;
  judoWeightBoysSectionA: TextFieldModel;
  judoWeightBoysSectionB: TextFieldModel;
  judoWeightBoysSectionC: TextFieldModel;
  judoWeightGirlsSectionA: TextFieldModel;
  judoWeightGirlsSectionB: TextFieldModel;
  judoWeightGirlsSectionC: TextFieldModel;

  kayakSlalomSeniority: TextFieldModel;
  kayakSprintAgeCategory: TextFieldModel;
  kayakSprintReserve: BooleanFieldModel;

  pgAthleticsDisabilityCategory: TextFieldModel;
  pgAthleticsEvent1: TextFieldModel;
  pgAthleticsEvent2: TextFieldModel;
  pgAthleticsFourTimesOneHundredMetersRelay: BooleanFieldModel;

  pgSwimmingFourTimesTwentyFiveMetersBreaststroke: BooleanFieldModel;
  pgSwimmingFourTimesTwentyFiveMetersFreestyle: BooleanFieldModel;
  pgSwimmingEvent: TextFieldModel;
  pgSwimmingReserve: BooleanFieldModel;

  swimmingReserve: BooleanFieldModel;
  swimmingMembershipNumber: TextFieldModel;
  swimmingEntryTime: TextFieldModel;
  swimmingEntryTimeCourse: TextFieldModel;
  swimmingCompetitionEvent: TextFieldModel;
  swimmingParaSwimmingClassification: TextFieldModel;
  swimmingFourTimesFiftyMetersMedley: BooleanFieldModel;
  swimmingFourTimesFiftyMetersFreestyle: BooleanFieldModel;
  swimmingEvent: TextFieldModel;

  swimmingRecreationalEvent: TextFieldModel;
  swimmingRecreationalEventNumber: TextFieldModel;
  swimmingRecreationalAgeGroup: TextFieldModel;
  swimmingRecreationalEventSex: TextFieldModel;
  swimmingRecreationalLevel: TextFieldModel;

  swimmingCompetitiveEvent: TextFieldModel;
  swimmingCompetitiveEventNumber: TextFieldModel;
  swimmingCompetitiveAgeGroup: TextFieldModel;
  swimmingCompetitiveEventSex: TextFieldModel;
  swimmingCompetitiveLevel: TextFieldModel;
  swimmingCompetitiveRegistrationNumber: TextFieldModel;
  swimmingCompetitiveEntryTime: TextFieldModel;
  swimmingCompetitiveEntryTimeCourse: TextFieldModel;

  tennisMembershipNumber: TextFieldModel;
  tennisItfWorldTennisNumber: TextFieldModel;
  tennisSeniority: TextFieldModel;

  crossCountryAgeCategory: TextFieldModel;

  roadCyclingMembershipNumber: TextFieldModel;
  roadCyclingTimeTrialCompetitor: BooleanFieldModel;
  roadCyclingRoadRaceCompetitor: BooleanFieldModel;
  roadCyclingTimeTrialCategory: TextFieldModel;
  roadCyclingReserve: BooleanFieldModel;

  trampolineReserve: BooleanFieldModel;
  trampolineTier: TextFieldModel;
  trampolineMembershipNumber: TextFieldModel;

  constructor(props: LYGEntryImportModelProps) {
    this.nameField = new TextFieldModel({
      i18n: props.i18n,
      id: 'nameField ',
      initialValue: '',
      title: 'Name',
    });
    this.genderField = new DropdownFieldModel({
      i18n: props.i18n,
      id: 'genderField',
      initialValue: DROPDOWN_BLANK_VALUE,
      title: 'Gender',
      options: [
        { id: 'Male', label: 'Male' },
        { id: 'Female', label: 'Female' },
        { id: 'Prefer Not to Say', label: 'Prefer Not to Say' },
        { id: 'Other', label: 'Other' },
      ],
    });
    this.emailField = new TextFieldModel({
      i18n: props.i18n,
      id: 'emailField ',
      initialValue: '',
      title: 'Email',
    });
    this.parentNameField = new TextFieldModel({
      i18n: props.i18n,
      id: 'parentNameField',
      initialValue: '',
      title: 'Parent name',
    });
    this.parentEmailField = new TextFieldModel({
      i18n: props.i18n,
      id: 'parentEmailField',
      initialValue: '',
      title: 'Parent email',
    });
    this.dobField = new DateFieldModel({
      i18n: props.i18n,
      id: 'dobField',
      initialValue: new Date(),
      title: 'Date of birth',
    });
    this.boroughPicker = createBoroughPickerDropdown({ i18n: props.i18n });
    this.ethnicityField = new TextFieldModel({
      i18n: props.i18n,
      id: 'ethnicityField ',
      initialValue: '',
      title: 'Ethnicity',
    });
    this.disabilityField = new TextFieldModel({
      i18n: props.i18n,
      id: 'disabilityField ',
      initialValue: '',
      title: 'Disability',
    });
    this.homePostCodeField = new TextFieldModel({
      i18n: props.i18n,
      id: 'homePostCode',
      initialValue: '',
      title: 'Home postcode',
    });
    this.schoolPicker = createSchoolPickerDropdown({ i18n: props.i18n });
    this.emergencyContactNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'emergencyContactNumber',
      initialValue: '',
      title: 'Emergency contact number',
    });
    this.emergencyContactName = new TextFieldModel({
      i18n: props.i18n,
      id: 'emergencyContactName',
      initialValue: '',
      title: 'Emergency contact name',
    });
    this.imageryConsent = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'imageryConsent',
      initialValue: false,
      title: 'Imagery consent',
    });

    this.aquathlonReserve = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'aquathlonReserve',
      initialValue: false,
      title: 'Aquathlon reserve',
    });

    this.athleticsTrackEvent = new TextFieldModel({
      i18n: props.i18n,
      id: 'athleticsTrackEvent',
      initialValue: '',
      title: 'Track event',
    });
    this.athleticsFieldEvent = new TextFieldModel({
      i18n: props.i18n,
      id: 'athleticsFieldEvent',
      initialValue: '',
      title: 'Field event',
    });
    this.athleticsFourTimesOneHundredMetersRelay = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'athleticsFourTimesOneHundredMetersRelay',
      initialValue: false,
      title: '4x100m relay',
    });

    this.bmxLicenseNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'bmxLicenseNumber',
      initialValue: '',
      title: 'BMX license number',
    });
    this.bmxGrade = new TextFieldModel({
      i18n: props.i18n,
      id: 'bmxGrade ',
      initialValue: '',
      title: 'BMX grade',
    });
    this.bmxReserve = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'bmxReserve',
      initialValue: false,
      title: 'BMX reserve',
    });

    this.indoorRowingReserve = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'indoorRowingReserve',
      initialValue: false,
      title: 'Indoor rowing reserve',
    });
    this.indoorRowingCategoryAgeGroup = new TextFieldModel({
      i18n: props.i18n,
      id: 'indoorRowingCategoryAgeGroup',
      initialValue: '',
      title: 'Category age group',
    });
    this.judoLicenseNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoLicenseNumber',
      initialValue: '',
      title: 'Judo license number',
    });
    this.judoWeightBoysSectionA = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoWeightBoysSectionA',
      initialValue: '',
      title: 'Judo weight boys section A',
    });
    this.judoWeightBoysSectionB = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoWeightBoysSectionB',
      initialValue: '',
      title: 'Judo weight boys section B',
    });
    this.judoWeightBoysSectionC = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoWeightBoysSectionC',
      initialValue: '',
      title: 'Judo weight boys section C',
    });
    this.judoWeightGirlsSectionA = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoWeightGirlsSectionA',
      initialValue: '',
      title: 'Judo weight girls section A',
    });
    this.judoWeightGirlsSectionB = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoWeightGirlsSectionB',
      initialValue: '',
      title: 'Judo weight girls section B',
    });
    this.judoWeightGirlsSectionC = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoWeightGirlsSectionC',
      initialValue: '',
      title: 'Judo weight girls section C',
    });
    this.judoDanGrade = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoDanGrade',
      initialValue: '',
      title: 'Judo dan grade',
    });
    this.judoSection = new TextFieldModel({
      i18n: props.i18n,
      id: 'judoSection',
      initialValue: '',
      title: 'Judo Section',
    });
    this.kayakSlalomSeniority = new TextFieldModel({
      i18n: props.i18n,
      id: 'kayakSlalomSeniority',
      initialValue: '',
      title: 'Kayak slalom seniority',
    });
    this.kayakSprintAgeCategory = new TextFieldModel({
      i18n: props.i18n,
      id: 'kayakSprintAge',
      initialValue: '',
      title: 'Kayak sprint age category',
    });
    this.kayakSprintReserve = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'kayakSprintReserver',
      initialValue: false,
      title: 'Kayak sprint reserve',
    });
    this.pgAthleticsDisabilityCategory = new TextFieldModel({
      i18n: props.i18n,
      id: 'pgAthleticsDisability',
      initialValue: '',
      title: 'Disability category',
    });
    this.pgAthleticsEvent1 = new TextFieldModel({
      i18n: props.i18n,
      id: 'pgAthleticsEvent1',
      initialValue: '',
      title: 'Event 1',
    });
    this.pgAthleticsEvent2 = new TextFieldModel({
      i18n: props.i18n,
      id: 'pgAthleticsEvent2',
      initialValue: '',
      title: 'Event 2',
    });
    this.pgAthleticsFourTimesOneHundredMetersRelay = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'pgAthleticsFourTimesOneHundredMetersRelay',
      initialValue: false,
      title: '4x100m relay',
    });
    this.pgSwimmingFourTimesTwentyFiveMetersBreaststroke = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'pgSwimmingFourTimesTwentyFiveMetersBreaststroke',
      initialValue: false,
      title: '4x25m breaststroke',
    });
    this.pgSwimmingFourTimesTwentyFiveMetersFreestyle = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'pgFourTimesTwentyFiveMetersFreestyle',
      initialValue: false,
      title: '4x25m relay',
    });
    this.pgAthleticsFourTimesOneHundredMetersRelay = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'pgAthleticsFourTimesOneHundredMetersRelay',
      initialValue: false,
      title: '4x100m relay',
    });
    this.pgSwimmingReserve = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'pgSwimmingReserve',
      initialValue: false,
      title: 'PG Swimming reserve',
    });
    this.pgSwimmingEvent = new TextFieldModel({
      i18n: props.i18n,
      id: 'pgSwimmingEvent',
      initialValue: '',
      title: 'Swimmming Event',
    });
    this.swimmingReserve = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'swimmingReserve',
      initialValue: false,
      title: 'Swimming reserve',
    });
    this.swimmingMembershipNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingMembershipNumber',
      initialValue: '',
      title: 'Membership number',
    });
    this.swimmingEntryTime = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingEntryTime',
      initialValue: '',
      title: 'Entry time',
    });
    this.swimmingEntryTimeCourse = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingEntryTime',
      initialValue: '',
      title: 'Entry time course',
    });
    this.swimmingEvent = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingEvent',
      initialValue: '',
      title: 'Swimming Event',
    });
    this.swimmingCompetitionEvent = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitionEvent',
      initialValue: '',
      title: 'Competition event',
    });
    this.swimmingParaSwimmingClassification = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingParaSwimming',
      initialValue: '',
      title: 'Para swimming classification',
    });
    this.swimmingFourTimesFiftyMetersMedley = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'swimmingFourTimesFiftyMetersMedley',
      initialValue: false,
      title: '4x50m medley',
    });
    this.swimmingFourTimesFiftyMetersFreestyle = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'swimmingFourTimesFiftyMetersFreestyle',
      initialValue: false,
      title: '4x50m freestyle',
    });
    this.swimmingRecreationalEvent = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingRecreationalEvent',
      initialValue: '',
      title: 'Event',
    });
    this.swimmingRecreationalEventNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingRecreationalEventNumber',
      type: TEXT_FIELD_TYPE.number,
      initialValue: '',
      title: 'Event number',
    });
    this.swimmingRecreationalAgeGroup = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingRecreationalAgeGroup',
      initialValue: '',
      title: 'Age group',
    });
    this.swimmingRecreationalEventSex = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingRecreationalEventSex',
      initialValue: '',
      title: 'Event sex',
    });
    this.swimmingRecreationalLevel = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingRecreationalLevel',
      initialValue: '',
      title: 'Level',
    });

    this.swimmingCompetitiveEvent = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitiveEvent',
      initialValue: '',
      title: 'Event',
    });
    this.swimmingCompetitiveEventNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitiveEventNumber',
      type: TEXT_FIELD_TYPE.number,
      initialValue: '',
      title: 'Event number',
    });
    this.swimmingCompetitiveAgeGroup = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitiveAgeGroup',
      initialValue: '',
      title: 'Age group',
    });
    this.swimmingCompetitiveEventSex = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitiveEventSex',
      initialValue: '',
      title: 'Event sex',
    });
    this.swimmingCompetitiveLevel = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitiveLevel',
      initialValue: '',
      title: 'Level',
    });
    this.swimmingCompetitiveRegistrationNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitiveRegistrationNumber',
      initialValue: '',
      title: 'Registration number',
    });
    this.swimmingCompetitiveEntryTime = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitiveEntryTime',
      initialValue: '',
      title: 'Entry time',
    });
    this.swimmingCompetitiveEntryTimeCourse = new TextFieldModel({
      i18n: props.i18n,
      id: 'swimmingCompetitiveEntryTimeCourse',
      initialValue: '',
      title: 'Entry time course',
    });

    this.tennisMembershipNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'tennisMembershipNumber',
      initialValue: '',
      title: 'Membership number',
    });
    this.tennisItfWorldTennisNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'tennisItfWorld',
      initialValue: '',
      title: 'ITF world tennis number',
    });
    this.tennisSeniority = new TextFieldModel({
      i18n: props.i18n,
      id: 'tennisSeniority ',
      initialValue: '',
      title: 'Seniority',
    });
    this.crossCountryAgeCategory = new TextFieldModel({
      i18n: props.i18n,
      id: 'crossCountryAgeCategory ',
      initialValue: '',
      title: 'Age category',
    });
    this.roadCyclingMembershipNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'roadCyclingMembershipNumber ',
      initialValue: '',
      title: 'License Number',
    });
    this.roadCyclingTimeTrialCompetitor = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'roadCyclingTimeTrialCompetitor',
      initialValue: false,
      title: 'Time Trial Competitor?',
    });
    this.roadCyclingRoadRaceCompetitor = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'roadCyclingTimeTrialCompetitor',
      initialValue: false,
      title: 'Road Race Competitor?',
    });
    this.roadCyclingTimeTrialCategory = new TextFieldModel({
      i18n: props.i18n,
      id: 'roadCyclingTimeTrialCategory',
      initialValue: '',
      title: 'Time Trial Category',
    });
    this.roadCyclingReserve = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'roadCyclingReserve',
      initialValue: false,
      title: 'Road Cycling Reserve?',
    });
    this.trampolineReserve = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'trampolineReserve',
      initialValue: false,
      title: 'Trampoline reserve',
    });
    this.trampolineTier = new TextFieldModel({
      i18n: props.i18n,
      id: 'trampolineTier ',
      initialValue: '',
      title: 'Tier',
    });
    this.trampolineMembershipNumber = new TextFieldModel({
      i18n: props.i18n,
      id: 'trampolineMembershipNumber',
      initialValue: '',
      title: 'Membership number',
    });

    makeObservable(this, {});
  }

  get data(): FormData {
    return {
      name: this.nameField.value,
      gender: this.genderField.value.id,
      email: this.emailField.value,
      parentName: this.parentNameField.value,
      parentEmail: this.parentEmailField.value,
      dob: this.dobField.value as Date,
      borough: this.boroughPicker.value.id,
      school: this.schoolPicker.value.label,
      ethnicity: this.ethnicityField.value,
      disability: this.disabilityField.value,
      homePostCode: this.homePostCodeField.value,
      emergencyContactNumber: this.emergencyContactNumber.value,
      emergencyContactName: this.emergencyContactName.value,
      imageryConsent: this.imageryConsent.value,
      aquathlonReserve: this.aquathlonReserve.value,
      athleticsTrackEvent: this.athleticsTrackEvent.value,
      athleticsFieldEvent: this.athleticsFieldEvent.value,
      athleticsFourTimesOneHundredMetersRelay: this.athleticsFourTimesOneHundredMetersRelay.value,
      bmxLicenseNumber: this.bmxLicenseNumber.value,
      bmxGrade: this.bmxGrade.value,
      bmxReserve: this.bmxReserve.value,
      indoorRowingReserve: this.indoorRowingReserve.value,
      indoorRowingCategoryAgeGroup: this.indoorRowingCategoryAgeGroup.value,
      judoLicenseNumber: this.judoLicenseNumber.value,
      judoSection: this.judoSection.value,
      judoDanGrade: this.judoDanGrade.value,
      judoWeightBoysSectionA: this.judoWeightBoysSectionA.value,
      judoWeightBoysSectionB: this.judoWeightBoysSectionB.value,
      judoWeightBoysSectionC: this.judoWeightBoysSectionC.value,
      judoWeightGirlsSectionA: this.judoWeightGirlsSectionA.value,
      judoWeightGirlsSectionB: this.judoWeightGirlsSectionB.value,
      judoWeightGirlsSectionC: this.judoWeightGirlsSectionC.value,
      kayakSlalomSeniority: this.kayakSlalomSeniority.value,
      kayakSprintAgeCategory: this.kayakSprintAgeCategory.value,
      kayakSprintReserve: this.kayakSprintReserve.value,
      pgAthleticsDisabilityCategory: this.pgAthleticsDisabilityCategory.value,
      pgAthleticsEvent1: this.pgAthleticsEvent1.value,
      pgAthleticsEvent2: this.pgAthleticsEvent2.value,
      pgAthleticsFourTimesOneHundredMetersRelay: this.pgAthleticsFourTimesOneHundredMetersRelay.value,
      pgSwimmingFourTimesTwentyFiveMetersBreaststroke: this.pgSwimmingFourTimesTwentyFiveMetersBreaststroke.value,
      pgSwimmingFourTimesTwentyFiveMetersFreestyle: this.pgSwimmingFourTimesTwentyFiveMetersFreestyle.value,
      pgSwimmingReserve: this.pgSwimmingReserve.value,
      pgSwimmingEvent: this.pgSwimmingEvent.value,
      swimmingReserve: this.swimmingReserve.value,
      swimmingMembershipNumber: this.swimmingMembershipNumber.value,
      swimmingEntryTime: this.swimmingEntryTime.value,
      swimmingEntryTimeCourse: this.swimmingEntryTimeCourse.value,
      swimmingEvent: this.swimmingEvent.value,
      swimmingCompetitionEvent: this.swimmingCompetitionEvent.value,
      swimmingParaSwimmingClassification: this.swimmingParaSwimmingClassification.value,
      swimmingFourTimesFiftyMetersMedley: this.swimmingFourTimesFiftyMetersMedley.value,
      swimmingFourTimesFiftyMetersFreestyle: this.swimmingFourTimesFiftyMetersFreestyle.value,
      swimmingRecreationalAgeGroup: this.swimmingRecreationalAgeGroup.value,
      swimmingRecreationalEvent: this.swimmingRecreationalEvent.value,
      swimmingRecreationalEventNumber: this.swimmingRecreationalEventNumber.value,
      swimmingRecreationalEventSex: this.swimmingRecreationalEventSex.value,
      swimmingRecreationalLevel: this.swimmingRecreationalLevel.value,
      swimmingCompetitiveAgeGroup: this.swimmingCompetitiveAgeGroup.value,
      swimmingCompetitiveEvent: this.swimmingCompetitiveEvent.value,
      swimmingCompetitiveEventNumber: this.swimmingCompetitiveEventNumber.value,
      swimmingCompetitiveEventSex: this.swimmingCompetitiveEventSex.value,
      swimmingCompetitiveLevel: this.swimmingCompetitiveLevel.value,
      swimmingCompetitiveRegistrationNumber: this.swimmingCompetitiveRegistrationNumber.value,
      swimmingCompetitiveEntryTime: this.swimmingCompetitiveEntryTime.value,
      swimmingCompetitiveEntryTimeCourse: this.swimmingCompetitiveEntryTimeCourse.value,
      tennisMembershipNumber: this.tennisMembershipNumber.value,
      tennisItfWorldTennisNumber: this.tennisItfWorldTennisNumber.value,
      tennisSeniority: this.tennisSeniority.value,
      trampolineReserve: this.trampolineReserve.value,
      trampolineTier: this.trampolineTier.value,
      trampolineMembershipNumber: this.trampolineMembershipNumber.value,
      crossCountryAgeCategory: this.crossCountryAgeCategory.value,
      roadCyclingMembershipNumber: this.roadCyclingMembershipNumber.value,
      roadCyclingTimeTrialCompetitor: this.roadCyclingTimeTrialCompetitor.value,
      roadCyclingRoadRaceCompetitor: this.roadCyclingRoadRaceCompetitor.value,
      roadCyclingTimeTrialCategory: this.roadCyclingTimeTrialCategory.value,
      roadCyclingReserve: this.roadCyclingReserve.value,
    };
  }

  setDefaultValues(entry: LYGEntry | undefined) {
    if (!entry) return;
    this.nameField.setDefaultValue(entry.name);
    this.genderField.setDefaultValueById(entry.gender);
    this.emailField.setDefaultValue(entry.email ?? '');
    this.parentNameField.setDefaultValue(entry.parentName ?? '');
    this.parentEmailField.setDefaultValue(entry.parentEmail ?? '');
    this.dobField.setDefaultValue(entry.dob);
    this.boroughPicker.setDefaultValue({ id: entry.boroughId.toString(), label: entry.boroughName });
    this.ethnicityField.setDefaultValue(entry.ethnicity);
    this.disabilityField.setDefaultValue(entry.disability);
    this.homePostCodeField.setDefaultValue(entry.homePostcode);
    this.emergencyContactNumber.setDefaultValue(entry.emergencyContactNumber);
    this.emergencyContactName.setDefaultValue(entry.emergencyContactName);
    this.imageryConsent.setDefaultValue(entry.imageryConscent);

    this.aquathlonReserve.setDefaultValue(entry.aquathlon?.reserve ?? false);

    this.athleticsTrackEvent.setDefaultValue(entry.athletics?.trackEvent ?? '');
    this.athleticsFieldEvent.setDefaultValue(entry.athletics?.fieldEvent ?? '');
    this.athleticsFourTimesOneHundredMetersRelay.setDefaultValue(
      entry.athletics?.fourTimesOneHundredMetersRelay ?? false,
    );

    this.bmxLicenseNumber.setDefaultValue(entry.bmx?.licenseNumber ?? '');
    this.bmxGrade.setDefaultValue(entry.bmx?.grade ?? '');
    this.bmxReserve.setDefaultValue(entry.bmx?.reserve ?? false);

    this.indoorRowingReserve.setDefaultValue(entry.indoorRowing?.reserve ?? false);
    this.indoorRowingCategoryAgeGroup.setDefaultValue(entry.indoorRowing?.categoryAgeGroup ?? '');

    this.judoLicenseNumber.setDefaultValue(entry.judo?.licenseNumber ?? '');
    this.judoSection.setDefaultValue(entry.judo?.section.toString() ?? '');
    this.judoDanGrade.setDefaultValue(entry.judo?.danGrade ?? '');
    this.judoWeightBoysSectionA.setDefaultValue(entry.judo?.weightBoysSectionA ?? '');
    this.judoWeightBoysSectionB.setDefaultValue(entry.judo?.weightBoysSectionB ?? '');
    this.judoWeightBoysSectionC.setDefaultValue(entry.judo?.weightBoysSectionC ?? '');
    this.judoWeightGirlsSectionA.setDefaultValue(entry.judo?.weightGirlsSectionA ?? '');
    this.judoWeightGirlsSectionB.setDefaultValue(entry.judo?.weightGirlsSectionB ?? '');
    this.judoWeightGirlsSectionC.setDefaultValue(entry.judo?.weightGirlsSectionC ?? '');

    this.kayakSlalomSeniority.setDefaultValue(entry.kayakSlalom?.seniority ?? '');
    this.kayakSprintAgeCategory.setDefaultValue(entry.kayakSprint?.ageCategory ?? '');
    this.kayakSprintReserve.setDefaultValue(entry.kayakSprint?.reserve ?? false);

    this.pgAthleticsDisabilityCategory.setDefaultValue(entry.pgAthletics?.disabilityCategory ?? '');
    this.pgAthleticsEvent1.setDefaultValue(entry.pgAthletics?.event1 ?? '');
    this.pgAthleticsEvent2.setDefaultValue(entry.pgAthletics?.event2 ?? '');
    this.pgAthleticsFourTimesOneHundredMetersRelay.setDefaultValue(
      entry.pgAthletics?.fourTimesOneHundredMetersRelay ?? false,
    );

    this.pgSwimmingEvent.setDefaultValue(entry.pgSwimming?.swimmingEvent ?? '');
    this.pgSwimmingFourTimesTwentyFiveMetersBreaststroke.setDefaultValue(
      entry.pgSwimming?.fourTimesTwentyFiveMetersBackstroke ?? false,
    );
    this.pgSwimmingFourTimesTwentyFiveMetersFreestyle.setDefaultValue(
      entry.pgSwimming?.fourTimesTwentyFiveMetersFreestyle ?? false,
    );
    this.pgSwimmingReserve.setDefaultValue(entry.pgSwimming?.reserve ?? false);

    this.swimmingEvent.setDefaultValue(entry.swimming?.swimmingEvent ?? '');
    this.swimmingReserve.setDefaultValue(entry.swimming?.reserve ?? false);
    this.swimmingMembershipNumber.setDefaultValue(entry.swimming?.membershipNumber ?? '');
    this.swimmingEntryTime.setDefaultValue(entry.swimming?.entryTime.toString() ?? '');
    this.swimmingEntryTimeCourse.setDefaultValue(entry.swimming?.entryTimeCourse ?? '');
    this.swimmingCompetitionEvent.setDefaultValue(entry.swimming?.competitionEvent ?? '');
    this.swimmingParaSwimmingClassification.setDefaultValue(entry.swimming?.paraSwimmingClassification ?? '');
    this.swimmingFourTimesFiftyMetersMedley.setDefaultValue(entry.swimming?.fourTimesFiftyMetersMedley ?? false);
    this.swimmingFourTimesFiftyMetersFreestyle.setDefaultValue(entry.swimming?.fourTimesFiftyMetersFreestyle ?? false);

    this.swimmingRecreationalEvent.setDefaultValue(entry.swimmingRecreational?.event ?? '');
    this.swimmingRecreationalEventNumber.setDefaultValue(entry.swimmingRecreational?.eventNumber?.toString() ?? '');
    this.swimmingRecreationalAgeGroup.setDefaultValue(entry.swimmingRecreational?.ageGroup ?? '');
    this.swimmingRecreationalEventSex.setDefaultValue(entry.swimmingRecreational?.eventSex ?? '');
    this.swimmingRecreationalLevel.setDefaultValue(entry.swimmingRecreational?.level ?? '');

    this.swimmingCompetitiveEvent.setDefaultValue(entry.swimmingCompetitive?.event ?? '');
    this.swimmingCompetitiveEventNumber.setDefaultValue(entry.swimmingCompetitive?.eventNumber?.toString() ?? '');
    this.swimmingCompetitiveAgeGroup.setDefaultValue(entry.swimmingCompetitive?.ageGroup ?? '');
    this.swimmingCompetitiveEventSex.setDefaultValue(entry.swimmingCompetitive?.eventSex ?? '');
    this.swimmingCompetitiveLevel.setDefaultValue(entry.swimmingCompetitive?.level ?? '');
    this.swimmingCompetitiveRegistrationNumber.setDefaultValue(entry.swimmingCompetitive?.registrationNumber ?? '');
    this.swimmingCompetitiveEntryTime.setDefaultValue(entry.swimmingCompetitive?.entryTime ?? '');
    this.swimmingCompetitiveEntryTimeCourse.setDefaultValue(entry.swimmingCompetitive?.entryTimeCourse ?? '');

    this.tennisMembershipNumber.setDefaultValue(entry.tennis?.membershipNumber ?? '');
    this.tennisItfWorldTennisNumber.setDefaultValue(entry.tennis?.itfWorldTennisNumber ?? '');
    this.tennisSeniority.setDefaultValue(entry.tennis?.seniority ?? '');

    this.trampolineReserve.setDefaultValue(entry.trampoline?.reserve ?? false);
    this.trampolineTier.setDefaultValue(entry.trampoline?.tier ?? '');
    this.trampolineMembershipNumber.setDefaultValue(entry.trampoline?.membershipNumber ?? '');

    this.crossCountryAgeCategory.setDefaultValue(entry.crossCountry?.ageCategory ?? '');

    this.roadCyclingMembershipNumber.setDefaultValue(entry.roadCycling?.licenseNumber ?? '');
    this.roadCyclingTimeTrialCompetitor.setDefaultValue(entry.roadCycling?.timeTrialCompetitor ?? false);
    this.roadCyclingRoadRaceCompetitor.setDefaultValue(entry.roadCycling?.roadRaceCompetitor ?? false);
    this.roadCyclingTimeTrialCategory.setDefaultValue(entry.roadCycling?.timeTrialAgeCategory ?? '');
    this.roadCyclingReserve.setDefaultValue(entry.roadCycling?.reserve ?? false);
  }
}
