import { Observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useI18nContext } from 'src/context/i18n';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { useSetGameScoreMutation } from 'src/lyg/pages/competitions/schedule/mutation';
import { LYGEditGameScoreFormModel } from 'src/lyg/pages/competitions/schedule/ui/LYGEditGameScoreFormModel';
import { CancelButton, PrimaryButton } from 'src/shared/ui/buttons';
import { FormFieldWrapper } from 'src/shared/ui/inputs/FormFieldWrapper';
// import { CheckboxField } from 'src/shared/ui/inputs/boolean/CheckboxField';
import { TextField } from 'src/shared/ui/inputs/text/TextField';
import { ModalDialog } from 'src/shared/ui/modal/ModalDialog';
import { ErrorMessage } from 'src/shared/ui/utils/ErrorMessage';
import { useParams } from 'react-router-dom';
import { useLYGCompetitionQuery } from 'src/lyg/pages/competitions/pools/query';

interface LYGEditGameScoreModalProps {
  game: LYGCompetitionGame;
  numberOfQuarters: number;
  closeDialog(): void;
}

export const LYGEditGameScoreModal: React.FC<LYGEditGameScoreModalProps> = ({
  closeDialog,
  game,
  numberOfQuarters,
}) => {
  const i18n = useI18nContext();
  const [model] = useState(new LYGEditGameScoreFormModel({ i18n, game, numberOfQuarters }));
  // const colSpan = Math.floor(10 / numberOfQuarters);

  const params = useParams();
  const competitionId = Number(params.competitionId as string);
  const { data: competition } = useLYGCompetitionQuery(competitionId);
  const sportName = competition?.sportName;

  const { mutate, isLoading, error } = useSetGameScoreMutation();

  function onSuccess() {
    toast.success(`Score for ${game.teamAName} vs ${game.teamBName} has been updated`);
    closeDialog();
  }

  return (
    <ModalDialog closeDialog={closeDialog} title={`Edit score ${game.teamAName} vs ${game.teamBName}`}>
      <div className='d-flex flex-column'>
        <span className='ts-fw-500 mb-2'>{game.teamAName}</span>
        <div className='d-flex gap-2'>
          {model.scoreA.map(scoreA => (
            <FormFieldWrapper key={scoreA.id} model={scoreA} sportName={sportName}>
              <TextField model={scoreA} className='no-arrow-number-field' />
            </FormFieldWrapper>
          ))}
        </div>
      </div>
      <div className='d-flex flex-column'>
        <span className='ts-fw-500 mb-2'>{game.teamBName}</span>
        <div className='d-flex gap-2'>
          {model.scoreB.map(scoreB => (
            <FormFieldWrapper key={scoreB.id} model={scoreB} sportName={sportName}>
              <TextField model={scoreB} className='no-arrow-number-field' />
            </FormFieldWrapper>
          ))}
        </div>
      </div>
      {/* TODO: implement and fix (attach forfeit to a team) */}
      {/* <FormFieldWrapper model={model.forfeit}>
        <CheckboxField model={model.forfeit} />
      </FormFieldWrapper> */}
      <Observer>
        {() =>
          model.forfeit.value ? (
            <FormFieldWrapper model={model.forfeitReason}>
              <TextField model={model.forfeitReason} />
            </FormFieldWrapper>
          ) : null
        }
      </Observer>

      {error && <ErrorMessage className='mt-3' message={error.message} />}
      <div className='d-flex gap-2'>
        <CancelButton
          disabled={isLoading}
          className='w-100'
          onClick={() => {
            model.reset();
            closeDialog();
          }}
        >
          Cancel
        </CancelButton>
        <Observer>
          {() => (
            <PrimaryButton
              disabled={model.hasError}
              loading={isLoading}
              className='w-100'
              onClick={() =>
                mutate({ gameId: game.id, competitionId: game.competitionId, scores: model.data }, { onSuccess })
              }
            >
              Save
            </PrimaryButton>
          )}
        </Observer>
      </div>
    </ModalDialog>
  );
};
