import { InputFieldModel, InputFieldModelProps } from 'src/shared/ui/inputs/InputFieldModel';

export const enum TEXT_FIELD_TYPE {
  email = 'email',
  number = 'number',
  password = 'password',
  text = 'text',
}

interface TextFieldModelProps extends InputFieldModelProps<string> {
  /**
   * @default 'text'
   */
  type?: TEXT_FIELD_TYPE;
  multiple?: boolean;
  hideText?: boolean;
  typePassword?: boolean;
}

export class TextFieldModel extends InputFieldModel<string, TextFieldModelProps> {
  get type(): TEXT_FIELD_TYPE {
    return this.props.type || TEXT_FIELD_TYPE.text;
  }

  get hasChanged(): boolean {
    return this.value.trim() !== this.defaultValue.trim();
  }

  get multiple(): boolean {
    return Boolean(this.props.multiple);
  }

  get typePassword(): boolean {
    return this.props.typePassword || false;
  }

  protected get requiredIsValid(): boolean {
    return typeof this.value === 'string' && this.value.trim().length > 0;
  }

  setValue(value: string): void {
    if (this.props.title === 'Club Tagline' && value.trim().length > 50) {
      super.setValue(value.trim().substring(0, 50));
    } else {
      super.setValue(value);
    }
  }

  setErrorIsVisible(value: boolean): void {
    this.errorIsVisible = value;
  }
}
