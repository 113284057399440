import React from 'react';
import { Bell } from 'react-feather';
import { useI18nContext } from 'src/context/i18n';
import css from './NoNotificationsMessage.module.scss';

export const NoNotificationsMessage: React.FC = () => {
  const i18n = useI18nContext();
  return (
    <div className='text-center'>
      <Bell className={css.icon} strokeWidth={1} />
      <div className='mt-3'>
        <p className={css.text}>{i18n.t('shared.phrases.notificationsEmpty')}</p>
        <p className={css.text}>{i18n.t('shared.phrases.notificationsCheckLater')}</p>
      </div>
    </div>
  );
};
