export const LYG_MEMBER_ROLE = {
  admin: 'LYG Admin',
  bto: 'LYG BTO',
  btm: 'LYG BTM',
  stm: 'LYG STM',
  sgo: 'LYG SGO',
  sgobto: 'LYG SGO-BTO',
} as const;
export type LYG_MEMBER_ROLE = ValueOf<typeof LYG_MEMBER_ROLE>;

export interface LYGMember {
  id: number;
  firstName: string;
  lastName: string;
  rawRole: string;
  email: string;
  phone: string | null;
  avatarUrl: string | null;
  boroughId: number | null;
  boroughName: string | null;
  schoolId: number | null;
  schoolName: string | null;
  sportId: number | null;
  sportName: string | null;
}

type LYGMemberProps = OnlyData<Omit<LYGMember, 'fullName' | 'formatedRole'>>;

export class LYGMember {
  public constructor(init: LYGMemberProps) {
    Object.assign(this, init);
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get formatedRole(): string {
    switch (this.rawRole) {
      case LYG_MEMBER_ROLE.admin:
        return 'LYG Admin';
      case LYG_MEMBER_ROLE.bto:
        return 'Borough Team Organiser';
      case LYG_MEMBER_ROLE.btm:
        return 'Borough Team Manager';
      case LYG_MEMBER_ROLE.sgo:
        return 'School Games Organiser';
      case LYG_MEMBER_ROLE.stm:
        return 'School Team Manager';
      case LYG_MEMBER_ROLE.sgobto:
        return 'School Game Organiser & Borough Team Organiser';
      default:
        return 'Missing Role';
    }
  }
}
