import React from 'react';
import { ErrorLoadingPage } from 'src/shared/ui/layout/ErrorLoadingPage';
import { UnauthorisedPage } from 'src/shared/ui/layout/UnauthorisedPage';
import { NoNetworkPage } from 'src/shared/ui/layout/NoNetworkPage';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ErrorMessage } from 'src/shared/ui/utils/ErrorMessage';
import { ApiError } from 'src/shared/utils/apiError';
import { PrimaryButton } from 'src/shared/ui/buttons';

interface ErrorPageProps {
  error?: ApiError;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  const routerError = useRouteError();
  const code = error?.code || (isRouteErrorResponse(routerError) && routerError.status);

  if (
    error?.message.includes('Failed to fetch dynamically imported module') ||
    String(error).includes('Failed to fetch dynamically imported module')
  )
    return (
      <div className='d-flex flex-column justify-content-center align-items-center gap-4'>
        <h4>
          Yeah! We have just released new features. We are improving the system by creating new features and squashing
          bugs. Please refresh your application so you can have access to the new features.
        </h4>
        <PrimaryButton className='w-100' onClick={() => window.location.reload()}>
          Refresh!
        </PrimaryButton>
      </div>
    );

  if (code === false)
    return (
      <div className='my-3'>
        <ErrorMessage message='Something went wrong :(' />
      </div>
    );

  switch (code) {
    case 401:
      return <UnauthorisedPage isApiResponse />;
    case 408:
      return <ErrorLoadingPage />;
    case 503:
      return <NoNetworkPage />;

    default:
      return (
        <div className='my-3'>
          <ErrorMessage message={error?.message || 'Something went wrong :('} stack={error?.stack} />
        </div>
      );
  }
};
