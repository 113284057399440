/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { CurrentUser } from 'src/context/authContext/domain';

const intercomAppId = 'lk7wk3i9';
export const INTERCOM_CUSTOM_LAUNCHER_ID = 'intercom-custom-launcher';

export function loadIntercom() {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${intercomAppId}`;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode?.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}

export function initIntercom(currentUser: CurrentUser): void {
  if (!window.Intercom) return;

  window.intercomSettings = {
    app_id: intercomAppId,
    name: currentUser.name,
    first_name: currentUser.name.split(' ')[0],
    last_name: currentUser.name.split(' ')[1],
    email: currentUser.email,
    gender: currentUser.gender,
    user_id: currentUser.id.toString(),
    sport: currentUser.sport,
    role: currentUser.currentRole,
    accountId: currentUser.currentClub,
    accountName: currentUser.clubName,
    coachOnboardingStatus: currentUser.onboardingCompleted,
    user_hash: currentUser.intercomHash,
    hide_default_launcher: true,
    custom_launcher_selector: INTERCOM_CUSTOM_LAUNCHER_ID,
  };

  window.Intercom('boot', { app_id: intercomAppId, custom_launcher_selector: INTERCOM_CUSTOM_LAUNCHER_ID });
}
