import { useContext } from 'react';
import { LYGPusherContext, LYGPusherContextValue } from 'src/lyg/features/appLayout/pusher/LYGPusherContext';
import { browserIsSafari } from 'src/shared/utils/common';

export const useLYGPusher = (): LYGPusherContextValue => {
  const ctx = useContext(LYGPusherContext);

  if (!ctx && !('PushManager' in window) && !browserIsSafari()) {
    return {
      onClear: async () => {
        null;
      },
      onInit: () => new Promise(() => null),
    };
  }

  if (!ctx) {
    throw new Error('useLYGPusher can only be used inside PusherProvider');
  }

  return ctx;
};
