import React from 'react';
import { I18nModel, LocaleData } from 'src/context/i18n/I18nModel';

const I18nContext = React.createContext<I18nModel | null>(null);

const DEFAULT_LOCALE_KEY = 'en-GB';

async function getLocaleData(localeKey: string): Promise<LocaleData> {
  /**
   * The ?v has been implemented to bust the cache until we get rid of Laravel.
   */
  return fetch(`/locales/${localeKey}.json?v=${Math.random()}`).then(response => response.json());
}

export const I18nContextProvider: React.FC = ({ children }) => {
  const [localeData, setLocaleData] = React.useState<LocaleData>();

  React.useEffect(() => {
    (async () => {
      setLocaleData(await getLocaleData(DEFAULT_LOCALE_KEY));
    })();
  }, []);

  if (!localeData) return null;

  return <I18nContext.Provider value={new I18nModel(localeData)}>{children}</I18nContext.Provider>;
};

export function useI18nContext(): I18nModel {
  const context = React.useContext(I18nContext);

  if (!context) {
    throw new Error('useI18n() hook cannot be used outside the I18nProvider component.');
  }

  return context;
}
