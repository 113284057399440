import React from 'react';

export const PlayBookIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={1000} height={1000} viewBox='0 0 1000 1000' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={312.5} cy={729.167} r={150} stroke='currentColor' strokeWidth={75} />
      <path
        d='M377 575.5C391 466 533.333 291.667 750 291.667'
        stroke='currentColor'
        strokeWidth={75}
        strokeLinecap='round'
      />
      <path
        d='M687.5 208.333l104.167 83.334-83.334 104.166M125 333.333L333.333 125M125 125l208.333 208.333M666.667 875L875 666.667m-208.333 0L875 875'
        stroke='currentColor'
        strokeWidth={75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
