import { format, formatDistanceToNowStrict, Locale } from 'date-fns';
import { enGB, enUS } from 'date-fns/locale';
import { useI18nContext } from 'src/context/i18n';

interface SupportedLocales {
  [localeCode: string]: Locale;
}

const supportedLocales: SupportedLocales = {
  'en-GB': enGB,
  'en-US': enUS,
};

interface UseDateTimeFormatReturn {
  localeCode: string;
  supportedLocales: SupportedLocales;
  formatDate(date: Date, format: string): string;
  formatDateToNow(date: Date): string;
  formatTime(date: Date): string;
}

export function useDateTimeFormat(): UseDateTimeFormatReturn {
  const i18n = useI18nContext();
  const localeCode = i18n.t('region.localeCode');
  const locale = supportedLocales[localeCode] || enGB;

  return {
    localeCode,
    supportedLocales,
    formatDate: (date, dateFormat) => format(date, dateFormat, { locale }),
    formatDateToNow: date => formatDistanceToNowStrict(date, { addSuffix: true, locale }),
    formatTime: date => {
      // Workaround to show UTC formated dates
      const utcDate = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDay(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds(),
      );
      // return format(utcDate, i18n.t('region.hour12') ? 'hh:mm a' : 'HH:mm');
      return format(utcDate, 'h:mm a');
    },
  };
}
