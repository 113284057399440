import { computed, makeObservable } from 'mobx';
import { I18nModel } from 'src/context/i18n/I18nModel';
import { createBoroughPickerDropdown } from 'src/lyg/features/boroughPicker';
import { createSchoolPickerDropdown } from 'src/lyg/features/schoolPicker';
import {
  DemographicFormData,
  LYGSchoolEntryDemographicDataModel,
} from 'src/lyg/pages/entries/shared/LYGSchoolEntryDemographicDataModel';
import { LYGEntryGeneralInfoModel, ParticipantFormData } from 'src/lyg/pages/entries/shared/LYGEntryGeneralInfoModel';
import { DROPDOWN_BLANK_VALUE, DropdownFieldModel } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';
import { TextFieldModel } from 'src/shared/ui/inputs/text/TextFieldModel';
import { numberHasOnlyPositiveDigits } from 'src/shared/utils/numbers';
import { LYGSchoolGameEntry } from 'src/lyg/entities/entries/schoolGameEntry';

interface FormData {
  boroughId: string;
  schoolId: string;
  numberOfTeams: string;
  teamManagerId: string;
  teamManagerName: string;
  teamManagerLastName: string;
  teamManagerEmail: string;
  teamManagerContactNumber: string;
  participantData: ParticipantFormData;
  demographicData: DemographicFormData;
}

interface LYGEntryImportModelProps {
  i18n: I18nModel;
  eventType: string;
}

export class LYGEntrySchoolModel {
  generalInfo: LYGEntryGeneralInfoModel;
  demographicData: LYGSchoolEntryDemographicDataModel;
  boroughPicker: DropdownFieldModel;
  schoolPicker: DropdownFieldModel;
  teamManagerPicker: DropdownFieldModel;
  numberOfTeams: TextFieldModel;
  teamManagerName: TextFieldModel;
  teamManagerLastName: TextFieldModel;
  teamManagerEmail: TextFieldModel;
  teamManagerContactNumber: TextFieldModel;

  constructor(props: LYGEntryImportModelProps) {
    this.generalInfo = new LYGEntryGeneralInfoModel({ i18n: props.i18n, eventType: props.eventType });
    this.demographicData = new LYGSchoolEntryDemographicDataModel({ i18n: props.i18n });
    this.boroughPicker = createBoroughPickerDropdown({ i18n: props.i18n });
    this.schoolPicker = createSchoolPickerDropdown({ i18n: props.i18n });
    this.teamManagerPicker = new DropdownFieldModel({
      i18n: props.i18n,
      id: 'team-manager',
      initialValue: DROPDOWN_BLANK_VALUE,
      title: 'Team manager',
      required: true,
    });
    this.numberOfTeams = new TextFieldModel({
      title: 'No. of Teams',
      required: true,
      i18n: props.i18n,
      id: 'numberOfTeams',
      initialValue: '',
      validators: [
        {
          message: props.i18n.t('shared.errors.positiveDigit'),
          validate: numberHasOnlyPositiveDigits,
        },
      ],
    });
    this.teamManagerName = new TextFieldModel({
      id: 'teamManagerName',
      i18n: props.i18n,
      initialValue: '',
      title: 'Name',
      required: true,
    });
    this.teamManagerLastName = new TextFieldModel({
      id: 'teamManagerLastName',
      i18n: props.i18n,
      initialValue: '',
      title: 'Last Name',
      required: true,
    });
    this.teamManagerEmail = new TextFieldModel({
      id: 'teamManagerEmail',
      i18n: props.i18n,
      initialValue: '',
      title: 'Email',
      required: true,
    });
    this.teamManagerContactNumber = new TextFieldModel({
      id: 'teamManagerContactNumber',
      i18n: props.i18n,
      initialValue: '',
      title: 'Contact Number',
      required: true,
    });
    makeObservable(this, {
      hasError: computed,
      hasChanged: computed,
    });
  }

  get hasError(): boolean {
    return this.boroughPicker.hasError || this.schoolPicker.hasError || this.numberOfTeams.hasError;
  }

  get hasChanged(): boolean {
    return this.boroughPicker.hasChanged || this.schoolPicker.hasChanged || this.numberOfTeams.hasChanged;
  }

  get data(): FormData {
    return {
      numberOfTeams: this.numberOfTeams.value,
      boroughId: this.boroughPicker.value.id,
      schoolId: this.schoolPicker.value.id,
      teamManagerId: this.teamManagerPicker.value.id,
      teamManagerName: this.teamManagerName.value,
      teamManagerLastName: this.teamManagerLastName.value,
      teamManagerEmail: this.teamManagerEmail.value,
      teamManagerContactNumber: this.teamManagerContactNumber.value,
      participantData: this.generalInfo.data,
      demographicData: this.demographicData.data,
    };
  }

  setDefaultValues(
    boroughId: number | null,
    school: { schoolId: number; schoolName: string } | null,
    entry: LYGSchoolGameEntry | null,
  ): void {
    if (boroughId) {
      this.boroughPicker.setDefaultValueById(boroughId.toString());
      this.boroughPicker.disabled = true;
    }
    if (school) {
      this.schoolPicker.setDefaultValue({ id: school.schoolId.toString(), label: school.schoolName });
      this.schoolPicker.disabled = true;
    }
    if (entry) {
      this.numberOfTeams.setDefaultValue(entry.numberOfTeams.toString());
      this.teamManagerPicker.setDefaultValue({
        id: entry.teamManagerId.toString(),
        label: `${entry.teamManagerName} ${entry.teamManagerLastName}`,
      });
      this.boroughPicker.setDefaultValueById(entry.boroughName.toString());
      if (entry.demographicData) {
        this.demographicData.setDefaultValues(entry.demographicData);
      }
      if (entry.participantData) {
        this.generalInfo.setDefaultValues(entry.participantData, null);
      }
      this.schoolPicker.setDefaultValue({ id: entry.schoolId.toString(), label: entry.schoolName });
      this.schoolPicker.disabled = school !== null;
      this.boroughPicker.setDefaultValue({ id: entry.boroughId.toString(), label: entry.boroughName });
      this.boroughPicker.disabled = boroughId !== null;
    }
  }
}
