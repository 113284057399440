import React from 'react';
import { LogOut } from 'react-feather';
import { AuthContextProvider, useAuthContext } from 'src/context/authContext';
import { useNavigate } from 'react-router-dom';
import { useLYGAppRoutesContext } from 'src/context/routes';
import { FullScreenSpinner } from 'src/shared/ui/layout/FullScreenSpinner';
import { cx } from 'src/shared/utils/common';
import { clearLocalStorage, deleteAllCookies } from 'src/context/authContext/utils';
import { useQueryClient } from 'react-query';
import { useLYGPusher } from 'src/lyg/features/appLayout/pusher/useLYGPusher';
import css from './LYGLogoutButton.module.scss';

const LogoutButtonView: React.FC = () => {
  const { logout } = useAuthContext();
  const { onClear } = useLYGPusher();
  const navigate = useNavigate();
  const routes = useLYGAppRoutesContext();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = React.useState(false);

  const onLogout = async () => {
    setIsLoading(true);
    document.body.classList.add('overflow-hidden');

    try {
      await onClear();
    } catch (e) {
      console.error(e);
    }
    queryClient.invalidateQueries();
    queryClient.clear();
    clearLocalStorage();
    deleteAllCookies();
    const cacheKeys = await window.caches?.keys();
    if (cacheKeys) cacheKeys.forEach(key => window.caches.delete(key));
    if ('Intercom' in window) window.Intercom('shutdown');
    logout();

    document.body.classList.remove('overflow-hidden');
    navigate(routes.auth.signIn);
  };

  return (
    <>
      <button
        className={cx('border-0 p-0 bg-transparent d-flex align-items-center ms-4 my-3', css.logoutBtn)}
        type='button'
        onClick={onLogout}
      >
        <LogOut className={cx('me-3', css.icon)} /> <div>Logout</div>
      </button>
      {isLoading && <FullScreenSpinner />}
    </>
  );
};

export const LYGLogoutButton: React.FC = () => {
  return (
    <AuthContextProvider>
      <LogoutButtonView />
    </AuthContextProvider>
  );
};
