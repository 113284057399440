import { observer } from 'mobx-react-lite';
import React from 'react';
import { CheckCircle, ChevronDown, ChevronUp, File, RotateCw, X } from 'react-feather';
import {
  VideoToUpload,
  useFileUploadModal,
} from 'src/features/appLayout/ui/FileUploadProgressModal/FileUploadProgressModel';
import { SpinnerAnimation } from 'src/shared/ui/assets/SpinnerAnimation';
import { TransparentButton } from 'src/shared/ui/buttons';
import { ProgressBar } from 'src/shared/ui/widgets/ProgressBar';
import { cx } from 'src/shared/utils/common';
import { formatBytes, numbersAverage } from 'src/shared/utils/numbers';
import css from './index.module.scss';

interface FileItemProps {
  file: VideoToUpload;
  onRetry: () => void;
  onCancel: () => void;
}

const FileItem: React.FC<FileItemProps> = observer(
  ({
    file: {
      formData: {
        videoFile: { name, size },
      },
      progress,
      hasError,
      isLoading,
      uploadId,
    },
    onRetry,
    onCancel,
  }) => (
    <div className='d-flex gap-2 w-100 p-2'>
      <File className={cx('flex-nowrap flex-shrink-0', css.icon)} />
      <div className='flex-grow-1 flex-shrink-0 flex-nowrap'>
        <p className='mb-1 ts-fw-500 ts-fs-16'>{name}</p>
        <ProgressBar className={css.progress} error={hasError} isLoading={progress !== 100} value={progress} />
        <div className='ts-text-lighter mt-2 ts-fs-12 d-flex gap-3'>
          <p className='m-0'>{progress} %</p>
          <p className='m-0'>{formatBytes(size)}</p>
        </div>
      </div>
      {!hasError ? (
        progress === 100 ? (
          <CheckCircle className={cx(css.green, 'align-self-center')} />
        ) : uploadId ? (
          <TransparentButton
            loading={isLoading}
            className='align-self-center flex-nowrap flex-shrink-0'
            onClick={onCancel}
          >
            <X />
          </TransparentButton>
        ) : (
          <SpinnerAnimation className={cx(css.blue, css.icon, 'align-self-center ms-2')} />
        )
      ) : (
        <TransparentButton
          loading={isLoading}
          className='align-self-center flex-nowrap flex-shrink-0'
          onClick={onRetry}
        >
          <RotateCw className={css.blue} />
        </TransparentButton>
      )}
    </div>
  ),
);

export const FileUploadProgressModal: React.FC = observer(() => {
  const { videos, retryUpload, cancelUpload } = useFileUploadModal();
  const [isOpen, setIsOpen] = React.useState(true);

  React.useEffect(() => {
    const intercomTrigger = document.querySelector<HTMLDivElement>('.intercom-lightweight-app div');
    if (!intercomTrigger) return;
    if (videos.length === 0) {
      intercomTrigger.style.display = 'block';
      return;
    }

    intercomTrigger.style.display = isOpen ? 'none' : 'block';
  }, [videos.length, isOpen]);

  React.useEffect(() => {
    if (videos.length === 0) {
      window.onbeforeunload = () => null;
      return;
    }
    window.onbeforeunload = () => 'There are files being uploaded. Are you sure you want to leave?';
  }, [videos.length]);

  return videos.length > 0 ? (
    <div className={cx(css.wrapper, !isOpen && css.closed, 'overflow-auto')}>
      <TransparentButton
        className={cx('d-flex align-items-center justify-content-between p-2 ts-fw-500 ts-fs-16 w-100', css.title)}
        onClick={() => setIsOpen(p => !p)}
      >
        <p className='m-0 p-0'>Uploading {Math.round(numbersAverage(videos.map(({ progress }) => progress)))}%</p>
        {isOpen ? <ChevronDown /> : <ChevronUp />}
      </TransparentButton>

      {videos.map(file => (
        <React.Fragment key={file.id}>
          <FileItem file={file} onRetry={() => retryUpload(file.id)} onCancel={() => cancelUpload(file.id)} />
          <div className='ts-h-divider m-auto' />
        </React.Fragment>
      ))}
    </div>
  ) : null;
});
