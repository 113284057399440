import React, { useState } from 'react';
import { DataTable } from 'src/shared/ui/data/DataTable';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { DataTableModel } from 'src/shared/ui/data/DataTableModel';
import { useDateTimeFormat } from 'src/shared/hooks/useDateTimeFormat';
import { LYGCompetitionLeaderboardTeam } from 'src/lyg/entities/competitions/LYGCompetitionLeaderboard';

interface LYGPoolResultsTableProps {
  teams: LYGCompetitionLeaderboardTeam[];
  bloatTextPattern: RegExp;
}

export default function PoolGroup({ teams, bloatTextPattern }: LYGPoolResultsTableProps) {
  const removeBloatText = (str: string) => {
    return str.replace(bloatTextPattern, '');
  };

  const [model] = useState(
    new DataTableModel<LYGCompetitionLeaderboardTeam>({
      columns: [
        {
          className: 'flex-shrink-1 flex-grow-1',
          width: 50,
          key: 'teams',
          title: 'Teams',
          dataCell: ({ dataItem }) => <span>{removeBloatText(dataItem.teamName)}</span>,
        },
      ],
      onSort: () => null,
    }),
  );

  return <DataTable headerClassName='ts-fw-500' data={teams} model={model} />;
}
