import React from 'react';

export const WhistleIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_5182_19373)'>
      <path
        d='M17.9236 8.06094C18.7614 7.4686 19.8446 7.23098 20.9241 7.52022C22.846 8.03522 23.9876 10.0124 23.4726 11.9344C22.9994 13.7005 21.2915 14.8076 19.5263 14.576C18.4532 18.7513 13.9689 21.1306 9.841 19.5235L17.9236 8.06094ZM17.9236 8.06094C16.9546 6.9539 15.6527 6.10776 14.1525 5.70579L3.31603 2.80217C2.78256 2.65922 2.23422 2.97581 2.09128 3.50927L1.01017 7.54403C0.867231 8.07749 1.18381 8.62583 1.71728 8.76877L5.8124 9.86606C5.82459 9.86932 5.83109 9.87361 5.83497 9.87683C5.8396 9.88068 5.84318 9.88535 5.8455 9.89003C5.84778 9.89464 5.84798 9.89748 5.848 9.89789L5.84792 9.89871C5.84784 9.89909 5.84746 9.90103 5.84584 9.90477L5.8405 9.91702L5.84058 9.91706C5.536 10.6429 5.31739 11.4487 5.26931 12.3355C5.08143 15.4227 6.93896 18.394 9.84089 19.5235L17.9236 8.06094ZM20.1152 10.5387C19.8635 10.4713 19.5958 10.6258 19.5284 10.8776C19.4609 11.1293 19.6155 11.397 19.8672 11.4644C20.1189 11.5319 20.3866 11.3773 20.4541 11.1256C20.5215 10.8739 20.367 10.6062 20.1152 10.5387Z'
        stroke='#FC5352'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M11.6988 15.7907C10.0317 15.344 9.04242 13.6305 9.48912 11.9634C9.93581 10.2963 11.6494 9.307 13.3164 9.75369C14.9835 10.2004 15.9728 11.9139 15.5262 13.581C15.0795 15.2481 13.3659 16.2374 11.6988 15.7907Z'
        fill='#FC5352'
      />
    </g>
    <defs>
      <clipPath id='clip0_5182_19373'>
        <rect width='25' height='25' fill='white' transform='matrix(-1 0 0 1 25 0)' />
      </clipPath>
    </defs>
  </svg>
);
