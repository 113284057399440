import { InputFieldModel, InputFieldModelProps } from 'src/shared/ui/inputs/InputFieldModel';

interface BooleanFieldModelProps extends InputFieldModelProps<boolean> {
  helperText?: string;
  label?: string | JSX.Element;
  /**
   * Title needs to be specified to be used as aria-label.
   */
  title: string;
}

export class BooleanFieldModel extends InputFieldModel<boolean, BooleanFieldModelProps> {
  get hasChanged(): boolean {
    return this.value !== this.defaultValue;
  }

  get label(): string | JSX.Element {
    return this.props.label || '';
  }

  get helperText(): string {
    return this.props.helperText || '';
  }

  protected get requiredIsValid(): boolean {
    return this.value;
  }
}
