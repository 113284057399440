export interface BmxEntry {
  licenseNumber: string;
  grade: string;
  reserve: boolean;
}
export class BmxEntry {
  public constructor(params: OnlyData<BmxEntry>) {
    Object.assign(this, params);
  }
}
