import { load } from 'src/shared/utils/components';

export const SignInPage = () => load(import('src/lyg/pages/auth/signIn').then(m => ({ default: m.SignInPage })));
export const UpdatePasswordPage = () =>
  load(import('src/lyg/pages/auth/updatePassword').then(m => ({ default: m.UpdatePasswordPage })));
export const ResetPasswordPage = () =>
  load(import('src/lyg/pages/auth/resetPassword').then(m => ({ default: m.ResetPasswordPage })));

export const DashboardPage = () => load(import('src/lyg/pages/dashboard').then(m => ({ default: m.DashboardPage })));

export const EventsHomePage = () =>
  load(import('src/lyg/pages/events/home').then(m => ({ default: m.EventsHomePage })));
export const EventPage = () => load(import('src/lyg/pages/events/event').then(m => ({ default: m.EventPage })));
export const EventCreatePage = () =>
  load(import('src/lyg/pages/events/create').then(m => ({ default: m.EventCreatePage })));
export const EventEditPage = () => load(import('src/lyg/pages/events/edit').then(m => ({ default: m.EventEditPage })));

export const CompetitionPoolsPage = () =>
  load(import('src/lyg/pages/competitions/pools').then(m => ({ default: m.CompetitionPoolsPage })));
export const CompetitionSchedulePage = () =>
  load(import('src/lyg/pages/competitions/schedule').then(m => ({ default: m.CompetitionSchedulePage })));

export const EntriesHomePage = () =>
  load(import('src/lyg/pages/entries/home').then(m => ({ default: m.EntriesHomePage })));

export const TeamsHomePage = () => load(import('src/lyg/pages/teams/home').then(m => ({ default: m.TeamsHomePage })));

export const MembersHomePage = () =>
  load(import('src/lyg/pages/members/home').then(m => ({ default: m.MembersHomePage })));
export const MemberCreatePage = () =>
  load(import('src/lyg/pages/members/create').then(m => ({ default: m.MemberCreatePage })));
export const MemberEditPage = () =>
  load(import('src/lyg/pages/members/edit').then(m => ({ default: m.MemberEditPage })));
export const MemberPage = () => load(import('src/lyg/pages/members/member').then(m => ({ default: m.MemberPage })));

export const SportsHomePage = () =>
  load(import('src/lyg/pages/sports/home').then(m => ({ default: m.SportsHomePage })));

export const ReportsHomePage = () =>
  load(import('src/lyg/pages/reports/home').then(m => ({ default: m.ReportsHomePage })));

export const VideosHomePage = () =>
  load(import('src/lyg/pages/videos/home').then(m => ({ default: m.VideosHomePage })));

export const PhotosHomePage = () =>
  load(import('src/lyg/pages/photos/home').then(m => ({ default: m.PhotosHomePage })));

export const SettingsHomePage = () =>
  load(import('src/lyg/pages/settings/home').then(m => ({ default: m.SettingsHomePage })));

export const FeedbackPage = () => load(import('src/lyg/pages/feedback').then(m => ({ default: m.FeedbackPage })));
export const FAQsPage = () => load(import('src/lyg/pages/support/faqs').then(m => ({ default: m.FAQsPage })));

export const VenueCreatePage = () =>
  load(import('src/lyg/pages/venues/create').then(m => ({ default: m.VenueCreatePage })));
export const VenueEditPage = () => load(import('src/lyg/pages/venues/edit').then(m => ({ default: m.VenueEditPage })));
export const VenuesHomePage = () =>
  load(import('src/lyg/pages/venues/home').then(m => ({ default: m.VenuesHomePage })));
export const SchoolsHomePage = () =>
  load(import('src/lyg/pages/schools/home').then(m => ({ default: m.SchoolsHomePage })));
export const SchoolEditPage = () =>
  load(import('src/lyg/pages/schools/edit').then(m => ({ default: m.SchoolEditPage })));
export const SchoolCreatePage = () =>
  load(import('src/lyg/pages/schools/create').then(m => ({ default: m.SchoolCreatePage })));

export const SportEditPage = () => load(import('src/lyg/pages/sports/edit').then(m => ({ default: m.SportEditPage })));

export const SportCreatePage = () =>
  load(import('src/lyg/pages/sports/create').then(m => ({ default: m.SportCreatePage })));

export const NotFoundPage = () => load(import('src/lyg/pages/404').then(m => ({ default: m.NotFoundPage })));
