export const LYG_COMPETITIONS_KEYS = {
  base: 'competitions',
  filtered: (filters?: AnyObject): unknown[] => [LYG_COMPETITIONS_KEYS.base, filters],
  competition: (id: number): unknown[] => [LYG_COMPETITIONS_KEYS.base, id],
  upcoming: (filters: AnyObject): unknown[] => [LYG_COMPETITIONS_KEYS.base, 'upcoming', filters],
  teams: (competitionId: number): unknown[] => [LYG_COMPETITIONS_KEYS.base, 'teams', competitionId],
  pools: (competitionId: number): unknown[] => [LYG_COMPETITIONS_KEYS.base, 'pools', competitionId],
  schedule: (competitionId: number): unknown[] => [LYG_COMPETITIONS_KEYS.base, 'schedule', competitionId],
  leaderboard: (competitionId: number): unknown[] => [LYG_COMPETITIONS_KEYS.base, 'leaderboard', competitionId],
  bracket: (competitionId: number): unknown[] => [LYG_COMPETITIONS_KEYS.base, 'bracket', competitionId],
};
