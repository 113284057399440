import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLYGCompetitionQuery } from 'src/lyg/pages/competitions/pools/query';
import { useLYGCompetitionScheduleQuery, useLYGLeaderboardQuery } from 'src/lyg/pages/competitions/schedule/query';
import { HorizontalSelect } from 'src/shared/ui/select/HorizontalSelect';
import { QueryLoader } from 'src/shared/ui/utils/QueryLoader';
import { CardTitle } from 'src/shared/ui/widgets/Card';
import { CardSubtitle } from 'src/shared/ui/widgets/Card';
import { PoolLeaderboard } from 'src/lyg/pages/competitions/details/ui/PoolLeaderBoard';
import PoolSchedule from 'src/lyg/pages/competitions/details/ui/PoolSchedule';
import QRCode from 'react-qr-code';
import { cx } from 'src/shared/utils/common';
import css from './CompetitionDetailsPage.module.css';
import { useDateTimeFormat } from 'src/shared/hooks/useDateTimeFormat';
// import { useIsMobile } from 'src/shared/hooks/useIsMobile';
import { BaseButton } from 'src/shared/ui/buttons/BaseButton';
import { WhatsAppIcon } from 'src/shared/ui/assets/WhatsAppIcon';
import { PrimaryButton } from 'src/shared/ui/buttons';
import { Copy } from 'react-feather';
import { toast } from 'react-toastify';
import PoolGroup from 'src/lyg/pages/competitions/details/ui/PoolGroup';
import { Helmet } from 'react-helmet-async';
import { LYGCompetitionLeaderboardTeam } from 'src/lyg/entities/competitions/LYGCompetitionLeaderboard';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { BracketComp } from 'src/pages/competitions/bracket/components/Bracket';
import { CompetitionBracket } from 'src/entities/competition/domain';
import { useLYGCompetitionBracketQuery } from 'src/lyg/pages/competitions/bracket/query';

const TABS = {
  groups: 'groups',
  schedule: 'schedule',
  leaderboard: 'leaderboard',
  bracket: 'bracket',
};

export const CompetitionDetailsPage: React.FC = () => {
  const { competitionId } = useParams();
  const competitionQuery = useLYGCompetitionQuery(Number(competitionId), false);
  const scheduleQuery = useLYGCompetitionScheduleQuery(Number(competitionId), false);
  const leaderboardQuery = useLYGLeaderboardQuery(Number(competitionId), false);
  const bracketQuery = useLYGCompetitionBracketQuery(Number(competitionId), false);
  // const poolsQuery = useLYGCompetitionPoolsQuery(Number(competitionId), false);
  const [tab, setTab] = React.useState(TABS.groups);
  const { formatDate } = useDateTimeFormat();
  const [searchTerm, setSearchTerm] = useState('');

  function handleSendWhapp() {
    const shareUrl = `https://wa.me/?text=${window.location.href}`;
    window.open(shareUrl, '_blank');
    return;
  }

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success('Copied!', { autoClose: 1500 });
  };

  const title = `LYG${competitionQuery.data && formatDate(competitionQuery.data?.eventStartDate, 'yy')} ${
    competitionQuery.data?.sportName
  } ${competitionQuery.data?.name}`;
  const bloatText = `${competitionQuery.data?.name} ${competitionQuery.data?.sportName}`;
  const bloatTextPattern = new RegExp(bloatText, 'ig');

  const filterTeam = (data: LYGCompetitionLeaderboardTeam[], searchStr: string) => {
    return data.filter(item => item.teamName.toLowerCase().includes(searchStr.toLowerCase()));
  };
  const filterGame = (data: LYGCompetitionGame[], searchStr: string) => {
    return data.filter(
      item =>
        item.teamAName.toLowerCase().includes(searchStr.toLowerCase()) ||
        item.teamBName.toLowerCase().includes(searchStr.toLowerCase()),
    );
  };

  return (
    <div>
      {competitionQuery.data && (
        <Helmet>
          <title>TeamSportz platform</title>
          <meta name='title' content={title} />
          <meta
            name='description'
            content={`View the ${title} schedule and results leaderboard in TeamSportz. Download our app, start your sport journey for free`}
          />

          <meta property='og:type' content='website' />
          <meta property='og:url' content={`https://lyg.teamsportz.pro/competitions/${competitionId}`} />
          <meta property='og:title' content={title} />
          <meta
            property='og:description'
            content={`View the ${title} schedule and results leaderboard in TeamSportz. Download our app, start your sport journey for free`}
          />
          <meta property='og:image' content='https://files.teamsportz.app/assets/TEAMSPORTZandLYG.png' />

          <meta property='twitter:card' content='summary_large_image' />
          <meta property='twitter:url' content={`https://lyg.teamsportz.pro/competitions/${competitionId}`} />
          <meta property='twitter:title' content={title} />
          <meta
            property='twitter:description'
            content={`View the ${title} schedule and results leaderboard in TeamSportz. Download our app, start your sport journey for free`}
          />
          <meta property='twitter:image' content='https://files.teamsportz.app/assets/TEAMSPORTZandLYG.png' />
        </Helmet>
      )}

      <QueryLoader query={scheduleQuery}>
        {({ data: schedule }) => (
          <div>
            <div className='p-2 container mt-3' style={{ maxWidth: '880px', width: '95%' }}>
              <div className={cx(css.shareButtons, 'mb-3')}>
                <BaseButton
                  className={cx('justify-content-center flex-grow-1 d-flex gap-2 align-items-center', css.whatsapp)}
                  onClick={handleSendWhapp}
                >
                  <WhatsAppIcon />
                  Share
                </BaseButton>

                <PrimaryButton
                  className='justify-content-center flex-grow-1 d-flex gap-2 align-items-center'
                  onClick={handleCopyUrl}
                >
                  <Copy />
                  Copy link
                </PrimaryButton>
              </div>
              <div className='d-flex justify-content-between mb-3 align-items-end'>
                <div className={cx(css.logoSection)}>
                  <img
                    width={100}
                    src={'/assets/lyg/lyg.png'}
                    alt='London Youth Games Logo'
                    className={cx('flex-shrink-0 user-select-none')}
                  />
                  <h2>{title}</h2>
                  {/* <p className='ts-fs-14 mb-2'>
                    <span className='ts-fw-500'>Registration:</span> 09:00 - 09:30
                  </p>
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>TM Briefing:</span> 09:45
                  </p> */}
                </div>

                <div className={cx('text-center', css.qrSection)}>
                  <CardSubtitle className='mb-2'>
                    {scheduleQuery.data && formatDate(scheduleQuery.data[0].games[0].date, 'dd LLL yyyy')}
                  </CardSubtitle>
                  <a href={`${window.location.href}/qr`} target='_blank' rel='noreferrer'>
                    <QRCode size={140} value={window.location.href} />
                  </a>
                  <p className='ts-fs-14 mb-0'>LIVE SCHEDULE</p>
                </div>
              </div>

              <div className='d-flex gap-2 gap-md-5 align-items-center mb-4 justify-content-between flex-wrap'>
                <HorizontalSelect
                  // className='mb-4'
                  options={[
                    { id: TABS.groups, label: 'Groups' },
                    { id: TABS.schedule, label: 'Schedule' },
                    { id: TABS.leaderboard, label: 'Leaderboard' },
                    ...(bracketQuery.data?.rounds?.length ? [{ id: TABS.bracket, label: 'Brackets' }] : []),
                  ]}
                  onSelect={({ id }) => setTab(id)}
                  selectedId={tab}
                />
                <div className='d-flex gap-2'>
                  <input
                    className={cx('ts-text-overflow mt-3 mt-md-0', css.field)}
                    placeholder='Search team name'
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  {/* <a href={`${window.location.href}/qr`} target='_blank' rel='noreferrer' className='d-none d-md-block'>
                    <PrimaryButton>
                      <Printer className='me-2' />
                      <span>Print QR Code</span>
                    </PrimaryButton>
                  </a> */}
                </div>
              </div>

              {tab === TABS.groups ? (
                <div className='row'>
                  {leaderboardQuery.data?.map((leadearboard, index) => (
                    <div className={cx('col-12 mb-4', css.groups)} key={index}>
                      {/* <div className={cx('mb-4 pb-2', css.groupItem)}> */}
                      <div>
                        <CardTitle className='mb-3'>{leadearboard.poolName}</CardTitle>
                        {/* {leadearboard.teams.map(team => (
                          <p key={team.id} className='py-3 mb-0 ms-2'>
                            {team.teamName.replace(bloatTextPattern, '')}
                          </p>
                        ))} */}

                        <PoolGroup
                          teams={filterTeam(leadearboard.teams, searchTerm)}
                          bloatTextPattern={bloatTextPattern}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              {tab === TABS.schedule ? (
                <div className='d-flex flex-column gap-2'>
                  {schedule?.map(pool => {
                    return (
                      <div key={pool.id} className='mb-4'>
                        <CardTitle>{pool.name}</CardTitle>
                        <div className='tableWrapper'>
                          <PoolSchedule
                            games={filterGame(pool.games, searchTerm)}
                            bloatTextPattern={bloatTextPattern}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {tab === TABS.leaderboard ? (
                <div className='d-flex flex-column gap-2'>
                  {leaderboardQuery.data?.map(pool => (
                    <div key={pool.poolId} className='mb-4'>
                      <CardTitle>{pool.poolName}</CardTitle>
                      <div>
                        <PoolLeaderboard
                          teams={filterTeam(pool.teams, searchTerm)}
                          bloatTextPattern={bloatTextPattern}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              {tab === TABS.bracket ? (
                <div className='d-flex flex-column gap-2 '>
                  <BracketComp competition={undefined} bracket={bracketQuery.data as CompetitionBracket} />
                </div>
              ) : null}

              <div className={cx('mt-5', css.footer)}>
                <h4 className='ts-text-light'>Powered by</h4>
                <img
                  className={css.tsLogo}
                  src='https://files.teamsportz.app/assets/logo/logo%20full%20light.png'
                  alt='Logo'
                />
                <div className='d-flex gap-3 justify-content-end'>
                  <a className='ts-text-lighter' href='mailto:hello@teamsportz.pro'>
                    hello@teamsportz.pro
                  </a>
                  <a className='ts-text-lighter' href='https://teamsportz.pro'>
                    www.teamsportz.pro
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </QueryLoader>
    </div>
  );
};
