import React, { useEffect } from 'react';

interface WindowSizes {
  height: number;
  width: number;
}

export function useOnWindowResize(handler: (sizes: WindowSizes) => void): void {
  const handleResize = React.useCallback(() => {
    handler({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }, [handler]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleResize(), []);
}
