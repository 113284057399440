import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { LYGCompetition, LYG_COMPETITION_STATUS } from 'src/lyg/entities/competitions';
import { LYGCompetitionPool } from 'src/lyg/entities/competitions/LYGCompetitionPool';
import { LYGTeam } from 'src/lyg/entities/competitions/LYGTeam';
import { LYG_EVENT_TYPE } from 'src/lyg/entities/events';
import { LYG_COMPETITIONS_KEYS } from 'src/lyg/pages/competitions/queryKeys';
import { ApiError } from 'src/lyg/utils/apiError';
import { apiClient } from 'src/shared/utils/apiClient';

interface CompetitionTeamSquadResponse {
  created_at: DateTimeString;
  lyg_disability: string | null;
  lyg_dob: DateTimeString;
  lyg_email: string | null;
  lyg_emergency_contact_name: string;
  lyg_emergency_contact_number: string;
  lyg_ethnicity: string;
  lyg_gender: string;
  lyg_home_postcode: string;
  lyg_imagery_consent: 1 | 0;
  lyg_name: string;
  lyg_parent_email: string;
  lyg_parent_name: string | null;
  lyg_school: string;
  lyg_squad_id: number;
  lyg_team_id: number;
}

interface GetCompetitionTeamsResponse {
  result: {
    created_at: DateTimeString;
    lyg_competition_id: string;
    lyg_team_id: number;
    lyg_team_name: string;
    participants?: CompetitionTeamSquadResponse[];
    participants_count?: number;
    updated_at: DateTimeString;
  }[];
}

async function getCompetitionTeams(competitionId: number, token: AccessToken): Promise<GetCompetitionTeamsResponse> {
  return apiClient(`/v1/lyg/competitions/${competitionId}/teams`, { token });
}

export function useLYGCompetitionTeamsQuery(competitionId: number) {
  const { token } = useAuthContext();

  return useQuery<LYGTeam[], ApiError>(
    LYG_COMPETITIONS_KEYS.teams(competitionId),
    async () => {
      const { result } = await getCompetitionTeams(competitionId, token);

      return result.map(
        team =>
          new LYGTeam({
            id: team.lyg_team_id,
            name: team.lyg_team_name,
            playersCount: team.participants_count ?? 0,
          }),
      );
    },
    { staleTime: 1000 * 60 * 5 },
  );
}

interface GetCompetitionPoolsResponse {
  result: {
    lyg_competition_id: string;
    lyg_competition_name: string;
    lyg_pool_id: number;
    lyg_pool_name: string;
    teams: { lyg_team_id: number; lyg_team_name: string; lyg_borough: string | null; lyg_school: string | null }[];
  }[];
}

async function getCompetitionPools(competitionId: number, token?: AccessToken): Promise<GetCompetitionPoolsResponse> {
  return apiClient(`/v1/lyg/competitions/${competitionId}/pools`, { token });
}

export function useLYGCompetitionPoolsQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }
  return useQuery<LYGCompetitionPool[], ApiError>(
    LYG_COMPETITIONS_KEYS.pools(competitionId),
    async () => {
      const { result } = await getCompetitionPools(competitionId, token);

      return result.map(
        pool =>
          new LYGCompetitionPool({
            id: pool.lyg_pool_id,
            name: pool.lyg_pool_name,
            teams: pool.teams.map(team => ({
              id: team.lyg_team_id,
              name: team.lyg_team_name,
              borough: team.lyg_borough,
              school: team.lyg_school,
            })),
          }),
      );
    },
    { staleTime: 1000 * 60 * 5 },
  );
}

interface GetCompetitionResponse {
  result: {
    lyg_borough_count?: number;
    lyg_competition_category_age_group: string;
    lyg_competition_category_class: string | null;
    lyg_competition_category_type: string;
    lyg_competition_end_date: DateTimeString;
    lyg_competition_id: number;
    lyg_competition_image: string | null;
    lyg_competition_max_dob_limit: DateTimeString;
    lyg_competition_min_dob_limit: DateTimeString;
    lyg_competition_name: string;
    lyg_competition_status?: string;
    dates: [
      {
        lyg_competition_end_date: DateTimeString;
        lyg_competition_start_date: DateTimeString;
      },
    ];
    event: {
      lyg_event_end_date: DateTimeString;
      lyg_event_start_date: DateTimeString;
      lyg_event_status: string;
    };
    lyg_competition_break_time: number;
    lyg_competition_spread_games_randomly: boolean;
    lyg_competition_number_of_courts: number;
    lyg_competition_match_duration: number;
    lyg_competition_matchup_count: number;
    lyg_competition_points_for_win: number;
    lyg_competition_points_for_loss: number;
    lyg_competition_allow_draws: boolean;
    lyg_competition_points_for_draw: number;
    lyg_competition_allow_walkovers: boolean;
    lyg_competition_points_for_awarded_walkover: number;
    lyg_competition_points_for_conceding_walkover: number;
    lyg_competition_points_for_walkover_win: number;
    lyg_competition_number_quarters: number;
    lyg_has_competition_started: 1 | 0;
    lyg_competition_show_time_in_schedule: 1 | 0;
    lyg_competition_type: string;
    lyg_event_id: number;
    lyg_sport_id: number;
    lyg_venue_id: number;
    lyg_venue_name: string;
    sport_acronym: string | null;
    sport_icon: string | null;
    sport_id: number;
    sport_image: string | null;
    sport_name: string;
  };
}

async function getCompetition(competitionId: number, token?: AccessToken): Promise<GetCompetitionResponse> {
  return apiClient(`/v1/lyg/competitions/${competitionId}`, { token });
}

export function useLYGCompetitionQuery(competitionId: number, auth = true) {
  let token: AccessToken | undefined = undefined;
  if (auth) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    token = useAuthContext().token;
  }

  return useQuery<LYGCompetition, ApiError>(
    LYG_COMPETITIONS_KEYS.competition(competitionId),
    async () => {
      const { result: comp } = await getCompetition(competitionId, token);

      return new LYGCompetition({
        boroughCount: comp.lyg_borough_count ?? null,
        categoryAgeGroup: comp.lyg_competition_category_age_group,
        categoryClass: comp.lyg_competition_category_class,
        categoryType: comp.lyg_competition_category_type,
        eventId: comp.lyg_event_id,
        id: comp.lyg_competition_id,
        maxDobLimit: comp.lyg_competition_min_dob_limit ? new Date(comp.lyg_competition_min_dob_limit) : null,
        minDobLimit: comp.lyg_competition_max_dob_limit ? new Date(comp.lyg_competition_max_dob_limit) : null,
        name: comp.lyg_competition_name,
        sportIcon: comp.sport_icon,
        sportId: comp.sport_id,
        sportName: comp.sport_name,
        type: comp.lyg_competition_type as LYG_EVENT_TYPE,
        dates: comp.dates.map(date => ({
          end: new Date(date.lyg_competition_end_date),
          start: new Date(date.lyg_competition_start_date),
        })),
        status: (comp.lyg_competition_status as LYG_COMPETITION_STATUS) ?? null,
        matchDuration: Number(comp.lyg_competition_match_duration),
        matchupFrequency: Number(comp.lyg_competition_matchup_count),
        pointsForWin: Number(comp.lyg_competition_points_for_win),
        pointsForLoss: Number(comp.lyg_competition_points_for_loss),
        isDrawAllowed: Boolean(comp.lyg_competition_allow_draws),
        pointsForDraw: Number(comp.lyg_competition_points_for_draw),
        isWalkoverAllowed: Boolean(comp.lyg_competition_allow_walkovers),
        pointsForAwardedWalkover: Number(comp.lyg_competition_points_for_awarded_walkover),
        pointsForConcedingWalkover: Number(comp.lyg_competition_points_for_conceding_walkover),
        pointsForWalkoverWin: Number(comp.lyg_competition_points_for_walkover_win),
        breakTime: Number(comp.lyg_competition_break_time),
        numberOfCourts: Number(comp.lyg_competition_number_of_courts || 1),
        spreadGamesEvenlyBetweenDates: Boolean(comp.lyg_competition_spread_games_randomly),
        showTimeInSchedule: Boolean(comp.lyg_competition_show_time_in_schedule),
        hasStarted: Boolean(comp.lyg_has_competition_started),
        venueId: comp.lyg_venue_id,
        venueName: comp.lyg_venue_name,
        eventEndDate: new Date(comp.event.lyg_event_end_date),
        eventStartDate: new Date(comp.event.lyg_event_start_date),
        numberOfQuarters: comp.lyg_competition_number_quarters,
        eventStatus: comp.event.lyg_event_status,
      });
    },
    { staleTime: 1000 * 60 * 5 },
  );
}
