import { apiClient } from 'src/shared/utils/apiClient';

export interface PaymentResponse {
  created_at: string;
  payment_amount: number;
  payment_client_secret: string;
  payment_currency: string;
  payment_custumer_id: string;
  payment_id: string;
  payment_payment_id: string;
  payment_plan: 'free' | 'club' | 'academy';
  payment_price_id: string;
  payment_quantity: number;
  payment_subscription_id: string;
  updated_at: string;
}

interface CurrentUserPermission {
  basic_number_of_players: number | null;
  default_org_flag: 1 | 0;
  flag_limit_of_free_videos: number;
  id: number;
  org_avatar: string | null;
  org_id: number;
  org_name: string;
  org_site_code: string;
  payment_amount: number | null;
  payment_currency: string | null;
  payment_customer_id: string | null;
  payment_frequency: number | null;
  payment_plan: string | null;
  payment_price_id: string | null;
  payment_quantity: number | null;
  payment_subscription_id: string | null;
  sport_id: number | null;
  sport_name: string | null;
  user_id: number;
  user_role: string;
}

export interface GetCurrentUserResponse {
  address_id: number | null;
  admin_first_login: string;
  admin_last_login: string;
  admin_login_counter: number;
  confirm_email_token: string;
  created_at: string;
  created_by: number | null;
  debugging: 0 | 1;
  deleted_at: string | null;
  direct_registration_flag: 0 | 1;
  email: string;
  email_confirmation: 0 | 1;
  email_newsletter: 0 | 1;
  email_notifications: 0 | 1;
  email_personal: 0 | 1;
  email_team: 0 | 1;
  first_login_at: string;
  last_login_at: string;
  login_counter: 0 | 1;
  onboarding: 0 | 1;
  onboarding_coach: 0 | 1;
  onboarding_payment: 0 | 1;
  org_avatar: string | null;
  org_description: string | null;
  org_id: number;
  org_name: string;
  org_season_end_day: string | null;
  org_season_month_month: string | null;
  org_season_start_day: string | null;
  org_season_start_month: string | null;
  org_type: string | null;
  pass_reset_token: string | null;
  payment_currency: string | null;
  payment_id: string | null;
  payment_plan: string | null;
  payment_frequency: string | null;
  profile_address: string | null;
  profile_age_group: string | null;
  profile_bio: string | null;
  profile_city: string | null;
  profile_deleted_at: string | null;
  profile_dob: string | null;
  payment_amount: number;
  payment_price_id: string;
  payment_subscription_id: string;
  payment_quantity: number;
  permissions?: CurrentUserPermission[];
  profile_avatar?: string;
  profile_first_name: string;
  profile_fitness: string | null;
  profile_gender: string | null;
  profile_height: string | null;
  profile_how_did_you_hear: string | null;
  profile_id: number;
  profile_last_name: string;
  profile_metric_distance: string;
  profile_metric_weight: string;
  profile_nationality: null;
  profile_number: null;
  profile_position: null;
  profile_postcode: null;
  profile_profession: null;
  profile_rate_communication: null;
  profile_rate_composure: null;
  profile_rate_handling: null;
  profile_rate_passing: null;
  profile_rate_quickness: null;
  profile_rate_unselfishness: null;
  profile_role: string;
  profile_skill: string | null;
  profile_social1: string | null;
  profile_social2: string | null;
  profile_social3: string | null;
  profile_sport: string | null;
  profile_standby: string | null;
  profile_telephone1: string | null;
  profile_telephone2: string | null;
  profile_telephone_code: string | null;
  profile_updated_at: string;
  profile_weight: string | null;
  provider: string | null;
  provider_user_id: string | null;
  push_newsletter: 0 | 1;
  push_notifications: 0 | 1;
  push_personal: 0 | 1;
  push_team: 0 | 1;
  reset_token_updated: string | null;
  strava_integration: 0 | 1;
  team_id: number | null;
  team_name: string | null;
  third_party_api_key: string | null;
  third_party_enabled: 0 | 1;
  time_zone: string | null;
  updated_at: string;
  user_id: number;
  user_pending_coach_approval: 0 | 1;
  lyg_borough_id: number | null;
  lyg_borough_name: string | null;
  lyg_borough_image: string | null;
  lyg_school_id: number | null;
  lyg_school_name: string | null;
}

export async function getCurrentUser(id: number, token: AccessToken): Promise<GetCurrentUserResponse[]> {
  return apiClient(`/users/${id}`, { token });
}
