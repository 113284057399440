import React, { useState } from 'react';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { TextFieldModel } from './TextFieldModel';
import css from './TextField.module.scss';
import { Eye, EyeOff } from 'react-feather';

interface TextFieldProps {
  className?: string;
  model: TextFieldModel;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const TextField = observer<TextFieldProps, HTMLInputElement>(
  ({ className, model, onKeyPress }, ref) => {
    const [hideText, setHideText] = useState(true);

    const {
      disabled,
      errorIsVisible,
      placeholder,
      title,
      id,
      required,
      type,
      value,
      makeErrorVisible,
      setValue,
      selectOnFocus,
      multiple,
      typePassword,
    } = model;

    return (
      <div className='position-relative'>
        <input
          aria-label={title}
          className={cx('w-100 ts-text-overflow', css.field, errorIsVisible && css.invalidField, className)}
          disabled={disabled}
          name={id}
          ref={ref}
          placeholder={placeholder}
          required={required}
          title={title}
          type={!typePassword ? type : hideText ? 'password' : 'text'}
          value={value}
          onBlur={makeErrorVisible}
          onChange={event => setValue(event.target.value)}
          onFocus={event => selectOnFocus && event.target.select()}
          onKeyPress={onKeyPress || undefined}
          multiple={multiple}
        />

        {typePassword && (
          <div className={cx('position-absolute', css.iconContainer)}>
            {hideText ? <Eye onClick={() => setHideText(false)} /> : <EyeOff onClick={() => setHideText(true)} />}
          </div>
        )}
      </div>
    );
  },
  { forwardRef: true },
);
