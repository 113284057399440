import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useI18nContext } from 'src/context/i18n';
import { getBreadcrumbMappings } from 'src/context/routes/breadcrumbMappings';
import { cx } from 'src/shared/utils/common';
import css from './Breadcrumb.module.scss';
import { useCurrentUserRole } from 'src/context/authContext';

interface BreadcrumbMappingCommon {
  title?: string | null;
  hidden?: boolean;
  isNotClickable?: boolean;
}
export interface BreadcrumbMappings extends BreadcrumbMappingCommon {
  href: string;
}

export interface BreadcrumbMappingsWithCallableHref extends BreadcrumbMappingCommon {
  href: (() => string) | ((id: number) => string) | ((id: number, id2: number) => string);
}

interface BreadcrumbProps {
  dynamicName?: string;
  routeState?: unknown;
  className?: string;
  showOnlyFirstPath?: boolean;
  defaultTitle?: string;
  showBasePath?: boolean;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  dynamicName,
  routeState,
  className,
  showOnlyFirstPath,
  defaultTitle = 'Coaches Corner',
  showBasePath = false,
}) => {
  const i18n = useI18nContext();
  const { isAdmin, isLygAdmin } = useCurrentUserRole();

  const id = useParams() as unknown as number & number[];
  const dynamicId1 = Object.values(id)[0];
  const dynamicId2 = Object.values(id)[1];

  const [breadcrumbMappings] = React.useState<BreadcrumbMappingsWithCallableHref[]>(() =>
    getBreadcrumbMappings(isAdmin || isLygAdmin),
  );
  const [currentViewMappings, setCurrentViewMappings] = React.useState<BreadcrumbMappings[] | null>(null);

  const { pathname } = useLocation();
  const currentPath = React.useMemo((): string[] => {
    const current = pathname.replace('/', '').split('/');
    if (showBasePath) current.splice(0, 0, defaultTitle);
    return current;
  }, [defaultTitle, pathname, showBasePath]);

  React.useEffect(() => {
    const currentMappings = currentPath.reduce<BreadcrumbMappings[]>(
      (acc: BreadcrumbMappings[], segment: string, index: number) => {
        const prevMap = acc[index - 1];
        const foundMapping = breadcrumbMappings.find(b => {
          return b.href(dynamicId1, dynamicId2) === `${prevMap?.href || ''}/${segment}`;
        });
        if (!foundMapping) return acc;
        return [
          ...acc,
          { ...foundMapping, href: foundMapping.href(dynamicId1, dynamicId2), title: foundMapping.title || null },
        ];
      },
      showBasePath ? [{ title: defaultTitle, href: '', isNotClickable: true }] : [],
    );

    if (showOnlyFirstPath) {
      if (currentMappings.at(0)?.hidden) currentMappings.splice(0, 1);
      currentMappings.splice(1);
    }
    setCurrentViewMappings(currentMappings);
  }, [currentPath, defaultTitle, dynamicId1, dynamicId2, showBasePath, showOnlyFirstPath, breadcrumbMappings]);

  return (
    <div className={cx('ts-fs-16 ts-text-overflow', css.wrapper, className)}>
      {currentViewMappings?.map((route, index, arr) => {
        const isLastItem = index === arr.length - 1;
        const title = route.title ? i18n.t(route.title, undefined, route.title) : dynamicName || defaultTitle;

        if (isLastItem)
          return (
            <span key={route.href} className='ts-color-primary ts-fw-500 ts-text-overflow'>
              {title}
            </span>
          );

        const page = route.isNotClickable ? (
          <span className='ts-color-primary'>{title}</span>
        ) : (
          <Link className='ts-fw-400' to={route.href} state={routeState}>
            {title}
          </Link>
        );

        return (
          !route?.hidden && (
            <React.Fragment key={route.href}>
              {page}
              <span> / </span>
            </React.Fragment>
          )
        );
      })}
    </div>
  );
};
