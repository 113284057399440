import React from 'react';

export const BasketballIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='12.5' cy='12.5' r='10' stroke='currentColor' strokeWidth={1.7} />
      <path
        d='M2.5 12.5C3.83333 12.3333 7.2 11.4 10 9C12.8 6.6 13.8333 4.16667 14 3'
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth={1.7}
      />
      <path
        d='M22 10.5C20.7163 10.8972 17.8373 12.6473 15.5 15.4999C13.1627 18.3524 12.4602 21.3223 12.5001 22.5001'
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth={1.7}
      />
      <path d='M5.5 19.5L19.5 5.5' stroke='currentColor' strokeLinecap='round' />
      <path
        d='M20.5 18.5C18.3333 18.5 13.3 17.7 10.5 14.5C7 10.5 6.5 7.5 6.5 5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth={1.7}
      />
    </svg>
  );
};
