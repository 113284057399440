import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useAuthContext } from 'src/context/authContext';
import { LYG_MEMBER_ROLE } from 'src/lyg/entities/member';
import { BoroughPicker } from 'src/lyg/features/boroughPicker';
import { MemberPicker } from 'src/lyg/features/memberPicker';
import { SchoolPicker } from 'src/lyg/features/schoolPicker';
import { LYGEntrySchoolModel } from 'src/lyg/pages/entries/shared/LYGSchoolGamesEntryModel';
import { PrimaryInvertedButton } from 'src/shared/ui/buttons';
import { FormFieldWrapper } from 'src/shared/ui/inputs/FormFieldWrapper';
import { TextField } from 'src/shared/ui/inputs/text/TextField';

interface LYGSchoolEntryGeneralDataProps {
  model: LYGEntrySchoolModel;
}

export const LYGSchoolEntryGeneralData: React.FC<LYGSchoolEntryGeneralDataProps> = observer(({ model }) => {
  const { currentUser } = useAuthContext();

  const [addPlayer, setAddPlayer] = useState(false);
  React.useEffect(() => {
    model.setDefaultValues(
      currentUser.boroughId,
      currentUser.schoolId && currentUser.schoolName
        ? {
            schoolId: currentUser.schoolId,
            schoolName: currentUser.schoolName,
          }
        : null,
      null,
    );
  }, [currentUser.boroughId, currentUser.schoolId, currentUser.schoolName, model]);

  return (
    <div className='d-flex flex-column gap-2'>
      <FormFieldWrapper model={model.boroughPicker}>
        <BoroughPicker model={model.boroughPicker} />
      </FormFieldWrapper>
      <FormFieldWrapper model={model.schoolPicker}>
        <SchoolPicker model={model.schoolPicker} boroughId={parseInt(model.boroughPicker.value.id)} />
      </FormFieldWrapper>
      <FormFieldWrapper
        model={model.teamManagerPicker}
        titleExtraContent={<PrimaryInvertedButton onClick={() => setAddPlayer(true)}>Add new</PrimaryInvertedButton>}
      >
        <MemberPicker
          model={model.teamManagerPicker}
          role={LYG_MEMBER_ROLE.stm}
          schoolId={parseInt(model.schoolPicker.value.id)}
        />
      </FormFieldWrapper>

      {addPlayer && (
        <>
          <div className='d-flex flex-wrap flex-md-nowrap w-100'>
            <FormFieldWrapper className='flex-grow-1' model={model.teamManagerName}>
              <TextField model={model.teamManagerName} />
            </FormFieldWrapper>
            <FormFieldWrapper className='ms-sm-3 m-0 flex-grow-1' model={model.teamManagerLastName}>
              <TextField model={model.teamManagerLastName} />
            </FormFieldWrapper>
          </div>
          <div className='d-flex flex-wrap flex-md-nowrap w-100'>
            <FormFieldWrapper className='flex-grow-1' model={model.teamManagerEmail}>
              <TextField model={model.teamManagerEmail} />
            </FormFieldWrapper>
            <FormFieldWrapper className='ms-sm-3 m-0 flex-grow-1' model={model.teamManagerContactNumber}>
              <TextField model={model.teamManagerContactNumber} />
            </FormFieldWrapper>
          </div>
        </>
      )}

      <FormFieldWrapper model={model.numberOfTeams}>
        <TextField model={model.numberOfTeams} />
      </FormFieldWrapper>
    </div>
  );
});
