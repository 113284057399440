import React from 'react';
import { cx } from 'src/shared/utils/common';
import { MoreVertical } from 'react-feather';
import { DataTableDataItem } from 'src/shared/ui/data/types';
import { ModalDialog } from 'src/shared/ui/modal/ModalDialog';
import { DataTableColumnModel } from 'src/shared/ui/data/DataTableColumnModel';
import css from './DataTable.module.scss';
import { Observer } from 'mobx-react-lite';

interface DataTableItemDialogProps<T extends DataTableDataItem> {
  columns: DataTableColumnModel<T>[];
  dialogTitle: string;
  dataItem: T;
  displayOnlyHiddenColumns?: boolean;
  keepKeyValueRender?: boolean;
  buttonRender?: JSX.Element;
}

export const DataTableItemDialog = <T extends DataTableDataItem>({
  children,
  columns,
  dialogTitle,
  dataItem,
  displayOnlyHiddenColumns,
  keepKeyValueRender,
  buttonRender,
}: React.PropsWithChildren<DataTableItemDialogProps<T>>): React.ReactElement => {
  const [opened, setOpened] = React.useState(false);

  function renderRow(props: DataTableColumnModel<T>['props'], index: number) {
    const { key, dialogDataCell, table, title, dataCell } = props;

    if (dialogDataCell !== undefined) {
      return keepKeyValueRender ? (
        <tr key={key as string}>
          <td className='py-2 pe-3 ts-fw-500 align-middle text-nowrap'>{title}</td>
          <td className='py-2 align-middle'>{dialogDataCell({ dataItem, table, index })}</td>
        </tr>
      ) : (
        dialogDataCell({ dataItem, table, index })
      );
    }

    return (
      <tr key={key as string}>
        <td className='py-2 pe-5 ts-fw-500 align-top text-nowrap'>{title}</td>
        <td className='py-2 align-middle'>{dataCell({ dataItem, table, index })}</td>
      </tr>
    );
  }

  const Button = buttonRender
    ? React.cloneElement(buttonRender, {
        onClick: () => setOpened(true),
      })
    : React.createElement(
        'button',
        {
          className: cx('p-0 bg-transparent', css.dialogOpenButton),
          onClick: () => setOpened(true),
        },
        <MoreVertical />,
      );

  return (
    <>
      {Button}

      {opened && (
        <ModalDialog title={dialogTitle} closeDialog={() => setOpened(false)}>
          <table className='w-100'>
            <tbody>
              <Observer>
                {() => (
                  <>
                    {columns.map(({ props, overflows }, index) => {
                      if (displayOnlyHiddenColumns) {
                        return overflows ? renderRow(props, index) : null;
                      }
                      return renderRow(props, index);
                    })}
                  </>
                )}
              </Observer>
            </tbody>
          </table>
          {children}
        </ModalDialog>
      )}
    </>
  );
};
