import React from 'react';
import { observer } from 'mobx-react-lite';
import { BooleanFieldModel } from 'src/shared/ui/inputs/boolean/BooleanFieldModel';
import { cx } from 'src/shared/utils/common';
import css from './ToggleField.module.scss';

interface ToggleFieldProps {
  className?: string;
  model: BooleanFieldModel;
}

export const ToggleField: React.FC<ToggleFieldProps> = observer(({ className, model }) => {
  const { disabled, id, label, title, value, setValue } = model;

  return (
    <div
      className={cx('d-flex user-select-none position-relative', css.field, disabled && css.disabledField, className)}
    >
      <input
        aria-label={title}
        checked={value}
        className='position-absolute'
        disabled={disabled}
        id={id}
        name={id}
        title={title}
        type='checkbox'
        onChange={disabled ? undefined : () => setValue(!value)}
      />
      <div
        className={cx('flex-shrink-0 position-relative', css.toggle, value && css.toggleOn, disabled && css.disabled)}
      />
      {label && (
        <label className='ts-text-light' htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
});
