import { action, makeObservable, observable } from 'mobx';
import { DataTableModel } from 'src/shared/ui/data/DataTableModel';
import { DataTableColumnDef, DataTableDataItem, DATA_TABLE_SORT_ORDER } from 'src/shared/ui/data/types';

interface DataTableColumnModelProps<T extends DataTableDataItem> extends DataTableColumnDef<T> {
  table: DataTableModel<T>;
}

export class DataTableColumnModel<T extends DataTableDataItem> {
  sortOrder = DATA_TABLE_SORT_ORDER.none;

  readonly props: DataTableColumnModelProps<T>;

  constructor(props: DataTableColumnModelProps<T>) {
    this.props = props;

    if (props.sortable && props.sortable.defaultOrder) {
      this.sortOrder = props.sortable.defaultOrder;
    }

    makeObservable(this, {
      sortOrder: observable,
      onHeaderClick: action.bound,
      resetSorting: action.bound,
      setSortOrder: action.bound,
    });
  }

  get overflows(): boolean {
    return Number(this.props.overflowBreakpoint) > this.props.table.width;
  }

  onHeaderClick(): void {
    this.sortOrder =
      this.sortOrder === DATA_TABLE_SORT_ORDER.ascending
        ? DATA_TABLE_SORT_ORDER.descending
        : DATA_TABLE_SORT_ORDER.ascending;
    this.props.table.props.onSort(this.props.sortable?.key as string, this.sortOrder);
    this.props.table.columns
      .filter(column => column.props.key !== this.props.key)
      .forEach(column => column.setSortOrder(DATA_TABLE_SORT_ORDER.none));
  }

  resetSorting(): void {
    this.sortOrder = this.props.sortable?.defaultOrder || DATA_TABLE_SORT_ORDER.none;
  }

  setSortOrder(order: DATA_TABLE_SORT_ORDER): void {
    this.sortOrder = order;
  }
}
