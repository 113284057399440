import React, { memo, useCallback, useEffect } from 'react';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { LYGPusherContext } from 'src/lyg/features/appLayout/pusher/LYGPusherContext';
import { useAuthContext } from 'src/context/authContext';
import { useQueryClient } from 'react-query';

export interface PusherNotification {
  body: string;
  title: string;
}

const beamInstanceId = 'c33f79a5-d364-43d6-871b-c33143cfa7d2';

const defaultBeams = ['coach-features', 'coach-updates', 'coach-news', 'coach-universe'];

export const beamsClient = new PusherPushNotifications.Client({
  instanceId: beamInstanceId,
});

export const LYGPusherProvider: React.FC = memo(({ children }) => {
  const { currentUser } = useAuthContext();
  const queryClient = useQueryClient();

  return (
    <LYGPusherContext.Provider
      value={{
        onClear: async () => await beamsClient.clearAllState(),
        onInit: async () => {
          null;
        },
      }}
    >
      {children}
    </LYGPusherContext.Provider>
  );
});
