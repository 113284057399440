import React from 'react';
import { Circle, X } from 'react-feather';
import { CancelButton, PrimaryButton, PrimaryInvertedButton } from 'src/shared/ui/buttons';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { IntroHelperModel } from 'src/features/appLayout/ui/IntroHelper/model';
import { useOnWindowResize } from 'src/shared/hooks/useOnWindowResize';
import { IntroStep } from 'src/features/appLayout/ui/IntroHelper/introConfig';
import css from './index.module.scss';

interface IntroHelperContext {
  subscribe: (id: string, ref: HTMLElement) => void;
}

export const IntroHelperContext = React.createContext<IntroHelperContext | null>(null);

interface IntroHelperProps {
  steps: IntroStep[] | undefined;
  pageId: string | undefined;
}

export const IntroHelper = observer<IntroHelperProps>(({ children, steps, pageId }) => {
  const [model] = React.useState(new IntroHelperModel(steps, pageId));
  useOnWindowResize(() => model.computeHelperSize());

  const subscribe: IntroHelperContext['subscribe'] = React.useCallback((id, ref) => model.subscribe(id, ref), [model]);

  const shouldHide = React.useMemo(() => !steps || model.shouldHide, [steps, model.shouldHide]);

  return (
    <>
      <div className={cx(css.overlay, shouldHide && css.hide)} />
      <div ref={model.helperRef} className={cx(css.helper, shouldHide && css.hide)}>
        <div ref={model.tooltipRef} className={cx(css.tooltip, 'p-3 d-flex flex-column gap-3', shouldHide && css.hide)}>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <h5 className='mb-0'>{model.currentStep?.title}</h5>
            <PrimaryInvertedButton onClick={model.end}>
              <X />
            </PrimaryInvertedButton>
          </div>

          <div className='mb-2'>
            <p>{model.currentStep?.content}</p>
          </div>

          <div className={cx(css.steps, 'm-auto d-flex gap-1')}>
            {model.subscribedSteps.map((_, i) => (
              <Circle key={i} className={cx(i === model.stepIndex && css.stepsActive)} />
            ))}
          </div>

          <div className='d-flex w-100 justify-content-around gap-4'>
            <CancelButton disabled={model.isFirstStep} className='flex-grow-1' onClick={model.prevStep}>
              Back
            </CancelButton>
            <PrimaryButton className='flex-grow-1' onClick={model.nextStep}>
              {model.isLastStep ? 'Finish' : 'Next'}
            </PrimaryButton>
          </div>
        </div>
      </div>
      <IntroHelperContext.Provider value={{ subscribe }}>{children}</IntroHelperContext.Provider>
    </>
  );
});
