import { apiClient } from 'src/shared/utils/apiClient';

interface GetIntercomHashResponse {
  code: number;
  error: boolean;
  message: string;
  response: string;
}

export function getIntercomHash(token: AccessToken): Promise<GetIntercomHashResponse> {
  return apiClient(`/v1/service/providers/intercom`, { token });
}
