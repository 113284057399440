export interface LYGCompetitionPool {
  id: number;
  name: string;
  teams: { id: number; name: string; borough: string | null; school: string | null }[];
}

type LYGCompetitionPoolProps = OnlyData<LYGCompetitionPool>;

export class LYGCompetitionPool {
  constructor(init: LYGCompetitionPoolProps) {
    Object.assign(this, init);
  }

  getTeamIds(): Set<number> {
    return new Set(this.teams.map(({ id }) => id));
  }
}
