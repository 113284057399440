export interface SwimmingRecreationalEntry {
  eventNumber: number | null;
  event: string | null;
  level: string | null;
  ageGroup: string | null;
  eventSex: string | null;
}

export class SwimmingRecreationalEntry {
  constructor(params: OnlyData<SwimmingRecreationalEntry>) {
    Object.assign(this, params);
  }
}
