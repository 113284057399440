import React from 'react';
import { useAuthContext } from 'src/context/authContext';
import { SpinnerAnimation } from 'src/shared/ui/assets/SpinnerAnimation';
import { loadIntercom, initIntercom, INTERCOM_CUSTOM_LAUNCHER_ID } from 'src/features/intercom/utils';
import css from './IntercomLoader.module.scss';

interface IntercomLoaderProps {
  button?: React.ReactElement;
}

export const IntercomLoader: React.FC<IntercomLoaderProps> = ({ button }) => {
  const { currentUser } = useAuthContext();
  const [isLoading, setIsLoading] = React.useState(false);

  function onIntercomLoad(): void {
    setIsLoading(true);
    loadIntercom();
    initIntercom(currentUser);
    window.Intercom('onShow', () => setIsLoading(false));
    window.Intercom('show');
  }

  function handleClick() {
    !window.Intercom ? onIntercomLoad() : window.Intercom('show');
  }

  const RenderButton = button && React.cloneElement(button, { onClick: handleClick });

  return isLoading && !button ? (
    <div className={css.intercomSpinnerWrapper}>
      <SpinnerAnimation className={css.intercomSpinner} />
    </div>
  ) : (
    RenderButton || (
      <button
        className={css.intercomBtn}
        onClick={handleClick}
        tabIndex={-1}
        name='Open intercom chat'
        aria-label='Open intercom chat'
        id={INTERCOM_CUSTOM_LAUNCHER_ID}
      >
        <svg focusable='false' aria-hidden='true' viewBox='0 0 28 32' width='28' height='32' fill='white'>
          {/*     {isOpen ? (
          <path d='M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z' />
        ) : ( */}
          <path d='M28,32 C28,32 23.2863266,30.1450667 19.4727818,28.6592 L3.43749107,28.6592 C1.53921989,28.6592 0,27.0272 0,25.0144 L0,3.6448 C0,1.632 1.53921989,0 3.43749107,0 L24.5615088,0 C26.45978,0 27.9989999,1.632 27.9989999,3.6448 L27.9989999,22.0490667 L28,22.0490667 L28,32 Z M23.8614088,20.0181333 C23.5309223,19.6105242 22.9540812,19.5633836 22.5692242,19.9125333 C22.5392199,19.9392 19.5537934,22.5941333 13.9989999,22.5941333 C8.51321617,22.5941333 5.48178311,19.9584 5.4277754,19.9104 C5.04295119,19.5629428 4.46760991,19.6105095 4.13759108,20.0170667 C3.97913051,20.2124916 3.9004494,20.4673395 3.91904357,20.7249415 C3.93763774,20.9825435 4.05196575,21.2215447 4.23660523,21.3888 C4.37862552,21.5168 7.77411059,24.5386667 13.9989999,24.5386667 C20.2248893,24.5386667 23.6203743,21.5168 23.7623946,21.3888 C23.9467342,21.2215726 24.0608642,20.9827905 24.0794539,20.7254507 C24.0980436,20.4681109 24.0195551,20.2135019 23.8614088,20.0181333 Z' />
        </svg>
      </button>
    )
  );
};
