import React from 'react';
import { Settings } from 'react-feather';
import { useI18nContext } from 'src/context/i18n';
import { useAppRoutesContext } from 'src/context/routes';
import { Link } from 'react-router-dom';

export const HeaderAccountIcon: React.FC = () => {
  const i18n = useI18nContext();
  const routes = useAppRoutesContext();

  const ariaLabel = i18n.t('pages.settings.settings');

  return (
    <Link aria-label={ariaLabel} className='ts-text' to={routes.settings.base} title={ariaLabel}>
      <Settings />
    </Link>
  );
};
