import ReactGA from 'react-ga4';
import { useAuthContext } from 'src/context/authContext';

/*
 * Format should be `ts_{page}_{feature/section}_{filter/action}`.
 */
export enum TS_GA_EVENT {
  acceptPlayer = 'ts_coaches_player_accept',
  dashboardUploadVideo = 'ts_dashboard_videos_uploaded',
  playersFeedbackGiven = 'ts_players_feedback_given',
  playersFeedbackPublished = 'ts_players_feedback_published',
  gameEventCreated = 'ts_events_game_created',
  practiceEventCreated = 'ts_events_practice_created',
  competitionEventCreated = 'ts_events_competition_created',
  hangoutEventCreated = 'ts_events_hangout_created',
  gameResultEdit = 'ts_stats_results_edited',
  videoUploaded = 'ts_videos_uploaded',
  membershipUpdated = 'ts_membership_updated',
  eventUpdated = 'ts_events_updated',
  clipShared = 'ts_videos_clips_shared',
  tagCreated = 'ts_videos_tag_created',
  trainingTargetCreated = 'ts_training_target_created',
  installAppDismiss = 'ts_install_app_dismiss',
  installAppAccepted = 'ts_install_app_accepted',
  installAppInstalled = 'ts_install_app_installed',
}

export const useAnalytics = () => {
  const { currentUser } = useAuthContext();

  function sendGAEvent(event: TS_GA_EVENT): void {
    ReactGA.event(event, currentUser);
  }

  return { sendGAEvent };
};

export function sendGAPageView(): void {
  ReactGA.send('pageview');
}
