import React, { useState } from 'react';
import { useI18nContext } from 'src/context/i18n';
import { CompetitionGame } from 'src/entities/competition/domain';
import { EditGameDataModel } from 'src/pages/competitions/bracket/components/EditGameDataModel';
import { useEditGameDateMutation } from 'src/pages/competitions/bracket/mutation';
import { CancelButton, PrimaryButton } from 'src/shared/ui/buttons';
import { FormFieldWrapper } from 'src/shared/ui/inputs/FormFieldWrapper';
import { DateField } from 'src/shared/ui/inputs/datetime/DateField';
import { TimePickerField } from 'src/shared/ui/inputs/datetime/TimePickerField';
import { TextField } from 'src/shared/ui/inputs/text/TextField';
import { ModalDialog } from 'src/shared/ui/modal/ModalDialog';
import { ErrorMessage } from 'src/shared/ui/utils/ErrorMessage';

interface EditGameDataModalProps {
  game: CompetitionGame;
  closeDialog(): void;
}

export const EditGameDataModal: React.FC<EditGameDataModalProps> = ({ game, closeDialog }) => {
  const i18n = useI18nContext();
  const [model] = useState(new EditGameDataModel({ i18n, game }));

  const { mutate, isLoading, error } = useEditGameDateMutation(game.competitionId);

  return (
    <>
      <ModalDialog title={`Edit game data for ${game.teamAName} vs ${game.teamBName}`} closeDialog={closeDialog}>
        <div className='d-flex gap-2'>
          <FormFieldWrapper className='w-100' model={model.date}>
            <DateField model={model.date} />
          </FormFieldWrapper>
          <FormFieldWrapper className='w-100' model={model.time}>
            <TimePickerField model={model.time} />
          </FormFieldWrapper>
        </div>
        <FormFieldWrapper model={model.court}>
          <TextField model={model.court} />
        </FormFieldWrapper>

        {error && <ErrorMessage message={error.message} className='mt-3' />}

        <div className='d-flex gap-2 mt-3 w-100'>
          <CancelButton
            className='w-100'
            disabled={isLoading}
            onClick={() => {
              model.reset();
              closeDialog();
            }}
          >
            Cancel
          </CancelButton>
          <PrimaryButton
            className='w-100'
            loading={isLoading}
            onClick={() => mutate(model.data, { onSuccess: closeDialog })}
          >
            Save
          </PrimaryButton>
        </div>
      </ModalDialog>
    </>
  );
};
