import { LYG_EVENT_TYPE } from 'src/lyg/entities/events';
import { ScheduleDate } from 'src/lyg/pages/competitions/schedule/ui/LYGScheduleFormModel';

export const LYG_COMPETITION_STATUS = {
  entered: 'ENTERED',
  notEntered: 'NOT ENTERED',
} as const;
export type LYG_COMPETITION_STATUS = ValueOf<typeof LYG_COMPETITION_STATUS>;

export const LYG_COMPETITION_CATEGORY_TYPE = {
  boys: 'boys',
  girls: 'girls',
  mixed: 'mixed',
} as const;
export type LYG_COMPETITION_CATEGORY_TYPE = ValueOf<typeof LYG_COMPETITION_CATEGORY_TYPE>;

export interface LYGCompetition {
  id: number;
  name: string;
  sportName: string;
  sportId: number;
  sportIcon: string | null;
  eventId: number;
  venueId: number;
  venueName: string;
  categoryType: string | null;
  categoryClass: string | null;
  categoryAgeGroup: string | null;
  type: LYG_EVENT_TYPE;
  eventStartDate: Date;
  eventEndDate: Date;
  eventStatus: string;
  dates: ScheduleDate[];
  breakTime: number;
  numberOfCourts: number;
  spreadGamesEvenlyBetweenDates: boolean;
  minDobLimit: Date | null;
  maxDobLimit: Date | null;
  status: LYG_COMPETITION_STATUS | null;
  boroughCount: number | null;
  matchupFrequency: number;
  matchDuration: number;
  pointsForWin: number;
  pointsForLoss: number;
  isDrawAllowed: boolean;
  pointsForDraw: number;
  isWalkoverAllowed: boolean;
  pointsForAwardedWalkover: number;
  pointsForConcedingWalkover: number;
  pointsForWalkoverWin: number;
  numberOfQuarters: number;
  hasStarted: boolean;
  showTimeInSchedule: boolean;
}

export const groupCompetitionsBySport = (competitions: LYGCompetition[]) => {
  return competitions.reduce((grouped: Record<string, LYGCompetition[]>, competition) => {
    const { sportName } = competition;
    grouped[sportName] = grouped[sportName] || [];
    grouped[sportName].push(competition);
    return grouped;
  }, {});
};

type LYGCompetitionProps = OnlyData<LYGCompetition>;

export class LYGCompetition {
  constructor(init: LYGCompetitionProps) {
    Object.assign(this, init);
  }
}
