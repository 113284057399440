import React from 'react';
import { useAppRoutesContext } from 'src/context/routes';
import { cx } from 'src/shared/utils/common';
import { PrimaryButton } from 'src/shared/ui/buttons';
import Confetti from 'react-confetti';
import css from './4xxPage.module.scss';

interface FallbackErrorPageProps {
  error: Error;
}

export const FallbackErrorPage: React.FC<FallbackErrorPageProps> = ({ error }) => {
  const routes = useAppRoutesContext();

  if (error?.message.includes('Failed to fetch dynamically imported module'))
    return (
      <div className='d-flex flex-column justify-content-center align-items-center gap-4'>
        <h4>
          Yeah! We have just released new features. We are improving the system by creating new features and squashing
          bugs. Please refresh your application so you can have access to the new features.
        </h4>
        <PrimaryButton className='w-100' onClick={() => window.location.reload()}>
          Refresh!
        </PrimaryButton>
      </div>
    );

  return (
    <div
      className={cx(
        'd-flex flex-column flex-lg-row align-items-center justify-content-center vh-100 overflow-hidden ts-fade-in-03',
        css.wrapper,
      )}
    >
      <div style={{ width: '100%' }} className={css.textWrapper}>
        <h3 style={{ fontSize: '26px', fontWeight: 'bold' }} className='ts-text-light ts-fw-600'>
          We have new features for you!
        </h3>
        <p style={{ fontSize: '17px', fontWeight: 'bold' }}>
          We release new features and fix bugs all the time to give you the best experience
        </p>
        <p style={{ fontSize: '26px', fontWeight: 'bold' }} className='ts-fs-18 mt-3'>
          <p>Please refresh the page to get the latest features</p>

          <span>
            <a href={routes.dashboard}>Click to refresh</a>
          </span>
        </p>
      </div>
      <div className={css.imageWrapper}>
        <img src='/assets/img/4xx/408.png' alt="Couldn't load" />
      </div>
      <div>
        <Confetti
          width={600}
          height={400}
          style={{ width: '60%', margin: '0 auto', paddingLeft: '10px' }}
          recycle={false}
        />
      </div>
    </div>
  );
};
