import React from 'react';
import { useParams } from 'react-router-dom';
import { useLYGCompetitionQuery } from 'src/lyg/pages/competitions/pools/query';
import { useLYGCompetitionScheduleQuery } from 'src/lyg/pages/competitions/schedule/query';
import QRCode from 'react-qr-code';
import { cx } from 'src/shared/utils/common';
import css from './CompetitionDetailsPage.module.css';
import { useDateTimeFormat } from 'src/shared/hooks/useDateTimeFormat';
import { RippleAnimation } from 'src/shared/ui/assets/RippleAnimation';

export const PublicQrPage: React.FC = () => {
  const { competitionId } = useParams();
  const competitionQuery = useLYGCompetitionQuery(Number(competitionId), false);
  const scheduleQuery = useLYGCompetitionScheduleQuery(Number(competitionId), false);
  const { formatDate } = useDateTimeFormat();

  const title = `LYG${competitionQuery.data && formatDate(competitionQuery.data?.eventStartDate, 'yy')} ${
    competitionQuery.data?.sportName
  } ${competitionQuery.data?.name}`;
  const location = window.location.href;

  if (competitionQuery.isLoading || scheduleQuery.isFetching) {
    return <RippleAnimation className='d-block mx-auto ts-color-primary' />;
  }

  return (
    <div>
      <div className='p-2 container mt-3' style={{ maxWidth: '800px', width: '95%' }}>
        <div className='mb-3'>
          <div className={cx('d-flex align-items-center justify-content-between mb-5', css.logoSection)}>
            <img
              width={100}
              src={'/assets/lyg/lyg.png'}
              alt='London Youth Games Logo'
              className={cx('flex-shrink-0 user-select-none')}
            />
            <div>
              <h2>{title}</h2>
              <p className='ts-fs-18 mb-0 text-end'>
                {scheduleQuery.data && formatDate(scheduleQuery.data[0].games[0].date, 'dd LLL yyyy')}
              </p>
            </div>
          </div>

          <div className={cx('text-center w-100', css.publicQrSection)}>
            <QRCode
              size={500}
              value={location.substring(0, location.lastIndexOf('/'))}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            />
            <h3 className='mt-4'>LIVE SCHEDULE</h3>
          </div>
        </div>

        <div className={cx('mt-5', css.footer)}>
          <h4 className='ts-text-light'>Powered by</h4>
          <img
            className={css.tsLogo}
            src='https://files.teamsportz.app/assets/logo/logo%20full%20light.png'
            alt='Logo'
          />
          <div className='d-flex gap-3 justify-content-end'>
            <a className='ts-text-lighter' href='mailto:hello@teamsportz.pro'>
              hello@teamsportz.pro
            </a>
            <a className='ts-text-lighter' href='https://teamsportz.pro'>
              www.teamsportz.pro
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
