import { UseQueryResult, useQuery } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { LYGMember, LYG_MEMBER_ROLE } from 'src/lyg/entities/member';
import { LYG_MEMBERS_KEYS } from 'src/lyg/pages/members/shared/queryKeys';
import { apiClient } from 'src/lyg/utils/apiClient';
import { ApiError } from 'src/lyg/utils/apiError';

interface GetMembersRequest {
  page: number;
  limit: number;
  name?: string;
  role?: LYG_MEMBER_ROLE | null;
  sport?: number | null;
  schoolId?: number | null;
}

interface MembersResponse {
  email: string;
  user_id: number;
  profile_avatar: string | null;
  profile_first_name: string;
  profile_id: number;
  profile_last_name: string;
  profile_role: string;
  profile_telephone1: string | null;
  lyg_borough_id: number | null;
  lyg_borough_name: string | null;
  lyg_school_id: number | null;
  lyg_school_name: string | null;
  sport_id: number | null;
  sport_name: string | null;
}

interface GetMembersResponse {
  current_page: number;
  data: MembersResponse[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: { active: boolean; label: string; url: null | string }[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}
interface QueryResponse {
  count: number;
  members: LYGMember[];
}

async function getMembers(request: GetMembersRequest, token: AccessToken): Promise<GetMembersResponse> {
  const params = [`limit=${request.limit}`, `page=${request.page}`];
  if (request.name) params.push(`search=${request.name}`);
  if (request.role) params.push(`role=${request.role}`);
  if (request.sport) params.push(`sport=${request.sport}`);
  if (request.schoolId) params.push(`lyg_school_id=${request.schoolId}`);

  return apiClient(`/v1/lyg/members?${params.join('&')}`, { token });
}

export function useLYGMembersQuery(
  filters: GetMembersRequest,
  onSuccess?: (data: QueryResponse) => void,
): UseQueryResult<QueryResponse, ApiError> {
  const { token } = useAuthContext();

  return useQuery<QueryResponse, ApiError>(
    LYG_MEMBERS_KEYS.filtered(filters),
    async () => {
      const { data, total } = await getMembers(filters, token);
      return {
        members: data.map(
          member =>
            new LYGMember({
              id: member.user_id,
              email: member.email,
              firstName: member.profile_first_name,
              lastName: member.profile_last_name,
              rawRole: member.profile_role,
              avatarUrl: member.profile_avatar,
              boroughId: member.lyg_borough_id,
              boroughName: member.lyg_borough_name || null,
              schoolId: member.lyg_school_id,
              schoolName: member.lyg_school_name || null,
              sportId: member.sport_id,
              sportName: member.sport_name,
              phone: member.profile_telephone1,
            }),
        ),
        count: total,
      };
    },
    { onSuccess },
  );
}
