export interface TennisEntry {
  membershipNumber: string;
  itfWorldTennisNumber: string;
  seniority: string;
}

export class TennisEntry {
  public constructor(params: OnlyData<TennisEntry>) {
    Object.assign(this, params);
  }
}
