import React from 'react';
import { Bracket } from 'react-brackets';
import { LYGCompetition } from 'src/lyg/entities/competitions';
import { LYGCompetitionBracket } from 'src/lyg/entities/competitions/LYGCompetitionBracket';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { LYGBracketMatch } from 'src/lyg/pages/competitions/bracket/components/LYGBracketMatch';
import { LYGEditGameDataModal } from 'src/lyg/pages/competitions/bracket/components/LYGEditGameDataModal';
import { LYGEditGameScoreModal } from 'src/lyg/pages/competitions/schedule/ui/LYGEditGameScoreModal';

interface LYGBracketProps {
  bracket: LYGCompetitionBracket;
  competition: LYGCompetition | undefined;
}

export const LYGBracket: React.FC<LYGBracketProps> = ({ competition, bracket }) => {
  const [editDateGame, setEditDateGame] = React.useState<LYGCompetitionGame | null>(null);
  const [editScoreGame, setEditScoreGame] = React.useState<LYGCompetitionGame | null>(null);

  return (
    <>
      <Bracket
        renderSeedComponent={props => (
          <LYGBracketMatch
            {...props}
            onEditDate={setEditDateGame}
            onEditScore={setEditScoreGame}
            disableActions={!competition}
          />
        )}
        rounds={bracket.getRenderData()}
      />
      {editScoreGame && competition && (
        <LYGEditGameScoreModal
          numberOfQuarters={competition.numberOfQuarters}
          closeDialog={() => setEditScoreGame(null)}
          game={editScoreGame}
        />
      )}
      {editDateGame && competition && (
        <LYGEditGameDataModal closeDialog={() => setEditDateGame(null)} game={editDateGame} />
      )}
    </>
  );
};
