export const LYG_ENTRIES_KEYS = {
  base: 'open_entries',
  filtered: (filters: AnyObject): unknown[] => [...LYG_ENTRIES_KEYS.base, filters],
  entry: (id: number): unknown[] => [...LYG_ENTRIES_KEYS.base, id],
};

export const LYG_SCHOOL_ENTRIES_KEYS = {
  base: 'school_entries',
  filtered: (filters: AnyObject): unknown[] => [...LYG_SCHOOL_ENTRIES_KEYS.base, filters],
  entry: (id: number): unknown[] => [...LYG_SCHOOL_ENTRIES_KEYS.base, id],
};
