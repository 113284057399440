import { computed, makeObservable } from 'mobx';
import { I18nModel } from 'src/context/i18n/I18nModel';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { BooleanFieldModel } from 'src/shared/ui/inputs/boolean/BooleanFieldModel';
import { TEXT_FIELD_TYPE, TextFieldModel } from 'src/shared/ui/inputs/text/TextFieldModel';
import { numberHasOnlyPositiveDigits } from 'src/shared/utils/numbers';

interface ScoreData {
  teamAScore: number;
  teamBScore: number;
  quarter: number;
}

interface LYGEditGameScoreFormModelProps {
  i18n: I18nModel;
  game: LYGCompetitionGame;
  numberOfQuarters: number;
}

export class LYGEditGameScoreFormModel {
  scoreA: TextFieldModel[];
  scoreB: TextFieldModel[];
  forfeit: BooleanFieldModel;
  forfeitReason: TextFieldModel;

  constructor(props: LYGEditGameScoreFormModelProps) {
    this.scoreA = Array.from(
      { length: props.numberOfQuarters },
      (_, i) =>
        new TextFieldModel({
          i18n: props.i18n,
          id: `teamAScore-${props.game.id}-${i}`,
          title: `Period ${i + 1}`,
          initialValue: props.game.scoreBreakdown.at(i)?.teamAScore?.toString() || '0',
          required: true,
          type: TEXT_FIELD_TYPE.number,
          validators: [{ validate: numberHasOnlyPositiveDigits, message: 'Only positive numbers are allowed' }],
        }),
    );

    this.scoreB = Array.from(
      { length: props.numberOfQuarters },
      (_, i) =>
        new TextFieldModel({
          i18n: props.i18n,
          id: `teamBScore-${props.game.id}-${i}`,
          title: `Period ${i + 1}`,
          initialValue: props.game.scoreBreakdown.at(i)?.teamBScore?.toString() || '0',
          required: true,
          type: TEXT_FIELD_TYPE.number,
          validators: [{ validate: numberHasOnlyPositiveDigits, message: 'Only positive numbers are allowed' }],
        }),
    );

    this.forfeit = new BooleanFieldModel({ i18n: props.i18n, id: 'forfeit', initialValue: false, title: 'Forfeit' });
    this.forfeitReason = new TextFieldModel({
      i18n: props.i18n,
      id: `forfeit-reason`,
      title: 'Forfeit Reason',
      initialValue: '',
      required: false,
    });

    makeObservable(this, {
      hasChanged: computed,
      hasError: computed,
      data: computed,
    });
  }

  get data(): ScoreData[] {
    return this.scoreA.map((score, i) => ({
      teamAScore: Number(score.value),
      teamBScore: Number(this.scoreB[i].value),
      quarter: i + 1,
    }));
  }

  get hasChanged(): boolean {
    return (
      this.scoreA.some(score => score.hasChanged) ||
      this.scoreB.some(score => score.hasChanged) ||
      this.forfeit.hasChanged ||
      this.forfeitReason.hasChanged
    );
  }
  get hasError(): boolean {
    return (
      this.scoreA.some(score => score.hasError) ||
      this.scoreB.some(score => score.hasError) ||
      this.forfeit.hasError ||
      this.forfeitReason.hasError
    );
  }

  reset(): void {
    this.scoreA.forEach(score => score.reset());
    this.scoreB.forEach(score => score.reset());
    this.forfeit.reset();
    this.forfeitReason.reset();
  }
}
