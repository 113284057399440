import { RegistrationState } from '@pusher/push-notifications-web';
import { action, computed, makeObservable, observable } from 'mobx';
import { beamsClient } from 'src/features/pusher/PusherProvider';
import { browserIsSafari } from 'src/shared/utils/common';

export class SafariNotificationModel {
  userPreference: boolean;
  notificationsAlreadyEnabled = true;
  private KEY = 'SAFARI_PUSH_NOTIFICATIONS';

  constructor() {
    beamsClient.getRegistrationState().then(state => {
      this.setNotificationsAlreadyEnabled(
        state === RegistrationState.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS ||
          state === RegistrationState.PERMISSION_DENIED,
      );
    });
    this.userPreference = browserIsSafari() ? JSON.parse(localStorage.getItem(this.KEY) || 'true') : false;

    makeObservable(this, {
      notificationsAlreadyEnabled: observable,
      shouldShowPrompt: computed,
      userPreference: observable,
      setUserPreference: action.bound,
      setNotificationsAlreadyEnabled: action.bound,
    });
  }

  get shouldShowPrompt(): boolean {
    return this.notificationsAlreadyEnabled ? false : this.userPreference;
  }

  setNotificationsAlreadyEnabled(value: boolean): void {
    this.notificationsAlreadyEnabled = value;
  }

  setUserPreference(value: boolean): void {
    this.userPreference = value;
    localStorage.setItem(this.KEY, String(value));
  }
}
