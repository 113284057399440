export interface SwimmingEntry {
  reserve: boolean;
  membershipNumber: string;
  entryTime: number;
  entryTimeCourse: string;
  competitionEvent: string;
  swimmingEvent: string;
  paraSwimmingClassification: string;
  fourTimesFiftyMetersMedley: boolean;
  fourTimesFiftyMetersFreestyle: boolean;
}

export class SwimmingEntry {
  constructor(params: OnlyData<SwimmingEntry>) {
    Object.assign(this, params);
  }
}
