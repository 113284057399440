import React from 'react';
import { Calendar } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { useCurrentUserRole } from 'src/context/authContext';
import { useLYGAppRoutesContext } from 'src/context/routes';
import { LYGBracket } from 'src/lyg/pages/competitions/bracket/components/LYGBracket';
import { LYGMoveBracketToNextPhaseButton } from 'src/lyg/pages/competitions/bracket/components/LYGMoveBracketToNextPhaseButton';
import { useLYGCompetitionBracketQuery } from 'src/lyg/pages/competitions/bracket/query';
import { useLYGCompetitionQuery } from 'src/lyg/pages/competitions/pools/query';
import { useBrowserTabTitle } from 'src/shared/hooks/useBrowserTabTitle';
import { AuthenticatedPage } from 'src/shared/ui/layout/AuthenticatedPage';

export const CompetitionBracketPage: React.FC = () => {
  useBrowserTabTitle('Bracket');
  const { isAdmin, isLygAdmin } = useCurrentUserRole();
  const routes = useLYGAppRoutesContext();
  const params = useParams();
  const eventId = Number(params.eventId as string);
  const competitionId = Number(params.competitionId as string);
  const competitionQuery = useLYGCompetitionQuery(competitionId);
  const bracketQuery = useLYGCompetitionBracketQuery(competitionId);

  return (
    <AuthenticatedPage query={bracketQuery} title={competitionQuery.data?.name || 'Loading...'}>
      {({ data: bracket }) => (
        <>
          <div className='d-flex w-100 justify-content-end mb-4 gap-3'>
            {!bracket.hasEnded && (isAdmin || isLygAdmin) && (
              <LYGMoveBracketToNextPhaseButton competitionId={competitionId} isDisabled={!bracket.canMoveToNextPhase} />
            )}
            <Link className='d-flex align-items-center gap-2' to={routes.competitions.schedule(eventId, competitionId)}>
              <Calendar className='align-center' />
              View Schedule & Leaderboard
            </Link>
          </div>
          <LYGBracket competition={competitionQuery.data} bracket={bracket} />
        </>
      )}
    </AuthenticatedPage>
  );
};
