import { computed, makeObservable } from 'mobx';
import { I18nModel } from 'src/context/i18n/I18nModel';
import { DemographicData } from 'src/lyg/entities/entries/schoolGameEntry';
import { TextFieldModel } from 'src/shared/ui/inputs/text/TextFieldModel';

export interface DemographicFormData {
  asianBoys: string;
  asianGirls: string;
  asianNonBinaryOther: string;

  blackBritishBoys: string;
  blackBritishGirls: string;
  blackBritishNonBinaryOther: string;

  mixedRaceBoys: string;
  mixedRaceGirls: string;
  mixedRaceNonBinaryOther: string;

  whiteBritishBoys: string;
  whiteBritishGirls: string;
  whiteBritishNonBinaryOther: string;

  whiteOtherBoys: string;
  whiteOtherGirls: string;
  whiteOtherNonBinaryOther: string;

  otherEthnicityBoys: string;
  otherEthnicityGirls: string;
  otherEthnicityNonBinaryOther: string;

  preferNotToSayEthnicityBoys: string;
  preferNotToSayEthnicityGirls: string;
  preferNotToSayEthnicityNonBinaryOther: string;

  g1DisabilityBoys: string;
  g1DisabilityGirls: string;
  g1DisabilityNonBinaryOther: string;

  g2DisabilityBoys: string;
  g2DisabilityGirls: string;
  g2DisabilityNonBinaryOther: string;

  g3aDisabilityBoys: string;
  g3aDisabilityGirls: string;
  g3aDisabilityNonBinaryOther: string;

  g3bDisabilityBoys: string;
  g3bDisabilityGirls: string;
  g3bDisabilityNonBinaryOther: string;

  g4DisabilityBoys: string;
  g4DisabilityGirls: string;
  g4DisabilityNonBinaryOther: string;

  g5DisabilityBoys: string;
  g5DisabilityGirls: string;
  g5DisabilityNonBinaryOther: string;

  behaviouralDisabilityBoys: string;
  behaviouralDisabilityGirls: string;
  behaviouralDisabilityNonBinaryOther: string;

  mentalHealthDisabilityBoys: string;
  mentalHealthDisabilityGirls: string;
  mentalHealthDisabilityNonBinaryOther: string;

  otherDisabilityBoys: string;
  otherDisabilityGirls: string;
  otherDisabilityNonBinaryOther: string;

  noneDisabilityBoys: string;
  noneDisabilityGirls: string;
  noneDisabilityNonBinaryOther: string;

  preferNotToSayDisabilityBoys: string;
  preferNotToSayDisabilityGirls: string;
  preferNotToSayDisabilityNonBinaryOther: string;
}

interface LYGDemographicDataModelProps {
  i18n: I18nModel;
}

export class LYGSchoolEntryDemographicDataModel {
  asianBoys: TextFieldModel;
  asianGirls: TextFieldModel;
  asianNonBinaryOther: TextFieldModel;

  blackBritishBoys: TextFieldModel;
  blackBritishGirls: TextFieldModel;
  blackBritishNonBinaryOther: TextFieldModel;

  mixedRaceBoys: TextFieldModel;
  mixedRaceGirls: TextFieldModel;
  mixedRaceNonBinaryOther: TextFieldModel;

  whiteBritishBoys: TextFieldModel;
  whiteBritishGirls: TextFieldModel;
  whiteBritishNonBinaryOther: TextFieldModel;

  whiteOtherBoys: TextFieldModel;
  whiteOtherGirls: TextFieldModel;
  whiteOtherNonBinaryOther: TextFieldModel;

  otherEthnicityBoys: TextFieldModel;
  otherEthnicityGirls: TextFieldModel;
  otherEthnicityNonBinaryOther: TextFieldModel;

  preferNotToSayEthnicityBoys: TextFieldModel;
  preferNotToSayEthnicityGirls: TextFieldModel;
  preferNotToSayEthnicityNonBinaryOther: TextFieldModel;

  g1DisabilityBoys: TextFieldModel;
  g1DisabilityGirls: TextFieldModel;
  g1DisabilityNonBinaryOther: TextFieldModel;

  g2DisabilityBoys: TextFieldModel;
  g2DisabilityGirls: TextFieldModel;
  g2DisabilityNonBinaryOther: TextFieldModel;

  g3aDisabilityBoys: TextFieldModel;
  g3aDisabilityGirls: TextFieldModel;
  g3aDisabilityNonBinaryOther: TextFieldModel;

  g3bDisabilityBoys: TextFieldModel;
  g3bDisabilityGirls: TextFieldModel;
  g3bDisabilityNonBinaryOther: TextFieldModel;

  g4DisabilityBoys: TextFieldModel;
  g4DisabilityGirls: TextFieldModel;
  g4DisabilityNonBinaryOther: TextFieldModel;

  g5DisabilityBoys: TextFieldModel;
  g5DisabilityGirls: TextFieldModel;
  g5DisabilityNonBinaryOther: TextFieldModel;

  behaviouralDisabilityBoys: TextFieldModel;
  behaviouralDisabilityGirls: TextFieldModel;
  behaviouralDisabilityNonBinaryOther: TextFieldModel;

  mentalHealthDisabilityBoys: TextFieldModel;
  mentalHealthDisabilityGirls: TextFieldModel;
  mentalHealthDisabilityNonBinaryOther: TextFieldModel;

  otherDisabilityBoys: TextFieldModel;
  otherDisabilityGirls: TextFieldModel;
  otherDisabilityNonBinaryOther: TextFieldModel;

  noneDisabilityBoys: TextFieldModel;
  noneDisabilityGirls: TextFieldModel;
  noneDisabilityNonBinaryOther: TextFieldModel;

  preferNotToSayDisabilityBoys: TextFieldModel;
  preferNotToSayDisabilityGirls: TextFieldModel;
  preferNotToSayDisabilityNonBinaryOther: TextFieldModel;

  constructor(props: LYGDemographicDataModelProps) {
    const commonProps = {
      required: false,
      initialValue: '0',
      i18n: props.i18n,
    };
    this.asianBoys = new TextFieldModel({
      ...commonProps,
      id: 'asianBoys',
    });

    this.asianGirls = new TextFieldModel({
      ...commonProps,
      id: 'asianGirls',
    });

    this.asianNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'asianNonBinaryOther',
    });

    this.blackBritishBoys = new TextFieldModel({
      ...commonProps,
      id: 'blackBritishBoys',
    });

    this.blackBritishGirls = new TextFieldModel({
      ...commonProps,
      id: 'blackBritishGirls',
    });

    this.blackBritishNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'blackBritishNonBinaryOther',
    });

    this.mixedRaceBoys = new TextFieldModel({
      ...commonProps,
      id: 'mixedRaceBoys',
    });

    this.mixedRaceGirls = new TextFieldModel({
      ...commonProps,
      id: 'mixedRaceGirls',
    });

    this.mixedRaceNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'mixedRaceNonBinaryOther',
    });

    this.whiteBritishBoys = new TextFieldModel({
      ...commonProps,
      id: 'whiteBritishBoys',
    });

    this.whiteBritishGirls = new TextFieldModel({
      ...commonProps,
      id: 'whiteBritishGirls',
    });

    this.whiteBritishNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'whiteBritishNonBinaryOther',
    });

    this.whiteOtherBoys = new TextFieldModel({
      ...commonProps,
      id: 'whiteOtherBoys',
    });

    this.whiteOtherGirls = new TextFieldModel({
      ...commonProps,
      id: 'whiteOtherGirls',
    });

    this.whiteOtherNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'whiteOtherNonBinaryOther',
    });

    this.otherEthnicityBoys = new TextFieldModel({
      ...commonProps,
      id: 'otherEthnicityBoys',
    });

    this.otherEthnicityGirls = new TextFieldModel({
      ...commonProps,
      id: 'otherEthnicityGirls',
    });

    this.otherEthnicityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'otherEthnicityNonBinaryOther',
    });

    this.preferNotToSayEthnicityBoys = new TextFieldModel({
      ...commonProps,
      id: 'preferNotToSayBoys',
    });

    this.preferNotToSayEthnicityGirls = new TextFieldModel({
      ...commonProps,
      id: 'preferNotToSayGirls',
    });

    this.preferNotToSayEthnicityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'preferNotToSayNonBinaryOther',
    });

    this.g1DisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'g1DisabilityBoys',
    });

    this.g1DisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'g1DisabilityGirls',
    });

    this.g1DisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'g1DisabilityNonBinaryOther',
    });

    this.g2DisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'g2DisabilityBoys',
    });

    this.g2DisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'g2DisabilityGirls',
    });

    this.g2DisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'g2DisabilityNonBinaryOther',
    });

    this.g3aDisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'g3aDisabilityBoys',
    });

    this.g3aDisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'g3aDisabilityGirls',
    });

    this.g3aDisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'g3aDisabilityNonBinaryOther',
    });

    this.g3bDisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'g3bDisabilityBoys',
    });

    this.g3bDisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'g3bDisabilityGirls',
    });

    this.g3bDisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'g3bDisabilityNonBinaryOther',
    });

    this.g4DisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'g4DisabilityBoys',
    });

    this.g4DisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'g4DisabilityGirls',
    });

    this.g4DisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'g4DisabilityNonBinaryOther',
    });

    this.g5DisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'g5DisabilityBoys',
    });

    this.g5DisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'g5DisabilityGirls',
    });

    this.g5DisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'g5DisabilityNonBinaryOther',
    });

    this.behaviouralDisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'behaviouralDisabilityBoys',
    });

    this.behaviouralDisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'behaviouralDisabilityGirls',
    });

    this.behaviouralDisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'behaviouralDisabilityNonBinaryOther',
    });

    this.mentalHealthDisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'mentalHealthDisabilityBoys',
    });

    this.mentalHealthDisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'mentalHealthDisabilityGirls',
    });

    this.mentalHealthDisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'mentalHealthDisabilityNonBinaryOther',
    });

    this.noneDisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'noneDisabilityBoys',
    });

    this.noneDisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'noneDisabilityGirls',
    });

    this.noneDisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'noneDisabilityNonBinaryOther',
    });

    this.otherDisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'otherDisabilityBoys',
    });

    this.otherDisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'otherDisabilityGirls',
    });

    this.otherDisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'otherDisabilityNonBinaryOther',
    });

    this.preferNotToSayDisabilityBoys = new TextFieldModel({
      ...commonProps,
      id: 'preferNotToSayDisabilityBoys',
    });

    this.preferNotToSayDisabilityGirls = new TextFieldModel({
      ...commonProps,
      id: 'preferNotToSayDisabilityGirls',
    });

    this.preferNotToSayDisabilityNonBinaryOther = new TextFieldModel({
      ...commonProps,
      id: 'preferNotToSayDisabilityNonBinaryOther',
    });

    makeObservable(this, {
      data: computed,
    });
  }

  get data(): DemographicFormData {
    return {
      asianBoys: this.asianBoys.value,
      asianGirls: this.asianGirls.value,
      asianNonBinaryOther: this.asianNonBinaryOther.value,

      blackBritishBoys: this.blackBritishBoys.value,
      blackBritishGirls: this.blackBritishGirls.value,
      blackBritishNonBinaryOther: this.blackBritishNonBinaryOther.value,

      mixedRaceBoys: this.mixedRaceBoys.value,
      mixedRaceGirls: this.mixedRaceGirls.value,
      mixedRaceNonBinaryOther: this.mixedRaceNonBinaryOther.value,

      whiteBritishBoys: this.whiteBritishBoys.value,
      whiteBritishGirls: this.whiteBritishGirls.value,
      whiteBritishNonBinaryOther: this.whiteBritishNonBinaryOther.value,

      whiteOtherBoys: this.whiteOtherBoys.value,
      whiteOtherGirls: this.whiteOtherGirls.value,
      whiteOtherNonBinaryOther: this.whiteOtherNonBinaryOther.value,

      otherEthnicityBoys: this.otherEthnicityBoys.value,
      otherEthnicityGirls: this.otherEthnicityGirls.value,
      otherEthnicityNonBinaryOther: this.otherEthnicityNonBinaryOther.value,

      preferNotToSayEthnicityBoys: this.preferNotToSayEthnicityBoys.value,
      preferNotToSayEthnicityGirls: this.preferNotToSayEthnicityGirls.value,
      preferNotToSayEthnicityNonBinaryOther: this.preferNotToSayEthnicityNonBinaryOther.value,

      g1DisabilityBoys: this.g1DisabilityBoys.value,
      g1DisabilityGirls: this.g1DisabilityGirls.value,
      g1DisabilityNonBinaryOther: this.g1DisabilityNonBinaryOther.value,

      g2DisabilityBoys: this.g2DisabilityBoys.value,
      g2DisabilityGirls: this.g2DisabilityGirls.value,
      g2DisabilityNonBinaryOther: this.g2DisabilityNonBinaryOther.value,

      g3aDisabilityBoys: this.g3aDisabilityBoys.value,
      g3aDisabilityGirls: this.g3aDisabilityGirls.value,
      g3aDisabilityNonBinaryOther: this.g3aDisabilityNonBinaryOther.value,

      g3bDisabilityBoys: this.g3bDisabilityBoys.value,
      g3bDisabilityGirls: this.g3bDisabilityGirls.value,
      g3bDisabilityNonBinaryOther: this.g3bDisabilityNonBinaryOther.value,

      g4DisabilityBoys: this.g4DisabilityBoys.value,
      g4DisabilityGirls: this.g4DisabilityGirls.value,
      g4DisabilityNonBinaryOther: this.g4DisabilityNonBinaryOther.value,

      g5DisabilityBoys: this.g5DisabilityBoys.value,
      g5DisabilityGirls: this.g5DisabilityGirls.value,
      g5DisabilityNonBinaryOther: this.g5DisabilityNonBinaryOther.value,

      behaviouralDisabilityBoys: this.behaviouralDisabilityBoys.value,
      behaviouralDisabilityGirls: this.behaviouralDisabilityGirls.value,
      behaviouralDisabilityNonBinaryOther: this.behaviouralDisabilityNonBinaryOther.value,

      mentalHealthDisabilityBoys: this.mentalHealthDisabilityBoys.value,
      mentalHealthDisabilityGirls: this.mentalHealthDisabilityGirls.value,
      mentalHealthDisabilityNonBinaryOther: this.mentalHealthDisabilityNonBinaryOther.value,

      otherDisabilityBoys: this.otherDisabilityBoys.value,
      otherDisabilityGirls: this.otherDisabilityGirls.value,
      otherDisabilityNonBinaryOther: this.otherDisabilityNonBinaryOther.value,

      noneDisabilityBoys: this.noneDisabilityBoys.value,
      noneDisabilityGirls: this.noneDisabilityGirls.value,
      noneDisabilityNonBinaryOther: this.noneDisabilityNonBinaryOther.value,

      preferNotToSayDisabilityBoys: this.preferNotToSayDisabilityBoys.value,
      preferNotToSayDisabilityGirls: this.preferNotToSayDisabilityGirls.value,
      preferNotToSayDisabilityNonBinaryOther: this.preferNotToSayDisabilityNonBinaryOther.value,
    };
  }

  setDefaultValues(demographicData: DemographicData) {
    this.asianBoys.setValue(demographicData.asianBoys.toString());
    this.asianGirls.setValue(demographicData.asianGirls.toString());
    this.asianNonBinaryOther.setValue(demographicData.asianNonBinaryOther.toString());
    this.blackBritishBoys.setValue(demographicData.blackBritishBoys.toString());
    this.blackBritishGirls.setValue(demographicData.blackBritishGirls.toString());
    this.blackBritishNonBinaryOther.setValue(demographicData.blackBritishNonBinaryOther.toString());
    this.mixedRaceBoys.setValue(demographicData.mixedRaceBoys.toString());
    this.mixedRaceGirls.setValue(demographicData.mixedRaceGirls.toString());
    this.mixedRaceNonBinaryOther.setValue(demographicData.mixedRaceNonBinaryOther.toString());
    this.whiteBritishBoys.setValue(demographicData.whiteBritishBoys.toString());
    this.whiteBritishGirls.setValue(demographicData.whiteBritishGirls.toString());
    this.whiteBritishNonBinaryOther.setValue(demographicData.whiteBritishNonBinaryOther.toString());
    this.whiteOtherBoys.setValue(demographicData.whiteOtherBoys.toString());
    this.whiteOtherGirls.setValue(demographicData.whiteOtherGirls.toString());
    this.whiteOtherNonBinaryOther.setValue(demographicData.whiteOtherNonBinaryOther.toString());
    this.otherEthnicityBoys.setValue(demographicData.otherEthnicityBoys.toString());
    this.otherEthnicityGirls.setValue(demographicData.otherEthnicityGirls.toString());
    this.otherEthnicityNonBinaryOther.setValue(demographicData.otherEthnicityNonBinaryOther.toString());
    this.preferNotToSayEthnicityBoys.setValue(demographicData.preferNotToSayEthnicityBoys.toString());
    this.preferNotToSayEthnicityGirls.setValue(demographicData.preferNotToSayEthnicityGirls.toString());
    this.preferNotToSayEthnicityNonBinaryOther.setValue(
      demographicData.preferNotToSayEthnicityNonBinaryOther.toString(),
    );
    this.g1DisabilityBoys.setValue(demographicData.g1DisabilityBoys.toString());
    this.g1DisabilityGirls.setValue(demographicData.g1DisabilityGirls.toString());
    this.g1DisabilityNonBinaryOther.setValue(demographicData.g1DisabilityNonBinaryOther.toString());
    this.g2DisabilityBoys.setValue(demographicData.g2DisabilityBoys.toString());
    this.g2DisabilityGirls.setValue(demographicData.g2DisabilityGirls.toString());
    this.g2DisabilityNonBinaryOther.setValue(demographicData.g2DisabilityNonBinaryOther.toString());
    this.g3aDisabilityBoys.setValue(demographicData.g3aDisabilityBoys.toString());
    this.g3aDisabilityGirls.setValue(demographicData.g3aDisabilityGirls.toString());
    this.g3aDisabilityNonBinaryOther.setValue(demographicData.g3aDisabilityNonBinaryOther.toString());
    this.g3bDisabilityBoys.setValue(demographicData.g3bDisabilityBoys.toString());
    this.g3bDisabilityGirls.setValue(demographicData.g3bDisabilityGirls.toString());
    this.g3bDisabilityNonBinaryOther.setValue(demographicData.g3bDisabilityNonBinaryOther.toString());
    this.g4DisabilityBoys.setValue(demographicData.g4DisabilityBoys.toString());
    this.g4DisabilityGirls.setValue(demographicData.g4DisabilityGirls.toString());
    this.g4DisabilityNonBinaryOther.setValue(demographicData.g4DisabilityNonBinaryOther.toString());
    this.g5DisabilityBoys.setValue(demographicData.g5DisabilityBoys.toString());
    this.g5DisabilityGirls.setValue(demographicData.g5DisabilityGirls.toString());
    this.g5DisabilityNonBinaryOther.setValue(demographicData.g5DisabilityNonBinaryOther.toString());
    this.behaviouralDisabilityBoys.setValue(demographicData.behaviouralDisabilityBoys.toString());
    this.behaviouralDisabilityGirls.setValue(demographicData.behaviouralDisabilityGirls.toString());
    this.behaviouralDisabilityNonBinaryOther.setValue(demographicData.behaviouralDisabilityNonBinaryOther.toString());
    this.mentalHealthDisabilityBoys.setValue(demographicData.mentalHealthDisabilityBoys.toString());
    this.mentalHealthDisabilityGirls.setValue(demographicData.mentalHealthDisabilityGirls.toString());
    this.mentalHealthDisabilityNonBinaryOther.setValue(demographicData.mentalHealthDisabilityNonBinaryOther.toString());
    this.otherDisabilityBoys.setValue(demographicData.otherDisabilityBoys.toString());
    this.otherDisabilityGirls.setValue(demographicData.otherDisabilityGirls.toString());
    this.otherDisabilityNonBinaryOther.setValue(demographicData.otherDisabilityNonBinaryOther.toString());
    this.noneDisabilityBoys.setValue(demographicData.noneDisabilityBoys.toString());
    this.noneDisabilityGirls.setValue(demographicData.noneDisabilityGirls.toString());
    this.noneDisabilityNonBinaryOther.setValue(demographicData.noneDisabilityNonBinaryOther.toString());
    this.preferNotToSayDisabilityBoys.setValue(demographicData.preferNotToSayDisabilityBoys.toString());
    this.preferNotToSayDisabilityGirls.setValue(demographicData.preferNotToSayDisabilityGirls.toString());
    this.preferNotToSayDisabilityNonBinaryOther.setValue(
      demographicData.preferNotToSayDisabilityNonBinaryOther.toString(),
    );
  }
}
