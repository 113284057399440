import { observable, action, computed, makeObservable, reaction } from 'mobx';
import { I18nModel } from 'src/context/i18n/I18nModel';
import { getNonRefValue } from 'src/shared/utils/common';

export interface InputFieldValidator<T> {
  message: string | JSX.Element;
  /**
   * The function used to determine fi the field value is valid.
   *
   * @returns TRUE if valid, FALSE if invalid
   */
  validate(value: T): boolean;
}

export interface InputFieldModelProps<T> {
  disabled?: boolean;
  /**
   * If TRUE, the asterisk is not shown when the field is required.
   */
  hideAsterisk?: boolean;
  i18n: I18nModel;
  initialValue: T;
  /**
   * The value of the "name" property of the field. This should be unique.
   */
  id: string;
  /**
   * Only displayed if the title is not specified.
   */
  placeholder?: string;
  required?: boolean;
  /*
   * Select the current value when focus
   */
  selectOnFocus?: boolean;
  /**
   * The label for input field.
   */
  title?: string;
  validators?: InputFieldValidator<T>[];
}

/**
 * The blueprint for all input fields. Provides a set of common properties and methods for managing state for any type
 * of field that accepts/requires user input.
 */
export abstract class InputFieldModel<T, U extends InputFieldModelProps<T> = InputFieldModelProps<T>> {
  defaultValue: T;
  disabled: boolean;
  required: boolean;
  errorIsVisible = false;
  selectOnFocus: boolean;
  title: string;
  value: T;

  protected props: U;

  constructor(props: U) {
    this.props = props;
    this.defaultValue = props.initialValue;
    this.required = props.required || false;
    this.disabled = props.disabled || false;
    this.title = props.title || '';
    this.selectOnFocus = Boolean(props.selectOnFocus);
    this.value = getNonRefValue(props.initialValue);

    makeObservable(this, {
      disabled: observable,
      errorIsVisible: observable,
      title: observable,
      value: observable,
      required: observable,
      errorMessage: computed,
      hasChanged: computed,
      hasError: computed,
      makeErrorVisible: action.bound,
      reset: action.bound,
      setDefaultValue: action.bound,
      setDisabled: action.bound,
      setTitle: action.bound,
      setValue: action.bound,
    });

    // This reaction ensures errors are no longer shown when there are none to show.
    reaction(
      () => this.errorMessage,
      () => {
        if (!this.errorMessage) {
          this.errorIsVisible = false;
        }
      },
    );
  }

  get hideAsterisk(): boolean {
    return this.props.hideAsterisk || false;
  }

  get id(): string {
    return this.props.id;
  }

  get placeholder(): string {
    return this.props.placeholder || '';
  }

  get errorMessage(): string | JSX.Element | null {
    if (this.required && !this.requiredIsValid) {
      return this.props.i18n.t('shared.errors.fieldCantBeBlank');
    }

    /**
     * Checking if required prevents validating empty fields that are not required.
     * Checking if changed prevents validating fields on edit forms that are not empty but don't require validation
     * until changed.
     */
    if ((this.required || this.hasChanged) && this.props.validators) {
      for (let index = 0; index < this.props.validators.length; index++) {
        const validator = this.props.validators[index];
        if (!validator.validate(this.value)) {
          return validator.message;
        }
      }
    }

    return null;
  }

  get hasError(): boolean {
    return this.errorMessage !== null;
  }

  /**
   * Whether the value has changed or not.
   */
  abstract get hasChanged(): boolean;

  /**
   * The logic used to validate required fields. Return TRUE if valid, FALSE otherwise.
   */
  protected abstract get requiredIsValid(): boolean;

  /**
   * If the field has an error, it will be made visible.
   */
  makeErrorVisible(): void {
    if (this.hasError) {
      this.errorIsVisible = true;
    }
  }

  /**
   * Reset the field to the initial state.
   */
  reset(): void {
    this.errorIsVisible = false;
    this.value = getNonRefValue(this.props.initialValue);
  }

  resetToDefault(): void {
    this.errorIsVisible = false;
    this.value = getNonRefValue(this.defaultValue);
  }

  /**
   * Method intended to be used in edit forms where the fields would usually contain a value.
   */
  setDefaultValue(defaultValue: T): void {
    this.defaultValue = getNonRefValue(defaultValue);
    this.value = getNonRefValue(defaultValue);
  }

  setDisabled(disabled: boolean): void {
    this.disabled = disabled;
  }

  setTitle(title: string): void {
    this.title = title;
  }

  setValue(value: T): void {
    this.value = getNonRefValue(value);
  }

  setRequired(required: boolean): void {
    this.required = required;
  }
}
