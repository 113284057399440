import React from 'react';
import { observer } from 'mobx-react-lite';
import { LYGSchoolEntryDemographicDataModel } from 'src/lyg/pages/entries/shared/LYGSchoolEntryDemographicDataModel';
import { TextField } from 'src/shared/ui/inputs/text/TextField';
import { FormFieldWrapper } from 'src/shared/ui/inputs/FormFieldWrapper';
import { CardTitle } from 'src/shared/ui/widgets/Card';

interface LYGSchoolEntryDemographicDataProps {
  model: LYGSchoolEntryDemographicDataModel;
}
export const LYGSchoolEntryDemographicData: React.FC<LYGSchoolEntryDemographicDataProps> = observer(({ model }) => {
  return (
    <div>
      <CardTitle>Ethnicity</CardTitle>
      <div className='row'>
        <div className='col-7 offset-5 d-flex mb-2'>
          <div className='col-3 offset-1'>Boys</div>
          <div className='col-3 offset-1'>Girls</div>
          <div className='col-3 offset-1'>Non-Binary / Other</div>
        </div>
      </div>

      <div className='row'>
        <div className='col-5'>
          <div>Asian / Asian British</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.asianBoys}>
              <TextField model={model.asianBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.asianGirls}>
              <TextField model={model.asianGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.asianNonBinaryOther}>
              <TextField model={model.asianNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>Black/Black British</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.blackBritishBoys}>
              <TextField model={model.blackBritishBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.blackBritishGirls}>
              <TextField model={model.blackBritishGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.blackBritishNonBinaryOther}>
              <TextField model={model.blackBritishNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>Mixed Race</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.mixedRaceBoys}>
              <TextField model={model.mixedRaceBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.mixedRaceGirls}>
              <TextField model={model.mixedRaceGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.mixedRaceNonBinaryOther}>
              <TextField model={model.mixedRaceNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>White British</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.whiteBritishBoys}>
              <TextField model={model.whiteBritishBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.whiteBritishGirls}>
              <TextField model={model.whiteBritishGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.whiteBritishNonBinaryOther}>
              <TextField model={model.whiteBritishNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>White Other</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.whiteOtherBoys}>
              <TextField model={model.whiteOtherBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.whiteOtherGirls}>
              <TextField model={model.whiteOtherGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.whiteOtherNonBinaryOther}>
              <TextField model={model.whiteOtherNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>Other Ethnicity</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.otherEthnicityBoys}>
              <TextField model={model.otherEthnicityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.otherEthnicityGirls}>
              <TextField model={model.otherEthnicityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.otherEthnicityNonBinaryOther}>
              <TextField model={model.otherEthnicityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>Prefer Not To Say</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.preferNotToSayEthnicityBoys}>
              <TextField model={model.preferNotToSayEthnicityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.preferNotToSayEthnicityGirls}>
              <TextField model={model.preferNotToSayEthnicityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.preferNotToSayEthnicityNonBinaryOther}>
              <TextField model={model.preferNotToSayEthnicityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <CardTitle className='mb-3'>Disability</CardTitle>
      <div className='row'>
        <div className='col-5'>
          <div>G1 - Wheelchair Users</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.g1DisabilityBoys}>
              <TextField model={model.g1DisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g1DisabilityGirls}>
              <TextField model={model.g1DisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g1DisabilityNonBinaryOther}>
              <TextField model={model.g1DisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>G2 - Ambulant</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.g2DisabilityBoys}>
              <TextField model={model.g2DisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g2DisabilityGirls}>
              <TextField model={model.g2DisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g2DisabilityNonBinaryOther}>
              <TextField model={model.g2DisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>G3a - Sensory - Hearing Impairmen</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.g3aDisabilityBoys}>
              <TextField model={model.g3aDisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g3aDisabilityGirls}>
              <TextField model={model.g3aDisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g3aDisabilityNonBinaryOther}>
              <TextField model={model.g3aDisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>G3b - Sensory - Visual Impairment</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.g3bDisabilityBoys}>
              <TextField model={model.g3bDisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g3bDisabilityGirls}>
              <TextField model={model.g3bDisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g3bDisabilityNonBinaryOther}>
              <TextField model={model.g3bDisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>G4 - Severe Learning Disability</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.g4DisabilityBoys}>
              <TextField model={model.g4DisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g4DisabilityGirls}>
              <TextField model={model.g4DisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g4DisabilityNonBinaryOther}>
              <TextField model={model.g4DisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>G5 - Mild Learning Disability</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.g5DisabilityBoys}>
              <TextField model={model.g5DisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g5DisabilityGirls}>
              <TextField model={model.g5DisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.g5DisabilityNonBinaryOther}>
              <TextField model={model.g5DisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>Behavioural</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.behaviouralDisabilityBoys}>
              <TextField model={model.behaviouralDisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.behaviouralDisabilityGirls}>
              <TextField model={model.behaviouralDisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.behaviouralDisabilityNonBinaryOther}>
              <TextField model={model.behaviouralDisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>Mental Health</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.mentalHealthDisabilityBoys}>
              <TextField model={model.mentalHealthDisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.mentalHealthDisabilityGirls}>
              <TextField model={model.mentalHealthDisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.mentalHealthDisabilityNonBinaryOther}>
              <TextField model={model.mentalHealthDisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>Other Disability</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.otherDisabilityBoys}>
              <TextField model={model.otherDisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.otherDisabilityGirls}>
              <TextField model={model.otherDisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.otherDisabilityNonBinaryOther}>
              <TextField model={model.otherDisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>None</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.noneDisabilityBoys}>
              <TextField model={model.noneDisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.noneDisabilityGirls}>
              <TextField model={model.noneDisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.noneDisabilityNonBinaryOther}>
              <TextField model={model.noneDisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <div>Prefer Not To Say</div>
        </div>
        <div className='col-7'>
          <div className='row'>
            <FormFieldWrapper className='col-4' model={model.preferNotToSayDisabilityBoys}>
              <TextField model={model.preferNotToSayDisabilityBoys} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.preferNotToSayDisabilityGirls}>
              <TextField model={model.preferNotToSayDisabilityGirls} />
            </FormFieldWrapper>
            <FormFieldWrapper className='col-4' model={model.preferNotToSayDisabilityNonBinaryOther}>
              <TextField model={model.preferNotToSayDisabilityNonBinaryOther} />
            </FormFieldWrapper>
          </div>
        </div>
      </div>
    </div>
  );
});
