import { StartEndDates } from 'src/shared/utils/dates';

export const PLAYER_QUERY_KEYS = {
  base: ['players'],
  filtered: (filters: AnyObject): unknown[] => [...PLAYER_QUERY_KEYS.base, filters],
  invited: {
    base: (): string[] => [...PLAYER_QUERY_KEYS.base, 'invited'],
    count: (): string[] => [...PLAYER_QUERY_KEYS.invited.base(), 'count'],
    filtered: (filters: AnyObject): unknown[] => [...PLAYER_QUERY_KEYS.invited.base(), filters],
  },
  player: {
    base: (playerId: number): unknown[] => [...PLAYER_QUERY_KEYS.base, playerId],
    sessionsAndReps: (playerId: number, startDate?: Date | null, endDate?: Date | null): unknown[] => [
      ...PLAYER_QUERY_KEYS.player.base(playerId),
      { startDate, endDate },
    ],
    gamePerformance: (playerId: number, startDate?: Date | null, endDate?: Date | null): unknown[] => [
      ...PLAYER_QUERY_KEYS.player.sessionsAndReps(playerId, startDate, endDate),
      'performance',
    ],
    gameResults: (playerId: number, dates: StartEndDates): unknown[] => [
      ...PLAYER_QUERY_KEYS.player.base(playerId),
      dates,
      'gameResults',
    ],
  },
  playersCount: (): string[] => [...PLAYER_QUERY_KEYS.base, 'count'],
} as const;
