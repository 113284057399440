import { apiClient } from 'src/shared/utils/apiClient';

export interface ChunkUploadPart {
  chunkNumber: number;
  etag: string;
}

interface CompleteChunkUploadRequest {
  fileKey: string;
  parts: ChunkUploadPart[];
  token: AccessToken;
  uploadId: string;
}

export async function completeChunkUpload(request: CompleteChunkUploadRequest): Promise<void> {
  return apiClient(`/v1/service/providers/aws/upload`, {
    body: JSON.stringify({
      file_key: request.fileKey,
      parts_list: request.parts.map(part => ({
        ETag: part.etag,
        PartNumber: part.chunkNumber,
      })),
      stage: 'complete',
      upload_id: request.uploadId,
    }),
    method: 'POST',
    token: request.token,
  });
}
