export interface KayakSprintEntry {
  ageCategory: string;
  reserve: boolean;
}

export class KayakSprintEntry {
  public constructor(params: OnlyData<KayakSprintEntry>) {
    Object.assign(this, params);
  }
}
