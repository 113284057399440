import React from 'react';
import { cx } from 'src/shared/utils/common';
import css from './ProgressBar.module.scss';

interface ProgressBarProps {
  className?: string;
  isLoading?: boolean;
  value: number;
  error?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ className, isLoading, value, error }) => {
  return (
    <div className={className}>
      {/* <div className='text-center mb-2 fw-bold'>{value}%</div> */}
      <div className={cx('position-relative overflow-hidden', css.progressBar)}>
        <div
          className={cx(
            'position-absolute',
            css.progressValue,
            isLoading && css.progressLoading,
            value === 100 && css.progressCompleted,
            error && css.progressError,
          )}
          style={{ width: `${value}%` }}
        />
      </div>
    </div>
  );
};
