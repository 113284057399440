import { useQuery, UseQueryResult } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { getTeams, TEAM_SORT_PROPERTY } from 'src/entities/teams/apis/getTeams';
import { Team } from 'src/entities/teams/domain';
import { TEAM_QUERY_KEYS } from 'src/entities/teams/queries/queryKeys';
import { TEAMS_TABLE_SORT_KEY } from 'src/pages/teams/home/ui/TeamsTableViewModel';

interface UseTeamsQueryData {
  teams: Team[];
  totalCount: number;
}
interface UseTeamsQueryInput {
  filters?: {
    name?: string;
    clubId?: number;
    division?: string;
  };
  limit?: number;
  page?: number;
  sortAscending?: boolean;
  sortBy?: TEAMS_TABLE_SORT_KEY;
}

const sortBy: Record<TEAMS_TABLE_SORT_KEY, TEAM_SORT_PROPERTY | undefined> = {
  created: undefined,
  createdBy: TEAM_SORT_PROPERTY.createdBy,
  name: TEAM_SORT_PROPERTY.name,
  club: TEAM_SORT_PROPERTY.club,
  playerCount: TEAM_SORT_PROPERTY.playerCount,
};

export function useTeamsQuery(input?: UseTeamsQueryInput): UseQueryResult<UseTeamsQueryData, Error> {
  const { token } = useAuthContext();

  return useQuery<UseTeamsQueryData, Error>(
    input ? TEAM_QUERY_KEYS.filtered(input) : TEAM_QUERY_KEYS.base,
    async () => {
      const response = await getTeams({ ...input, token, sortBy: sortBy[input?.sortBy || 'name'] });

      return {
        teams: response.data.map(team => ({
          created: new Date(team.created_at),
          createdBy: {
            firstName: team.created_first_name,
            id: team.created_by,
            lastName: team.created_last_name,
          },
          logoUrl: team.team_avatar || undefined,
          code: team.team_code,
          description: team.team_description || undefined,
          id: team.team_id,
          name: team.team_name,
          club: {
            id: team.org_id || NaN,
            name: team.org_name || '',
          },
          playerCount: team.player_count,
          invitedCount: team.player_invite_count,
          tags: JSON.parse(team.team_tags || '[]'),
          division: team.team_division || undefined,
        })),
        totalCount: response.total,
      };
    },
    {
      staleTime: 1000 * 60 * 15,
    },
  );
}
