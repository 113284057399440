export interface JudoEntry {
  licenseNumber: string;
  danGrade: string;
  section: string;
  weightBoysSectionA: string | null;
  weightBoysSectionB: string | null;
  weightBoysSectionC: string | null;
  weightGirlsSectionA: string | null;
  weightGirlsSectionB: string | null;
  weightGirlsSectionC: string | null;
}
export class JudoEntry {
  public constructor(params: OnlyData<JudoEntry>) {
    Object.assign(this, params);
  }
}
