export interface PgAthleticsEntry {
  disabilityCategory: string;
  event1: string;
  event2: string;
  fourTimesOneHundredMetersRelay: boolean;
}

export class PgAthleticsEntry {
  public constructor(params: OnlyData<PgAthleticsEntry>) {
    Object.assign(this, params);
  }
}
