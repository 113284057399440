import { apiClient } from 'src/shared/utils/apiClient';

export enum TEAM_SORT_PROPERTY {
  createdBy = 'created_by',
  name = 'name',
  club = 'club',
  playerCount = 'player_count',
}

interface GetTeamsRequest {
  filters?: {
    name?: string;
    clubId?: number;
    division?: string;
  };
  limit?: number;
  page?: number;
  sortAscending?: boolean;
  sortBy?: TEAM_SORT_PROPERTY;
  token: AccessToken;
}
interface GetTeamsResponse {
  data: {
    created_at: DateTimeString;
    created_by: number;
    created_first_name: string;
    created_last_name: string;
    org_name: string;
    org_id: number;
    player_count: number;
    player_invite_count: number;
    team_avatar: string | null;
    team_code: string;
    team_description: string | null;
    team_id: number;
    team_name: string;
    team_tags: string | null;
    team_division: string | null;
  }[];
  total: number;
}

export function getTeams(request: GetTeamsRequest): Promise<GetTeamsResponse> {
  const filters: string[] = [];

  if (request.filters) {
    if (request.filters.name) filters.push(`team_name=${request.filters.name}`);
    if (request.filters.clubId) filters.push(`org_id=${request.filters.clubId}`);
    if (request.filters.division) filters.push(`division=${request.filters.division}`);
  }
  if (request.limit) filters.push(`limit=${request.limit}`);
  if (request.page) filters.push(`page=${request.page}`);
  if (request.sortAscending !== undefined) filters.push(`sorting=${request.sortAscending ? 'asc' : 'desc'}`);
  if (request.sortBy) filters.push(`sort_name=${request.sortBy}`);

  const queryString = filters.length > 0 ? `?${filters.join('&')}` : '';

  return apiClient(`/v1/teams${queryString}`, { token: request.token });
}
