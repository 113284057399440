import React from 'react';
import { observer } from 'mobx-react-lite';
import { useI18nContext } from 'src/context/i18n';
import { cx } from 'src/shared/utils/common';
import { Search } from 'react-feather';
import css from './DropdownSearchbox.module.scss';

interface DropdownSearchboxProps {
  value: string | undefined;
  onChange: ((value: string) => void) | undefined;
  className?: string;
}

export const DropdownSearchbox: React.FC<DropdownSearchboxProps> = observer(({ onChange, value, className }) => {
  const i18n = useI18nContext();

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  }, []);

  return (
    <div className={cx(css.wrapper, 'd-flex w-100 justify-space-between align-items-center', className)}>
      <input
        className={cx('w-100 ts-text-overflow', css.field)}
        type='text'
        title={i18n.t('shared.actions.search')}
        aria-label={i18n.t('shared.actions.search')}
        placeholder={i18n.t('shared.actions.search')}
        onChange={e => onChange && onChange(e.target.value)}
        value={value}
        ref={inputRef}
      />
      <Search className={css.icon} />
    </div>
  );
});
