/**
 * Get the value without the reference, which is the case for arrays and objects.
 */
export function getNonRefValue<T>(value: T): T;
export function getNonRefValue<T extends Array<unknown> | Date | File | Record<string, unknown>>(value: T): T {
  if (Array.isArray(value)) {
    return [...value] as T;
  }
  if (value instanceof Date) {
    return new Date(value) as T;
  }
  if (value instanceof File) {
    return value;
  }
  if (typeof value === 'object' && value !== null) {
    return { ...value };
  }
  return value;
}

/**
 * Useful to create the impression of heavy processing, especially when testing HTTP requests.
 *
 * @note This function only works in the dev environment.
 */
export async function waitPromise(ms: number): Promise<void> {
  if (import.meta.env.DEV) {
    await new Promise(resolve =>
      setTimeout(() => {
        resolve(null);
      }, ms),
    );
  } else {
    console.warn('WARNING: you should not use the delayPromise() function in production!');
  }
}

interface UserAgentData {
  readonly brands: { readonly brand: string; readonly version: string }[];
  readonly mobile: boolean;
  readonly platform: string;
}

export function deviceIsMobile(): boolean {
  // if ('userAgentData' in navigator) return (navigator.userAgentData as UserAgentData ).mobile;
  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
    navigator.userAgent,
  );
}

export function browserIsSafari(): boolean {
  return 'safari' in window || /apple/i.test(navigator.vendor);
}

/**
 * Delays executing specified function until timeout is reached. Except the execution is further delayed if the
 * function execution is attempted again before timeout is reached.
 */
export function debounce(fn: () => void, timeoutMs: number) {
  let timeout: NodeJS.Timeout;
  return function () {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(fn, timeoutMs);
  };
}

/**
 * Replacement for packages like clsx, classnames, etc.
 */
export function cx(...args: (string | null | undefined | boolean)[]): string {
  let i = 0;
  let result = '';
  while (i < args.length) {
    if (args[i]) {
      result += `${args[i]} `;
    }
    i++;
  }
  return result.trim();
}
