import React from 'react';

interface ElementSizes {
  height: number;
  width: number;
}

export function useOnElementResize<T extends HTMLElement = HTMLDivElement>(
  handler: (sizes: ElementSizes) => void,
): React.RefObject<T> {
  const ref = React.useRef<T>(null);

  React.useEffect(() => {
    function handleResize() {
      handler({
        height: ref.current?.clientHeight || window.innerHeight,
        width: ref.current?.clientWidth || window.innerWidth,
      });
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [ref, handler]);

  return ref;
}
