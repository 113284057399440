export interface LYGCompetitionGame {
  id: number;
  competitionId: number;
  date: Date;
  teamAId: number;
  teamAName: string;
  teamAScore: number;
  teamBId: number;
  teamBName: string;
  teamBScore: number;
  court: number;
  scoreBreakdown: {
    teamAScore: number;
    teamBScore: number;
    period: number;
  }[];
}

type LYGCompetitionGameProps = OnlyData<LYGCompetitionGame>;

export class LYGCompetitionGame {
  static readonly Status = {
    win: 'win',
    lose: 'lose',
    draw: 'draw',
  } as const;

  constructor(init: LYGCompetitionGameProps) {
    Object.assign(this, init);
  }
}

export type LYGCompetitionGameStatus = ValueOf<keyof typeof LYGCompetitionGame.Status>;
export interface LYGCompetitionPoolSchedule {
  id: number;
  name: string;
  games: LYGCompetitionGame[];
}
