import { makeObservable } from 'mobx';
import { I18nModel } from 'src/context/i18n/I18nModel';
import { ParticipantData } from 'src/lyg/entities/entries/schoolGameEntry';
import { LYG_EVENT_TYPE } from 'src/lyg/entities/events';
import { BooleanFieldModel } from 'src/shared/ui/inputs/boolean/BooleanFieldModel';
import { DROPDOWN_BLANK_VALUE, DropdownFieldModel } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';
import { TextFieldModel } from 'src/shared/ui/inputs/text/TextFieldModel';
import { createBoroughPickerDropdown } from 'src/lyg/features/boroughPicker';
import { computed } from 'mobx';

export interface ParticipantFormData {
  howSelectSquad: string;
  totalBoysParticipants: string;
  totalGirlsParticipants: string;
  totalNonBinaryParticipants: string;
  totalOtherParticipants: string;
  totalNoResponseParticipants: string;
}

interface LYGEntryGeneralInfoModelProps {
  i18n: I18nModel;
  eventType: string;
}

export class LYGEntryGeneralInfoModel {
  didRunTrials: BooleanFieldModel;
  howSelectSquad: DropdownFieldModel;
  totalBoysParticipants: TextFieldModel;
  totalGirlsParticipants: TextFieldModel;
  totalNonBinaryParticipants: TextFieldModel;
  totalOtherParticipants: TextFieldModel;
  totalNoResponseParticipants: TextFieldModel;
  boroughPicker: DropdownFieldModel;

  constructor(props: LYGEntryGeneralInfoModelProps) {
    this.didRunTrials = new BooleanFieldModel({
      i18n: props.i18n,
      id: 'trials',
      title:
        props.eventType === LYG_EVENT_TYPE.openGames
          ? 'Did you run trials to select your squad?'
          : 'Do you have your participant data?',
      initialValue: false,
    });
    this.howSelectSquad = new DropdownFieldModel({
      i18n: props.i18n,
      id: 'how-select-squad',
      title: 'How did you select your squad?',
      options: [
        { id: 'Trials', label: 'Trials' },
        { id: 'Single School', label: 'Single School' },
        { id: 'Multiple Schools', label: 'Multiple Schools' },
        { id: 'Club', label: 'Club' },
        { id: 'Multiple Clubs', label: 'Multiple Clubs' },
        { id: 'School & Club', label: 'School & Club' },
        { id: 'Community Group', label: 'Community Group' },
        { id: 'None of the above/other', label: 'None of the above/other' },
      ],
      initialValue: DROPDOWN_BLANK_VALUE,
      required: true,
    });
    this.totalBoysParticipants = new TextFieldModel({
      i18n: props.i18n,
      id: 'total-boys-participants',
      initialValue: '0',
      title: 'Boys',
    });
    this.totalGirlsParticipants = new TextFieldModel({
      i18n: props.i18n,
      id: 'total-girls-participants',
      initialValue: '0',
      title: 'Girls',
    });
    this.totalNonBinaryParticipants = new TextFieldModel({
      i18n: props.i18n,
      id: 'total-non-binary-participants',
      initialValue: '0',
      title: 'Non Binary',
    });
    this.totalOtherParticipants = new TextFieldModel({
      i18n: props.i18n,
      id: 'total-other-participants',
      initialValue: '0',
      title: 'Other',
    });
    this.totalNoResponseParticipants = new TextFieldModel({
      i18n: props.i18n,
      id: 'total-no-response-participants',
      initialValue: '0',
      title: 'Prefer not to say',
    });
    this.boroughPicker = createBoroughPickerDropdown({ i18n: props.i18n, id: 'borough-picker', required: true });

    makeObservable(this, { hasError: computed });
  }

  get hasError(): boolean {
    return (
      this.boroughPicker.hasError ||
      this.howSelectSquad.hasError ||
      !(
        // We need at least one of these fields to be filled
        (
          Number(this.totalBoysParticipants.value) ||
          Number(this.totalGirlsParticipants.value) ||
          Number(this.totalNonBinaryParticipants.value) ||
          Number(this.totalOtherParticipants.value) ||
          Number(this.totalNoResponseParticipants.value)
        )
      )
    );
  }

  get data(): ParticipantFormData {
    return {
      howSelectSquad: this.howSelectSquad.value.id,
      totalBoysParticipants: this.totalBoysParticipants.value,
      totalGirlsParticipants: this.totalGirlsParticipants.value,
      totalNonBinaryParticipants: this.totalNonBinaryParticipants.value,
      totalOtherParticipants: this.totalOtherParticipants.value,
      totalNoResponseParticipants: this.totalNoResponseParticipants.value,
    };
  }

  setDefaultValues(participantData: ParticipantData | null, boroughId: number | null) {
    this.didRunTrials.setValue(false);
    if (participantData) {
      this.howSelectSquad.setDefaultValueById(participantData.howSelectSquad);
      this.totalBoysParticipants.setValue(participantData.totalBoysParticipants.toString());
      this.totalGirlsParticipants.setValue(participantData.totalGirlsParticipants.toString());
      this.totalNonBinaryParticipants.setValue(participantData.totalNonBinaryParticipants.toString());
      this.totalOtherParticipants.setValue(participantData.totalOtherParticipants.toString());
      this.totalNoResponseParticipants.setValue(participantData.totalNoResponseParticipants.toString());
    }
    if (boroughId) {
      this.boroughPicker.setDefaultValueById(boroughId.toString());
      this.boroughPicker.disabled = true;
    }
  }
}
