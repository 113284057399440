import { useContext } from 'react';
import { PusherContext, PusherContextValue } from 'src/features/pusher/PusherContext';
import { browserIsSafari } from 'src/shared/utils/common';

export const usePusher = (): PusherContextValue => {
  const ctx = useContext(PusherContext);

  if (!ctx && !('PushManager' in window) && !browserIsSafari()) {
    return {
      onClear: async () => {
        null;
      },
      onInit: () => new Promise(() => null),
    };
  }

  if (!ctx) {
    throw new Error('usePusher can only be used inside PusherProvider');
  }

  return ctx;
};
