import React from 'react';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { useI18nContext } from 'src/context/i18n';
import { Card } from 'src/shared/ui/widgets/Card';
import { DataTableDataItem } from 'src/shared/ui/data/types';
import { DataTableColumnHeader } from 'src/shared/ui/data/DataTableColumnHeader';
import { DataTableModel } from 'src/shared/ui/data/DataTableModel';
import { useOnElementResize } from 'src/shared/hooks/useOnElementResize';
import css from './DataTable.module.scss';

interface DataTableProps<T extends DataTableDataItem> {
  data: T[];
  model: DataTableModel<T>;
  idPropName?: keyof T;
  cardClassName?: string;
  tableRowClassName?: string;
  wrapperClassName?: string;
  headerClassName?: string;
  stripped?: boolean;
}

export const DataTable = observer(
  <T extends DataTableDataItem>({
    data,
    model,
    cardClassName,
    tableRowClassName,
    wrapperClassName,
    headerClassName,
    stripped,
    idPropName = 'id',
  }: DataTableProps<T>): JSX.Element => {
    const i18n = useI18nContext();
    const { columns, showColumnHeaders } = model;
    const tableWrapperRef = useOnElementResize(({ width }) => {
      model.setWidth(width);
    });

    return (
      <div ref={tableWrapperRef} className={wrapperClassName}>
        {showColumnHeaders && (
          <div
            className={cx(
              'd-flex align-items-center justify-content-between ts-fs-13',
              css.tableHeaderRow,
              headerClassName,
              tableRowClassName,
            )}
          >
            {columns.map(column => (
              <DataTableColumnHeader model={column} key={column.props.key} />
            ))}
          </div>
        )}

        <Card className={cx(css.tableCard, cardClassName)}>
          {data.length > 0 ? (
            data.map((dataItem, index) => (
              <div key={dataItem[idPropName]} className={cx(tableRowClassName, css.tableRow)}>
                <div className={cx('d-flex align-items-center justify-content-between')}>
                  {columns.map((column, cIdx) => {
                    if (
                      Number(column.props.overflowBreakpoint) > model.width ||
                      (column.props.hideFromTable && column.props.hideFromTable(model))
                    ) {
                      return null;
                    }

                    return (
                      <div
                        className={cx(
                          'text-break',
                          css.tableCell,
                          column.props.className,
                          stripped && cIdx % 2 !== 0 && css.stripped,
                        )}
                        key={column.props.key}
                        style={{ width: column.props.width }}
                      >
                        {column.props.dataCell({ dataItem, table: model, index })}
                      </div>
                    );
                  })}
                </div>

                {model.extraRowDataCell && model.extraRowDataCell({ dataItem, table: model, index }) && (
                  <div className={cx(css.tableRow, 'pb-0')}>
                    {model.extraRowDataCell({ dataItem, table: model, index })}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className='py-5 text-center'>{model.props.noDataMessage || i18n.t('shared.phrases.noData')}</p>
          )}
        </Card>
      </div>
    );
  },
);
