import React from 'react';
import { CSS_BREAKPOINTS } from 'src/constants';
import { useOnWindowResize } from 'src/shared/hooks/useOnWindowResize';

interface SidebarVisibility {
  hidden: boolean;
  isTopbarDisplay: boolean;
  toggleVisibility(): void;
  setIsTopbarDisplay(value: boolean): void;
}

const SidebarContext = React.createContext<SidebarVisibility | null>(null);

export const SidebarContextProvider: React.FC = ({ children }) => {
  const [hidden, toggleVisibility] = React.useReducer(previousState => !previousState, true);
  const [isTopbarDisplay, setIsTopbarDisplay] = React.useState(false);

  useOnWindowResize(({ width }) => {
    if (width > CSS_BREAKPOINTS.lg && !hidden) {
      toggleVisibility();
      document.body.classList.remove('overflow-hidden');
    }
  });

  React.useEffect(() => {
    if (window.innerWidth < CSS_BREAKPOINTS.lg) {
      if (hidden) {
        document.body.classList.remove('overflow-hidden');
      } else {
        document.body.classList.add('overflow-hidden');
      }
    }
  }, [hidden]);

  return (
    <SidebarContext.Provider value={{ hidden, toggleVisibility, isTopbarDisplay: isTopbarDisplay, setIsTopbarDisplay }}>
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebarVisibilityContext(): SidebarVisibility {
  const context = React.useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext() cannot be used outside SidebarContextProvider');
  }

  return context;
}
