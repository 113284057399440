import { useMutation, useQueryClient } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { dismissNotification } from 'src/entities/notifications/api/dismissNotification';
import { NOTIFICATIONS_QUERY_KEYS } from 'src/entities/notifications/query/queryKeys';

interface QueryResult {
  error: Error | null;
  isLoading: boolean;
  execute(): void;
}

export function useDismissNotificationQuery(notificationId: number): QueryResult {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const mutation = useMutation<void, Error, void>(() => dismissNotification(notificationId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEYS.base);
    },
  });

  return {
    error: mutation.error,
    isLoading: mutation.isLoading,
    execute: mutation.mutate,
  };
}
