import React from 'react';

export default function Swap() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none' viewBox='0 0 15 15'>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeOpacity='.75'
        d='M10.644 3.877a1.557 1.557 0 1 0 0-3.113 1.557 1.557 0 0 0 0 3.113Zm2.694 3.173a2.683 2.683 0 0 0-2.694-2.694 2.683 2.683 0 0 0-2.695 2.695m-3.592 4.012a1.557 1.557 0 1 0 0-3.114 1.557 1.557 0 0 0 0 3.114Zm2.693 3.173a2.683 2.683 0 0 0-2.694-2.694 2.683 2.683 0 0 0-2.694 2.694'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeOpacity='.75'
        strokeWidth='.63'
        d='M10.823 8.668v5.09M9.026 11.96l1.797 1.797 1.796-1.796M3.938 6.273v-5.09M2.142 2.98l1.796-1.796L5.734 2.98'
      />
    </svg>
  );
}
