import { apiClient } from 'src/shared/utils/apiClient';

interface GetAWSUrlForChunkUploadRequest {
  chunkNumber: number;
  fileKey: string;
  token: AccessToken;
  uploadId: string;
}

interface GetAWSUrlForChunkUploadResponse {
  presigned_url: string;
}

export async function getAWSUrlForChunkUpload(
  request: GetAWSUrlForChunkUploadRequest,
): Promise<GetAWSUrlForChunkUploadResponse> {
  const queryString = [
    `file_key=${request.fileKey}`,
    `part_number=${request.chunkNumber}`,
    'stage=upload',
    `upload_id=${request.uploadId}`,
  ].join('&');

  return apiClient(`/v1/service/providers/aws/upload?${queryString}`, {
    method: 'POST',
    token: request.token,
  });
}
