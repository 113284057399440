import React from 'react';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { TimePickerFieldModel } from 'src/shared/ui/inputs/datetime/TimePickerFieldModel';
import css from './TimePickerField.module.scss';

interface TimePickerFieldProps {
  model: TimePickerFieldModel;
}

export const TimePickerField: React.FC<TimePickerFieldProps> = observer(({ model }) => {
  const { id, required, errorIsVisible, title, value, makeErrorVisible, setValue } = model;

  return (
    <input
      aria-label={title}
      className={cx('w-100', css.field, errorIsVisible && css.hasErrors)}
      name={id}
      required={required}
      title={title}
      type='time'
      value={value}
      onBlur={makeErrorVisible}
      onChange={event => setValue(event.target.value)}
    />
  );
});
