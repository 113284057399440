import React from 'react';
import { CSS_BREAKPOINTS } from 'src/constants';
import { useOnWindowResize } from 'src/shared/hooks/useOnWindowResize';

export function useIsMobile(breakpoint?: number) {
  const [isMobile, setIsMobile] = React.useState(false);
  useOnWindowResize(({ width }) => setIsMobile(width < (breakpoint || CSS_BREAKPOINTS.md)));

  return isMobile;
}
