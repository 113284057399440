export interface LYGTeam {
  id: number;
  name: string;
  playersCount: number;
}

type LYGTeamProps = OnlyData<LYGTeam>;

export class LYGTeam {
  constructor(init: LYGTeamProps) {
    Object.assign(this, init);
  }
}
