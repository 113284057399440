import { apiClient } from 'src/shared/utils/apiClient';

interface GetNotificationsRequest {
  page: number;
}

interface GetPlayerResponse {
  code: number;
  error: boolean;
  message: string;
  response: {
    current_page: number;
    data: {
      created_at: string;
      deleted_at: string | null;
      from_user_id: number | null;
      general_interest: string | null;
      notification_body: string;
      notification_data: string;
      notification_id: number;
      notification_title: string;
      notification_type: string | null;
      notification_type_id: number | null;
      profile_avatar: string | null;
      profile_first_name: string;
      profile_last_name: string;
      org_id: number | null;
      pusher_notification_id: string;
      read_at: string | null;
      team_id: number;
      updated_at: string | null;
      user_id: number;
    }[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: { active: boolean; label: string; url: null | string }[];
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
  };
}

export async function getNotifications(data: GetNotificationsRequest, token: AccessToken): Promise<GetPlayerResponse> {
  const queryParam = data.page ? `?page=${data.page}` : '';
  return apiClient(`/v1/notifications${queryParam}`, { token });
}
