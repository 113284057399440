import React from 'react';

export const SortIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={14} height={14} fill='none' {...props}>
      <path
        d='M10.267 7.467v5.6M13.067 10.267l-2.8 2.8-2.8-2.8'
        stroke='#c4c4c4'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.2 7.194V1.205M1.205 4.2L4.2 1.205 7.194 4.2'
        stroke='#c4c4c4'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
