import React from 'react';
import { useI18nContext } from 'src/context/i18n';
import { LYG_SUBDOMAIN } from 'src/context/routes';

/**
 * Update the browser tab title.
 */
export function useBrowserTabTitle(title: string): void {
  const i18n = useI18nContext();
  const isLYG = window.location.hostname.startsWith(import.meta.env.PROD ? LYG_SUBDOMAIN : 'test');

  React.useEffect(() => {
    document.title = `${title} | ${isLYG ? 'London Youth Games' : i18n.t('shared.phrases.appName')}`;
  }, [title, i18n, isLYG]);
}
