import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAuthContext } from 'src/context/authContext';

interface CurrentUserIsProps {
  admin?: boolean;
  coach?: boolean;
  headCoach?: boolean;
  elseRender?: JSX.Element | null;
}

export const RenderIfCurrentUserIs: React.FC<RequireMinOne<CurrentUserIsProps>> = observer(
  ({ children, admin, coach, headCoach, elseRender }) => {
    const { currentUser } = useAuthContext();

    if ((admin && currentUser.isAdmin) || (coach && currentUser.isCoach) || (headCoach && currentUser.isHeadCoach)) {
      return <>{children}</>;
    }

    return elseRender || null;
  },
);
