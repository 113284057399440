export interface RoadCyclingEntry {
  licenseNumber: string;
  timeTrialAgeCategory: string;
  timeTrialCompetitor: boolean;
  roadRaceCompetitor: boolean;
  reserve: boolean;
}

export class RoadCyclingEntry {
  public constructor(params: OnlyData<RoadCyclingEntry>) {
    Object.assign(this, params);
  }
}
