import React from 'react';
import { cx } from 'src/shared/utils/common';
import { useI18nContext } from 'src/context/i18n';
import { useLYGAppRoutesContext } from 'src/context/routes';
import { PageHeader } from 'src/lyg/features/appLayout/ui/PageHeader';
import { Link } from 'react-router-dom';
import css from './4xxPage.module.scss';

export const LYGUnauthorisedPage: React.FC<{ isApiResponse?: boolean }> = ({ isApiResponse }) => {
  const i18n = useI18nContext();
  const routes = useLYGAppRoutesContext();

  return (
    <div>
      {!isApiResponse && <PageHeader />}
      <div className={cx('d-flex flex-column flex-lg-row vh-100 overflow-hidden ts-fade-in-03')}>
        <div className={css.textWrapper}>
          <h1 className='ts-text-light ts-fw-600 ts-fs-75'>{i18n.t('shared.errors.ohNo')}</h1>
          <p className='ts-text-lighter ts-fw-500 ts-fs-16'>{i18n.t('shared.errors.api', { code: '401' })}</p>
          <p className='ts-fs-16 mb-5'>{i18n.t('shared.errors.noPermission')}</p>
          <p className='ts-fs-16'>
            Go back to <Link to={routes.dashboard}>London Youth Games Dashboard</Link>
          </p>
        </div>
        <div className={css.imageWrapper}>
          <img src='/assets/img/4xx/401.png' alt='Not Authorised' />
        </div>
      </div>
    </div>
  );
};
