import { useQueryClient, useMutation } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { LYGCompetition } from 'src/lyg/entities/competitions';
import { LYGCompetitionPoolSchedule } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { LYG_COMPETITIONS_KEYS } from 'src/lyg/pages/competitions/queryKeys';
import { generateSchedule } from 'src/lyg/pages/competitions/schedule/scheduler';
import { apiClient } from 'src/lyg/utils/apiClient';
import { ApiError } from 'src/lyg/utils/apiError';

interface SaveScheduleRequest {
  competitionId: number;
  schedule: LYGCompetitionPoolSchedule[];
  pools: { name: string; teamIds: number[] }[];
}

function saveSchedule(request: SaveScheduleRequest, token: AccessToken): Promise<void> {
  return apiClient(`/v1/lyg/competitions/${request.competitionId}/schedule`, {
    method: 'POST',
    body: JSON.stringify({ schedule: JSON.stringify(request.schedule), pools: JSON.stringify(request.pools) }),
    token,
  });
}

function getSchedule(
  competition: LYGCompetition,
  pools: { name: string; teamIds: number[] }[],
): LYGCompetitionPoolSchedule[] {
  /* TODO: move this to the backend */
  const schedule = generateSchedule({
    breakTime: competition.breakTime,
    dates: competition.dates,
    matchDuration: competition.matchDuration,
    matchupFrequency: competition.matchupFrequency,
    spreadGamesEvenlyBetweenDates: competition.spreadGamesEvenlyBetweenDates,
    numberOfCourts: competition.numberOfCourts,
    pools,
  });
  const poolsSchedules = schedule.map<LYGCompetitionPoolSchedule>(pool => ({
    id: pool.id,
    name: pool.name,
    games: pool.games,
  }));

  return poolsSchedules;
}

interface SetLYGCompetitionScheduleMutationParams {
  competition: LYGCompetition;
  pools: { name: string; teamIds: number[] }[];
}

export function useSetLYGCompetitionScheduleMutation() {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, SetLYGCompetitionScheduleMutationParams>(
    async ({ competition, pools }) => {
      const schedule = getSchedule(competition, pools);
      return saveSchedule({ schedule, competitionId: competition.id, pools }, token);
    },
    {
      onSuccess: (_, { competition }) => {
        queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.schedule(competition.id));
        queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.pools(competition.id));
      },
    },
  );
}

interface SetGameScoreRequest {
  gameId: number;
  competitionId: number;
  scores: { teamAScore: number; teamBScore: number; quarter: number }[];
}

function setGameScore(request: SetGameScoreRequest, token: AccessToken): Promise<void> {
  return apiClient(`/v1/lyg/competitions/${request.competitionId}/schedule/game/${request.gameId}`, {
    method: 'POST',
    body: JSON.stringify({ scores: JSON.stringify(request.scores) }),
    token,
  });
}

export function useSetGameScoreMutation() {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, SetGameScoreRequest>(request => setGameScore(request, token), {
    onSuccess: (_, { competitionId }) => {
      queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.schedule(competitionId));
      queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.leaderboard(competitionId));
      queryClient.invalidateQueries(LYG_COMPETITIONS_KEYS.bracket(competitionId));
    },
  });
}
