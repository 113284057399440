import { ChunkUploadPart, completeChunkUpload } from 'src/entities/videos/apis/upload/completeChunkUpload';
import { startChunkUpload } from 'src/entities/videos/apis/upload/startChunkUpload';
import { uploadChunk } from 'src/entities/videos/apis/upload/uploadChunk';

const CHUNK_SIZE = 1024 * 1024 * 10;
const THROTTLE_LIMIT = 10; // 10 chunks in parallel

// async function throttleRequests(requestsToMake: RequestToMake[], maxParallelRequests = 6) {
//   // queue up simultaneous calls
//   const queue: Promise<void>[] = [];
//   for (const requestToMake of requestsToMake) {
//     // fire the async function, add its promise to the queue,
//     // and remove it from queue when complete
//     const promise = requestToMake().then(res => {
//       queue.splice(queue.indexOf(promise), 1);
//       return res;
//     });
//     queue.push(promise);

//     // if the number of queued requests matches our limit then
//     // wait for one to finish before enqueueing more
//     if (queue.length >= maxParallelRequests) {
//       await Promise.race(queue);
//     }
//   }
//   // wait for the rest of the calls to finish
//   await Promise.all(queue);
// }

interface UploadVideoFileRequest {
  file: File;
  teamId: number;
  token: AccessToken;
  type: string;
  onChunkUploaded(chunkIndex: number, chunkCount: number): void;
  onChunkStarted(args: UploadVideoFileResponse): void;
}

interface UploadVideoFileResponse {
  uploadId: string;
  fileKey: string;
}

export async function uploadVideoFile(request: UploadVideoFileRequest): Promise<UploadVideoFileResponse | null> {
  const startResponse = await startChunkUpload(request);
  request.onChunkStarted({ fileKey: startResponse.file_key, uploadId: startResponse.upload_id });

  let chunkIndex = 1;
  let chunksUploaded = 0;

  const promises: Promise<boolean>[] = [];
  const uploadParts: ChunkUploadPart[] = [];

  for (let start = 0; start < request.file.size; start += CHUNK_SIZE) {
    const end = Math.min(start + CHUNK_SIZE, request.file.size);
    promises.push(
      uploadChunk({
        chunk: request.file.slice(start, end),
        fileKey: startResponse.file_key,
        chunkNumber: chunkIndex,
        token: request.token,
        uploadId: startResponse.upload_id,
        onChunkUploaded: (etag, chunkNumber) => {
          uploadParts.push({ etag, chunkNumber });
          chunksUploaded++;
          request.onChunkUploaded(chunksUploaded, promises.length);
        },
      }),
    );
    chunkIndex++;
  }
  const responses = await Promise.all(promises);
  /* Upload has been canceled  */
  if (responses.some(val => !val)) return null;

  await completeChunkUpload({
    fileKey: startResponse.file_key,
    parts: uploadParts.sort((chunk1, chunk2) => chunk1.chunkNumber - chunk2.chunkNumber),
    token: request.token,
    uploadId: startResponse.upload_id,
  });

  return { fileKey: startResponse.file_key, uploadId: startResponse.upload_id };
}

/**
 * - [ ] Limit parallel requests to a specific number; e.g. 10 req/s
 * - [ ] Once a request in the queue has succeeded, start another one
 * - [ ] If a request fails, retry it 3 times
 */
