import React from 'react';

/**
 * Use to handle clicking outside an HTML element. Attach the ref object to the element that requires this behaviour.
 *
 * @param when Determines when to run the execute function.
 * @param execute The function to execute when clicking outside the element the ref object is attached to.
 * @param nodes Array of DOM nodes to be excluded when clicked, included their childrens. Usefull when using portals
 */
export function useOnClickOutside<T extends HTMLElement = HTMLDivElement>(
  when: boolean,
  execute: (event: MouseEvent | KeyboardEvent) => void,
  nodes?: (HTMLElement | null)[],
): React.RefObject<T> {
  const ref = React.useRef<T>(null);

  React.useEffect(() => {
    function listener(event: MouseEvent | KeyboardEvent) {
      if (Array.isArray(nodes)) {
        const shouldIgnore = nodes.some(node => {
          if (node instanceof HTMLElement) {
            return node.contains(event.target as Node);
          }
          return false;
        });
        if (shouldIgnore) return;
      }

      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      execute(event);
    }

    if (when) {
      window.addEventListener('mousedown', listener);
      window.addEventListener('keyup', listener);
    }

    return () => {
      window.removeEventListener('mousedown', listener);
      window.removeEventListener('keyup', listener);
    };
  }, [ref, when, execute, nodes]);

  return ref;
}
