import React from 'react';

interface IsLeagueContextType {
  isLeague: boolean;
  updateIsLeague: (value: boolean) => void;
}

export const IsLeagueContext = React.createContext<IsLeagueContextType | undefined>(undefined);

export const useIsLeagueContext = () => {
  const context = React.useContext(IsLeagueContext);
  if (context === undefined) {
    throw new Error('useIsLeagueContext must be used within a IsLeagueProvider');
  }
  return context;
};
