import React from 'react';
import { LYGEntrySchoolModel } from 'src/lyg/pages/entries/shared/LYGSchoolGamesEntryModel';
import { LYGEntryQuestionsStep } from 'src/lyg/pages/entries/shared/LYGEntryQuestionStep';
import { LYGSchoolEntryDemographicData } from 'src/lyg/pages/entries/shared/LYGSchoolEntryDemographicData';
import { LYGSchoolEntryGeneralData } from 'src/lyg/pages/entries/shared/LYGSchoolEntryGeneralData';
import { Card } from 'src/shared/ui/widgets/Card';

interface LYGSchoolEntryFormProps {
  model: LYGEntrySchoolModel;
}

export const LYGSchoolEntryForm: React.FC<LYGSchoolEntryFormProps> = ({ model }) => {
  return (
    <>
      <Card className='mb-2'>
        <LYGSchoolEntryGeneralData model={model} />
      </Card>
      <Card className='mb-2'>
        <LYGEntryQuestionsStep model={model.generalInfo} showHowSelectSquad={false} />
      </Card>
      <Card className='mb-2'>
        <LYGSchoolEntryDemographicData model={model.demographicData} />
      </Card>
    </>
  );
};
