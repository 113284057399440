import { useAppRoutesContext } from 'src/context/routes';
import { TSNotification, TS_NOTIFICATION_TYPE } from 'src/entities/notifications/domain';

export function useNotificationRoutes(notification: TSNotification): string {
  const { type, fromUser, teamId } = notification;
  const routes = useAppRoutesContext();

  const notificationsRoutesMap: Record<TS_NOTIFICATION_TYPE, string> = {
    playerRequestFeedback: routes.players.playerNotes(fromUser.id as number),
    playerRequestToJoinTeam: routes.teams.team(teamId),
    youHaveUnreadMessages: routes.chat.base,
  };

  return type ? notificationsRoutesMap[type] : '';
}
