import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLYGSchoolsQuery } from 'src/lyg/entities/schools/query';
import { DropdownField } from 'src/shared/ui/inputs/dropdown/DropdownField';
import { DROPDOWN_BLANK_VALUE, DropdownFieldModel } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';

interface SchoolPickerProps {
  model: DropdownFieldModel;
  boroughId?: number | null;
  className?: string;
}

export const SchoolPicker: React.FC<SchoolPickerProps> = observer(({ model, className, boroughId }) => {
  const { data: result } = useLYGSchoolsQuery({
    search: model.debouncedSearchValue,
    limit: 10,
    page: 1,
    boroughId: boroughId ?? undefined,
  });

  useEffect(() => {
    reaction(
      () => model.value.id,
      () => model.setSearchValue(''),
    );
    if (!result) return;
    model.setOptions(
      result.schools.map(({ id, name, postcode, boroughName }) => ({
        id: id.toString(),
        label: `${name} (${postcode}) - ${boroughName}`,
      })),
    );
  }, [model, result]);

  return <DropdownField model={model} className={className} />;
});

export function createSchoolPickerDropdown(props: RequireMinOne<Partial<DropdownFieldModel['props']>, 'i18n'>) {
  return new DropdownFieldModel({
    id: 'school',
    initialValue: props.initialValue ?? DROPDOWN_BLANK_VALUE,
    title: 'School',
    withSearchbox: true,
    localSearchDisabled: false,
    withEmptyOption: false,
    required: true,
    ...props,
    i18n: props.i18n,
  });
}
