import { format } from 'date-fns';
import { IRoundProps, ISeedProps } from 'react-brackets';
import { ScheduleDate } from 'src/pages/competitions/schedule/ui/ScheduleFomModel';

export const COMPETITION_STATUS = {
  entered: 'ENTERED',
  notEntered: 'NOT ENTERED',
} as const;
export type COMPETITION_STATUS = ValueOf<typeof COMPETITION_STATUS>;

export const COMPETITION_CATEGORY_TYPE = {
  men: 'men',
  women: 'women',
} as const;
export type COMPETITION_CATEGORY_TYPE = ValueOf<typeof COMPETITION_CATEGORY_TYPE>;

export interface Competition {
  id: number;
  name: string;
  sportName: string;
  sportId: number;
  sportIcon: string | null;
  eventId: number;
  venueId: number;
  venueName: string;
  categoryType: COMPETITION_CATEGORY_TYPE;
  categoryAgeGroup: string;
  minNumberOfPlayersPerTeam: number;
  maxNumberOfPlayersPerTeam: number;
  entriesLimit: number;
  eventStartDate: Date;
  eventEndDate: Date;
  dates: ScheduleDate[];
  breakTime: number;
  numberOfCourts: number;
  spreadGamesEvenlyBetweenDates: boolean;
  minDobLimit: Date | null;
  maxDobLimit: Date | null;
  status: COMPETITION_STATUS | null;
  entriesCount: number | null;
  matchupFrequency: number;
  matchDuration: number;
  pointsForWin: number;
  pointsForLoss: number;
  isDrawAllowed: boolean;
  pointsForDraw: number;
  isWalkoverAllowed: boolean;
  pointsForAwardedWalkover: number;
  pointsForConcedingWalkover: number;
  showTimeInSchedule: boolean;
  pointsForWalkoverWin: number;
  numberOfQuarters: number;
  hasStarted: boolean;
  productId: number | null;
  productName: string | null;
}

type CompetitionProps = OnlyData<Competition>;

export class Competition {
  constructor(init: CompetitionProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionPool {
  id: number;
  name: string;
  teams: { id: number; name: string }[];
}

type CompetitionPoolProps = OnlyData<CompetitionPool>;

export class CompetitionPool {
  constructor(init: CompetitionPoolProps) {
    Object.assign(this, init);
  }

  getTeamIds(): Set<number> {
    return new Set(this.teams.map(({ id }) => id));
  }
}

export interface CompetitionGame {
  id: number;
  competitionId: number;
  date: Date;
  teamAId: number;
  teamAName: string;
  teamAScore: number;
  teamBId: number;
  teamBName: string;
  teamBScore: number;
  teamATimeout: number;
  teamBTimeout: number;
  court: number;
  timeoutPerTeam: number;
  matchDuration: number;
  timeoutDuration: number;
  scoreBreakdown: {
    teamAScore: number;
    teamBScore: number;
    period: number;
  }[];
}

type CompetitionGameProps = OnlyData<CompetitionGame>;

export class CompetitionGame {
  static readonly Status = {
    win: 'win',
    lose: 'lose',
    draw: 'draw',
  } as const;

  constructor(init: CompetitionGameProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionTeamParticipants {
  id: number;
  firstName: string;
  lastName: string;
  jerseyNumber?: number | null;
  postcode?: string;
  dob?: Date;
  email?: string;
  result: CompetitionPlayerResult | null;
}

export interface CompetitionGameWithResults {
  gameId: number;
  eventId: number;
  competitionId: number;
  gameDate: string;
  teamATimeout: number;
  teamBTimeout: number;
  teamARebound: number;
  teamBRebound: number;
  timeoutPerTeam: number;
  matchDuration: number;
  timeoutDuration: number;
  team1: CompetitionTeamWithParticipants;
  team2: CompetitionTeamWithParticipants;
  venueId: number;
  courtNumber: number;
  venue: {
    venueId: number;
    venueAddress1: string;
    venueCity: string;
    venuePostcode: string;
    venueCountry: null;
  };
}

type CompetitionGameWithResultsProps = OnlyData<CompetitionGameWithResults>;

export class CompetitionGameWithResults {
  constructor(init: CompetitionGameWithResultsProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionTeamWithParticipants {
  id: number;
  competitionTeamId: number;
  competitionTeamName: string;
  color: string;
  playersCount: number;
  participants: CompetitionTeamParticipants[];
}

type CompetitionTeamWithParticipantsProps = OnlyData<CompetitionTeamWithParticipants>;

export class CompetitionTeamWithParticipants {
  constructor(init: CompetitionTeamWithParticipantsProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionPlayerResult {
  userId: number;
  firstName: string;
  lastName: string;
  id: number;
  totalPoints: number | null;
  personalFouls: number | null;
  techFouls: number | null;
  unsportmanFouls: number | null;
  disqualifyingFouls: number | null;
  assists: number | null;
  steals: number | null;
  blocks: number | null;
  dunks: number | null;
  turnovers: number | null;
  offensiveRebounds: number | null;
  defensiveRebounds: number | null;
  totalRebounds: number | null;
  ftm: number | null;
  fta: number | null;
  ptm2: number | null;
  pta2: number | null;
  ptm1: number | null;
  pta1: number | null;
  buzzerBeater: number | null;
  buzzerBeater2PT: number | null;
}

type CompetitionPlayerResultsProps = OnlyData<CompetitionPlayerResult>;

export class CompetitionPlayerResult {
  constructor(init: Partial<CompetitionPlayerResultsProps>) {
    this.firstName = init.firstName || '';
    this.lastName = init.lastName || '';
    this.userId = init.userId || 0;

    Object.assign(
      this,
      {
        id: 0,
        totalPoints: 0,
        personalFouls: 0,
        assists: 0,
        steals: 0,
        blocks: 0,
        dunks: 0,
        turnovers: 0,
        offensiveRebounds: 0,
        defensiveRebounds: 0,
        totalRebounds: 0,
        ftm: 0,
        fta: 0,
        ptm2: 0,
        pta2: 0,
        ptm1: 0,
        pta1: 0,
        buzzerBeater: 0,
        buzzerBeater2PT: 0,
      },
      init,
    );
    Object.assign(this, init);
  }
}

export type CompetitionGameStatus = ValueOf<keyof typeof CompetitionGame.Status>;
export interface CompetitionPoolSchedule {
  id: number;
  name: string;
  games: CompetitionGame[];
}

export interface CompetitionLeaderboardTeam {
  id: number;
  poolName: string;
  poolId: number;
  teamName: string;
  teamId: number;
  points: number;
  wins: number;
  losses: number;
  draws: number;
  pointsDifference: number;
  gamesPlayed: number;
}

type CompetitionLeaderboardRecordProps = OnlyData<CompetitionLeaderboardTeam>;

export class CompetitionLeaderboardTeam {
  constructor(init: CompetitionLeaderboardRecordProps) {
    Object.assign(this, init);
  }
}

export type CompetitionLeaderboard = { poolId: number; poolName: string; teams: CompetitionLeaderboardTeam[] }[];

interface CompetitionBracketRound {
  title: string;
  games: CompetitionGame[];
}

export interface CompetitionBracket {
  rounds: CompetitionBracketRound[];
  hasEnded: boolean;
  canMoveToNextPhase: boolean;
}

type CompetitionBracketProps = OnlyData<CompetitionBracket>;

export class CompetitionBracket {
  constructor(init: CompetitionBracketProps) {
    Object.assign(this, init);
  }

  getRenderData(): IRoundProps[] {
    return this.rounds.map<IRoundProps>(round => ({
      title: round.title,
      seeds: round.games.map<ISeedProps>(game => {
        const statusTeamA =
          Number(game.teamAScore) === Number(game.teamBScore)
            ? CompetitionGame.Status.draw
            : Number(game.teamAScore) > Number(game.teamBScore)
            ? CompetitionGame.Status.win
            : CompetitionGame.Status.lose;
        const statusTeamB =
          Number(game.teamAScore) === Number(game.teamBScore)
            ? CompetitionGame.Status.draw
            : Number(game.teamBScore) > Number(game.teamAScore)
            ? CompetitionGame.Status.win
            : CompetitionGame.Status.lose;
        return {
          teams: [
            { name: game.teamAName, score: game.teamAScore, status: statusTeamA },
            { name: game.teamBName, score: game.teamBScore, status: statusTeamB },
          ],
          id: game.id,
          date: `${format(game.date, 'dd/MM/yyyy - HH:mm')} - Court: ${game.court}`,
          game,
        };
      }),
    }));
  }
}

export interface CompetitionTeam {
  id: number;
  name: string;
  playersCount: number;
  color: string;
}

type CompetitionTeamProps = OnlyData<CompetitionTeam>;

export class CompetitionTeam {
  constructor(init: CompetitionTeamProps) {
    Object.assign(this, init);
  }
}

export interface CompetitionGameLogs {
  id: number;
  team: {
    id: number;
    name: string;
    color: string;
  };
  player: {
    id: number;
    firstName: string;
    lastName: string;
    jerseyNumber: number | null;
  } | null;
  action: string;
  time: string;
}

type CompetitionGameLogsProps = OnlyData<CompetitionGameLogs>;

export class CompetitionGameLogs {
  constructor(init: CompetitionGameLogsProps) {
    Object.assign(this, init);
  }
}
