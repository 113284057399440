import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { X } from 'react-feather';
import { useI18nContext } from 'src/context/i18n';
import { cx } from 'src/shared/utils/common';
import css from './ModalDialog.module.scss';

export const MODAL_ROOT_ID = 'modal-root';

interface ModalDialogStackItem {
  dialogElement: HTMLDivElement;
  closeDialog?(): void;
}

/**
 * This is used to handle opening a dialog from an already opened dialog.
 */
export const ModalDialogContext = React.createContext<ModalDialogStackItem[]>([]);

interface ModalDialogProps {
  className?: string;
  subtitle?: string | ReactNode;
  title?: string | ReactNode;
  modalContentClassName?: string;
  customWidth?: string;
  closeDialog?(): void;
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  children,
  className,
  subtitle,
  title,
  closeDialog,
  modalContentClassName = '',
  customWidth,
}) => {
  const i18n = useI18nContext();
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const modalDialogs = React.useContext(ModalDialogContext);

  /**
   * Important to use useLayoutEffect to avoid flashing background color when closing secondary dialogs and reopening
   * previously closed dialog.
   */
  React.useLayoutEffect(() => {
    const dialogElement = dialogRef.current;

    if (dialogElement) {
      modalDialogs.push({ dialogElement, closeDialog });
      if (modalDialogs.length > 1) {
        // Hide previously opened dialog to only show the current dialog.
        // FIXME: Find a fix for dialog order on unexpected re-renders
        //modalDialogs[modalDialogs.length - 2].dialogElement.style.opacity = '0';
      }
    }

    document.body.classList.add('overflow-hidden');

    return () => {
      const currentRefIndex = modalDialogs.findIndex(item => item.dialogElement === dialogElement);
      if (currentRefIndex >= 0) {
        modalDialogs.splice(currentRefIndex, 1);
      }
      if (modalDialogs.length > 0) {
        // Make previously hidden dialog visible.
        modalDialogs[modalDialogs.length - 1].dialogElement.style.opacity = '1';
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    };
  }, [modalDialogs, dialogRef, closeDialog]);

  return ReactDOM.createPortal(
    <div className={cx('position-fixed start-0 left-0 w-100 h-100 px-4 text-break', css.modalWrapper)} ref={dialogRef}>
      <div className={cx('mx-auto', css.modalBox, className)} style={{ width: customWidth ? customWidth : '640px' }}>
        <div
          className={cx(
            'd-flex align-items-start justify-content-between',
            css.modalHeader,
            !!title && 'w-100',
            !title && !subtitle && !closeDialog && 'p-0',
          )}
        >
          <div className='w-100'>
            {title && <div className='fs-6 ts-fw-500 me-2 w-100'>{title}</div>}
            {subtitle && <div className='ts-fs-13 ts-text-light mt-2'>{subtitle}</div>}
          </div>
          {closeDialog && (
            <button
              aria-label={i18n.t('shared.phrases.closeModalDialog')}
              className='bg-transparent p-0'
              title={i18n.t('shared.phrases.closeModalDialog')}
              type='button'
              onClick={closeDialog}
            >
              <X className='d-block' />
            </button>
          )}
        </div>
        <div className={cx(css.modalContent, modalContentClassName)}>{children}</div>
      </div>
    </div>,
    document.getElementById(MODAL_ROOT_ID) as HTMLElement,
  );
};

// TODO: implement a11y to focus on dialog once opened and listen to Escape key to close
