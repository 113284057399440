import { format } from 'date-fns';
import { IRoundProps, ISeedProps } from 'react-brackets';
import { LYGCompetitionGame } from 'src/lyg/entities/competitions/LYGCompetitionGame';

interface LYGCompetitionBracketRound {
  title: string;
  games: LYGCompetitionGame[];
}

export interface LYGCompetitionBracket {
  rounds: LYGCompetitionBracketRound[];
  hasEnded: boolean;
  canMoveToNextPhase: boolean;
}

type LYGCompetitionBracketProps = OnlyData<LYGCompetitionBracket>;

export class LYGCompetitionBracket {
  constructor(init: LYGCompetitionBracketProps) {
    Object.assign(this, init);
  }

  getRenderData(): IRoundProps[] {
    return this.rounds.map<IRoundProps>(round => ({
      title: round.title,
      seeds: round.games.map<ISeedProps>(game => {
        const statusTeamA =
          Number(game.teamAScore) === Number(game.teamBScore)
            ? LYGCompetitionGame.Status.draw
            : Number(game.teamAScore) > Number(game.teamBScore)
            ? LYGCompetitionGame.Status.win
            : LYGCompetitionGame.Status.lose;
        const statusTeamB =
          Number(game.teamAScore) === Number(game.teamBScore)
            ? LYGCompetitionGame.Status.draw
            : Number(game.teamBScore) > Number(game.teamAScore)
            ? LYGCompetitionGame.Status.win
            : LYGCompetitionGame.Status.lose;
        return {
          teams: [
            { name: game.teamAName, score: game.teamAScore, status: statusTeamA },
            { name: game.teamBName, score: game.teamBScore, status: statusTeamB },
          ],
          id: game.id,
          date: `${format(game.date, 'dd/MM/yyyy - HH:mm')} - Court: ${game.court}`,
          game,
        };
      }),
    }));
  }
}
