import React from 'react';
import ReactDOM from 'react-dom';
import { MoreVertical } from 'react-feather';
import { useI18nContext } from 'src/context/i18n';
import { useOnClickOutside } from 'src/shared/hooks/useOnClickOutside';
import { cx } from 'src/shared/utils/common';
import css from './DropdownButton.module.scss';
import { useAppRoutesContext } from 'src/context/routes';
import { useNavigate } from 'react-router-dom';
import { CurrentUser } from 'src/context/authContext/domain';
import { CURRENT_USER_ROLE } from 'src/context/authContext/domain';
import { AuthContextProvider, useAuthContext } from 'src/context/authContext';
import { usePusher } from 'src/features/pusher/usePusher';
import { useQueryClient } from 'react-query';
import { LogOut, Layout, CreditCard, User, Settings } from 'react-feather';
import { FullScreenSpinner } from 'src/shared/ui/layout/FullScreenSpinner';
import { clearLocalStorage, deleteAllCookies } from 'src/context/authContext/utils';
import Swap from 'src/shared/ui/buttons/SwapIcon';

const LogoutButtonView: React.FC = () => {
  const { logout } = useAuthContext();
  const { onClear } = usePusher();
  const navigate = useNavigate();
  const routes = useAppRoutesContext();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = React.useState(false);

  const onLogout = async () => {
    setIsLoading(true);
    document.body.classList.add('overflow-hidden');

    try {
      await onClear();
    } catch (e) {
      console.error(e);
    }
    queryClient.invalidateQueries();
    queryClient.clear();
    clearLocalStorage();
    deleteAllCookies();
    const cacheKeys = await window.caches?.keys();
    if (cacheKeys) cacheKeys.forEach(key => window.caches.delete(key));
    if ('Intercom' in window) window.Intercom('shutdown');
    logout();

    document.body.classList.remove('overflow-hidden');
    navigate(routes.auth.signIn);
  };

  return (
    <>
      <button
        className={cx(
          'w-100 px-4 text-nowrap ts-fw-500 ts-fs-14 d-flex gap-2 align-items-center',
          css.accountDropdownItem,
        )}
        type='button'
        onClick={onLogout}
      >
        <LogOut className={css.menuIcon} /> Logout
      </button>
      {isLoading && <FullScreenSpinner />}
    </>
  );
};

interface DropdownButtonOption {
  label: JSX.Element | string;
  disabled?: boolean;
  onClick(): void;
}

interface DropdownButtonProps {
  options: (DropdownButtonOption | false)[];
  currentUser: CurrentUser;
}

function getMenuPosition(buttonRef: HTMLDivElement, menuWidth: number): { left: number; top: number } {
  const btnBound = buttonRef.getBoundingClientRect();

  const offset = { left: menuWidth - btnBound.width, top: -btnBound.height - window.scrollY };
  offset.left += 10;

  return { left: btnBound.left - offset.left, top: btnBound.top - offset.top };
}

export const AccountDropdownButton: React.FC<DropdownButtonProps> = ({ options, currentUser, children }) => {
  const i18n = useI18nContext();
  const [opened, setOpened] = React.useState(false);
  const [wrapperRef, setWrapperRef] = React.useState<HTMLDivElement | null>(null);
  const btnRef = useOnClickOutside(opened, () => setOpened(false), [wrapperRef]);

  const navigate = useNavigate();
  const routes = useAppRoutesContext();

  function setPosition(menuRef: HTMLDivElement | null) {
    if (!menuRef || !btnRef.current) return;
    const position = getMenuPosition(btnRef.current, menuRef.getBoundingClientRect().width);
    menuRef.style.top = `${position.top}px`;
    menuRef.style.left = `${position.left}px`;
  }

  const hasOptions = React.useMemo(() => options.filter(Boolean).length > 0, [options]);

  const canViewBillingAndWebsite = [CURRENT_USER_ROLE.headCoach, CURRENT_USER_ROLE.admin].includes(
    currentUser.currentRole,
  );

  return (
    <div className='position-relative' ref={btnRef}>
      <button className='w-100 h-100 p-0 bg-transparent' type='button' onClick={() => setOpened(!opened)}>
        {children || <MoreVertical className={css.triggerIcon} />}
      </button>
      {opened &&
        ReactDOM.createPortal(
          <div
            className={cx('position-absolute py-3', css.dropdownPanel)}
            ref={ref => {
              setWrapperRef(ref);
              setPosition(ref);
            }}
          >
            <button
              className={cx(
                'w-100 px-4 text-nowrap ts-fw-500 ts-fs-14 d-flex gap-2 align-items-center',
                css.accountDropdownItem,
              )}
              type='button'
              onClick={() => {
                setOpened(!opened);
                navigate(routes.coaches.coach(currentUser.id));
              }}
            >
              <User /> My Profile
            </button>

            {canViewBillingAndWebsite ? (
              <>
                <button
                  className={cx(
                    'w-100 px-4 text-nowrap ts-fw-500 ts-fs-14 d-flex gap-2 align-items-center',
                    css.accountDropdownItem,
                  )}
                  type='button'
                  onClick={() => {
                    setOpened(!opened);
                    navigate(routes.clubWebsite.base);
                  }}
                >
                  <Layout /> Website
                </button>
                <button
                  className={cx(
                    'w-100 px-4 text-nowrap ts-fw-500 ts-fs-14 d-flex gap-2 align-items-center',
                    css.accountDropdownItem,
                  )}
                  type='button'
                  onClick={() => {
                    setOpened(!opened);
                    navigate(routes.billing.base);
                  }}
                >
                  <CreditCard /> Billing
                </button>
              </>
            ) : (
              ''
            )}

            <button
              className={cx(
                'w-100 px-4 text-nowrap ts-fw-500 ts-fs-14 d-flex gap-2 align-items-center',
                css.accountDropdownItem,
              )}
            >
              <Swap /> Swap accounts
            </button>
            <div className={css.menuSeparator} />
            {hasOptions ? (
              options
                .filter(Boolean)
                .map((opt, index) => {
                  const option = opt as DropdownButtonOption;
                  return (
                    <button
                      className={cx(
                        'w-100 px-4 text-nowrap ts-fw-500 ts-fs-13 d-flex',
                        css.accountDropdownItem,
                        option.disabled && css.disabled,
                      )}
                      type='button'
                      onClick={() => {
                        setOpened(!opened);
                        option.onClick();
                      }}
                      disabled={option.disabled}
                      key={index}
                    >
                      {option.label}
                    </button>
                  );
                })
                .concat(<div className={css.menuSeparator} />)
            ) : (
              <span className={cx('w-100 px-4 text-nowrap ts-fw-500 ts-fs-13 d-flex', css.dropdownItem)}>
                {i18n.t('shared.phrases.noOptions')}
              </span>
            )}

            <button
              className={cx(
                'w-100 px-4 text-nowrap ts-fw-500 ts-fs-14 d-flex pt-3 gap-2 align-items-center',
                css.accountDropdownItem,
              )}
              type='button'
              onClick={() => {
                setOpened(!opened);
                navigate(routes.settings.base);
              }}
            >
              <Settings /> Settings
            </button>
            <AuthContextProvider>
              <LogoutButtonView />
            </AuthContextProvider>
          </div>,
          document.getElementById('root') as Element,
        )}
    </div>
  );
};
