import { useQuery, UseQueryResult } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { getNotifications } from 'src/entities/notifications/api/getNotifications';
import { TSNotification, TS_NOTIFICATION_TYPE } from 'src/entities/notifications/domain';
import { NOTIFICATIONS_QUERY_KEYS } from 'src/entities/notifications/query/queryKeys';

interface QueryResponse {
  notifications: TSNotification[];
  totalCount: number;
}

export function useNotificationsQuery(page: number): UseQueryResult<QueryResponse, Error> {
  const { token } = useAuthContext();

  return useQuery<QueryResponse, Error>(
    NOTIFICATIONS_QUERY_KEYS.filters({ page }),
    async () => {
      const { response } = await getNotifications({ page }, token);
      return {
        notifications: response.data.map(notification => ({
          body: notification.notification_body,
          id: notification.notification_id,
          title: notification.notification_title,
          created: new Date(notification.created_at),
          type: notification.notification_type as TS_NOTIFICATION_TYPE,
          fromUser: {
            id: notification.from_user_id,
            firstName: notification.profile_first_name,
            lastName: notification.profile_last_name,
            avatarUrl: notification.profile_avatar,
          },
          teamId: notification.team_id,
        })),
        totalCount: response.total,
      };
    },
    { staleTime: 1000 * 60 },
  );
}
