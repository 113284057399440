import React, { SVGAttributes } from 'react';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { ChevronDown } from 'react-feather';
import { DropdownFieldModel, DROPDOWN_BLANK_VALUE } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';
import { useOnClickOutside } from 'src/shared/hooks/useOnClickOutside';
import { DropdownPanel } from 'src/shared/ui/inputs/dropdown/DropdownPanel';
import { DropdownOption } from 'src/shared/ui/inputs/dropdown/DropdownOption';
import { useI18nContext } from 'src/context/i18n';
import { RippleAnimation } from 'src/shared/ui/assets/RippleAnimation';
import css from './DropdownField.module.scss';
import DatePicker, { registerLocale } from 'react-datepicker';

interface DropdownFieldProps {
  className?: string;
  isSmall?: boolean;
  icon?: React.ComponentType<SVGAttributes<SVGElement>>;
  model: DropdownFieldModel;
  disabled?: boolean;
  isVenueDropdown?: boolean;
  onChange?(value: string): void;
}
const NoOptions: React.FC<{ onClick: () => void; isVenueDropdown: boolean }> = ({ onClick, isVenueDropdown }) => {
  const i18n = useI18nContext();
  return (
    <div className='p-3 ts-fs-13 fst-italic ts-text-light'>
      {i18n.t('shared.fields.messages.noDropdownOptions')}
      {isVenueDropdown && (
        <strong
          onClick={e => {
            e.stopPropagation();
            onClick();
          }}
          className='ts-color-primary'
          style={{ cursor: 'pointer' }}
          role='button'
          onKeyDown={onClick}
          tabIndex={-2}
        >
          , add a new venue
        </strong>
      )}
    </div>
  );
};

export const DropdownField: React.FC<DropdownFieldProps> = observer(
  ({ onChange, className, model, icon, isSmall, isVenueDropdown = false }) => {
    const {
      disabled,
      isLoading,
      opened,
      options,
      value,
      close,
      open,
      searchValue,
      setSearchValue,
      setDefaultValue,
      setValue,
      localSearchDisabled,
      withSearchbox,
      isFirstOptionDefault,
      defaultValue,
    } = model;
    const fieldRef = useOnClickOutside(opened, close);

    const filteredOptions = (
      localSearchDisabled
        ? options
        : options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase()))
    ).filter((option, index, self) => self.findIndex(({ id }) => id === option.id) === index);

    React.useEffect(() => {
      if (isFirstOptionDefault && defaultValue.id === DROPDOWN_BLANK_VALUE.id) {
        const option = filteredOptions.length > 0 ? filteredOptions[0] : defaultValue;
        setDefaultValue(option);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstOptionDefault, JSON.stringify(defaultValue), setDefaultValue, JSON.stringify(filteredOptions)]);

    const Icon = icon ?? null;

    return (
      <div
        className={cx(
          'position-relative',
          css.field,
          opened && css.fieldFocused,
          disabled && css.fieldDisabled,
          className,
        )}
        ref={fieldRef}
      >
        <button
          aria-label={value.label}
          className={cx('d-flex align-items-center w-100 px-3 py-2', css.fieldValue)}
          disabled={disabled}
          title={value.label}
          type='button'
          onClick={opened ? close : open}
        >
          <div className='flex-grow-1 ts-text-overflow text-start ts-text'>
            {Icon && <Icon className={cx(css.icon, 'me-2')} />}
            {value.label}
          </div>
          <div className={cx('ms-2', css.icon, opened && css.rotateIcon)}>
            <ChevronDown />
          </div>
        </button>

        <DropdownPanel
          opened={opened}
          withSearchbox={withSearchbox}
          onChange={val => setSearchValue(val)}
          value={searchValue}
          isSmall={isSmall}
        >
          {isLoading ? (
            <RippleAnimation className='d-block my-3 mx-auto ts-color-primary' />
          ) : options.length > 0 ? (
            <>
              {filteredOptions.length ? (
                filteredOptions.map(option => (
                  <DropdownOption
                    key={option.id}
                    option={option}
                    selected={option.id === value.id}
                    onSelect={selectedOption => {
                      setValue(selectedOption);
                      onChange?.(selectedOption.id);
                      close();
                    }}
                  />
                ))
              ) : (
                <NoOptions
                  onClick={() => {
                    model.close();
                    model.allowNewOption();
                  }}
                  isVenueDropdown={isVenueDropdown}
                />
              )}
            </>
          ) : (
            <NoOptions
              onClick={() => {
                model.close();
                model.allowNewOption();
              }}
              isVenueDropdown={isVenueDropdown}
            />
          )}
        </DropdownPanel>
      </div>
    );
  },
);

// TODO: implement method to remove selected option, especially for non-required dropdowns
// TODO: show error on blur
