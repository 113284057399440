import { InputFieldValidator } from 'src/shared/ui/inputs/InputFieldModel';

export function stringIsEmailAddress(email: string): boolean {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function stringIsOnlyAlphabetic(value: string): boolean {
  const regex = /^[A-Za-z]+$/;
  return regex.test(value);
}

// Babatunde Akerele returns BA
export function abbreviateString(value: string, maxLength?: number): string {
  const abbreviation = value
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('');
  return maxLength ? abbreviation.slice(0, maxLength) : abbreviation;
}

export const truncateString = (text: string, length: number) => {
  return text && text.length > length ? `${text.substring(0, length)}...` : text;
};

export function stringFromJoinedParts(values: (string | null | undefined)[], separator = ', '): string {
  return values.reduce<string>((result, value, index) => {
    if (typeof value === 'string') {
      const trimmed = value.trim();
      if (trimmed.length > 0) {
        result += `${index > 0 ? separator : ''}${trimmed}`;
      }
    }
    return result;
  }, '');
}

export function toTitleCase(string: string): string {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function stringIsUrl(string: string): boolean {
  if (string === '') return true;
  const regex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  return regex.test(string);
}

export function stringIsUrlValidator(): InputFieldValidator<string> {
  return {
    message: 'Invalid URL',
    validate: value => stringIsUrl(value),
  };
}

export function abbreviateFirstName(fullName: string): string {
  const firstName = fullName.split(' ')[0];
  const otherNames = fullName.split(' ').slice(1);
  return `${firstName[0]}. ${otherNames}`;
}
