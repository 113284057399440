import React from 'react';
import { cx } from 'src/shared/utils/common';
import css from './DropdownPanel.module.scss';
import { DropdownSearchbox } from 'src/shared/ui/inputs/dropdown/DropdownSearchbox';

interface DropdownPanelProps {
  className?: string;
  opened: boolean;
  withSearchbox?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  isSmall?: boolean;
}

export const DropdownPanel: React.FC<DropdownPanelProps> = ({
  className,
  children,
  opened,
  withSearchbox,
  onChange,
  value,
  isSmall,
}) => {
  if (!opened) {
    return null;
  }

  return (
    <div className={cx('position-absolute w-100', css.dropdownPanel, className)}>
      {withSearchbox && <DropdownSearchbox onChange={onChange} value={value} />}
      <div className={cx(css.container, isSmall && css.small)}>{children}</div>
    </div>
  );
};
