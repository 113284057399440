export const enum SUBSCRIPTION_PLAN {
  academy = 'academy',
  club = 'club',
  free = 'free',
}

export type PlanBase = keyof typeof SUBSCRIPTION_PLAN;

export const stripePaymentId = new Map([
  [
    SUBSCRIPTION_PLAN.club,
    new Map([
      ['monthly', 'dR63cx92HcOs7ra4gk'],
      ['yearly', 'fZe00l92H29O6n6dQV'],
    ]),
  ],
  [
    SUBSCRIPTION_PLAN.academy,
    new Map([
      ['monthly', '4gwdRbceT7u86n628i'],
      ['yearly', '6oE8wRen1cOscLu14f'],
    ]),
  ],
]);
