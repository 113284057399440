export function numberIsValid(value: number | string | undefined | null): boolean {
  return (
    (typeof value === 'number' && !Number.isNaN(value)) ||
    (!Number.isNaN(parseInt(value as string, 10)) && !Number.isNaN(Number(value)))
  );
}

export function numberFromString(value: string): number | undefined {
  return numberIsValid(value) ? Number(value) : undefined;
}

export function ageFromDateOfBirth(date: Date): number {
  const today = new Date();
  const monthsDiff = today.getMonth() - date.getMonth();
  const sameMonthBeforeDate = monthsDiff === 0 && today.getDate() < date.getDate();

  return today.getFullYear() - date.getFullYear() - (monthsDiff < 0 || sameMonthBeforeDate ? 1 : 0);
}

// This function returns the percentage of the smaller number over the larger number.
export function numberPercentage(value1: number, value2: number): number {
  return Math.round((Math.min(value1, value2) / Math.max(value1, value2) || 1) * 100);
}

// This function returns the percentage change between two numbers.
export function getPercentageChange(prevValue: number, currentValue: number): number {
  if (prevValue === 0) return currentValue > 0 ? 100 : 0;
  return Math.round(((currentValue - prevValue) / Math.abs(prevValue)) * 100);
}

export function numberWithThousandSuffix(value: number): string {
  if (value < 1000) {
    return String(value);
  }

  const stringValue = String(value);
  const hundreds = stringValue.substr(stringValue.length - 3, 1);
  const hundredsValue = hundreds !== '0' ? `.${hundreds}` : '';

  return `${stringValue.slice(0, stringValue.length - 3)}${hundredsValue}K`;
}

export function numberHasOnlyPositiveDigits(value: string | number): boolean {
  if (!value) return true;
  return /^\+?(0|[1-9]\d*)$/.test(String(value));
}

export function numberWithFixedDecimals(value: number | undefined): string {
  if (!value) return '-';

  const hasDecimals = value % 1 !== 0;
  if (hasDecimals) return value.toFixed(2);

  return value.toString(10);
}

export function numbersAverage(arr: number[]): number {
  return arr.reduce((acc, curr) => acc + curr, 0) / arr.length;
}

export function formatBytes(value: number): string {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  let n = value;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}
