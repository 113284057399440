import React from 'react';
import { Seed, SeedItem, SeedTeam, IRenderSeedProps } from 'react-brackets';
import { Edit2 } from 'react-feather';
import { useCurrentUserRole } from 'src/context/authContext';
import { LYGCompetitionGame, LYGCompetitionGameStatus } from 'src/lyg/entities/competitions/LYGCompetitionGame';
import { PrimaryInvertedButton } from 'src/shared/ui/buttons';
import { cx } from 'src/shared/utils/common';
import { THEME_COLORS } from 'src/constants';
import css from './LYGBracketMatch.module.scss';

interface TeamItemProps {
  name: string | undefined;
  score: number | undefined;
  status: LYGCompetitionGameStatus;
}

const TeamItem: React.FC<TeamItemProps> = ({ name, score, status }) => {
  return (
    <SeedTeam
      style={{
        justifyContent: 'space-between',
        gap: '18px',
        height: 50,
        backgroundColor: !name
          ? 'white'
          : status === LYGCompetitionGame.Status.win
          ? THEME_COLORS.fourth
          : status === LYGCompetitionGame.Status.lose
          ? THEME_COLORS.first
          : THEME_COLORS.third,
      }}
    >
      <span className='text-start'>{name || 'TBD '}</span>
      <span>{score || 0}</span>
    </SeedTeam>
  );
};

interface LYGBracketMatch extends IRenderSeedProps {
  disableActions?: boolean;
  onEditScore(game: LYGCompetitionGame): void;
  onEditDate(game: LYGCompetitionGame): void;
}

export const LYGBracketMatch: React.FC<LYGBracketMatch> = ({
  seed,
  breakpoint,
  disableActions,
  onEditScore,
  onEditDate,
}) => {
  const { isLygAdmin, isAdmin } = useCurrentUserRole();
  const game = seed.game as LYGCompetitionGame;

  return (
    <Seed mobileBreakpoint={breakpoint}>
      <SeedItem style={{ backgroundColor: 'white', color: 'black', borderRadius: '10px', overflow: 'hidden' }}>
        <button
          className={cx(css.match, 'p-0 w-100')}
          onClick={() => !disableActions && (isAdmin || isLygAdmin) && onEditScore(game)}
          disabled={!seed.teams[0].name || !seed.teams[1].name}
        >
          <TeamItem name={seed.teams[0]?.name} score={seed.teams[0]?.score} status={seed.teams[0]?.status} />
          <div className='ts-h-divider w-100' />
          <TeamItem name={seed.teams[1]?.name} score={seed.teams[1]?.score} status={seed.teams[1]?.status} />
        </button>
      </SeedItem>
      <p className='mt-2 mb-0'>
        {seed.date}
        {(isLygAdmin || isAdmin) && (
          <PrimaryInvertedButton onClick={() => !disableActions && onEditDate(game)} loading={disableActions}>
            <Edit2 className='align-middle' />
          </PrimaryInvertedButton>
        )}
      </p>
    </Seed>
  );
};
