import { NavItemId } from 'src/features/appLayout/navigationItems';
import { useInvitedPlayersQuery } from 'src/pages/players/list/queries/invitedPlayersQuery';

export function useCheckNotificationStatus(): Record<NavItemId, boolean> {
  const { data: pendingPlayers } = useInvitedPlayersQuery({ filters: {} });

  return {
    clubs: false,
    coaches: false,
    entries: false,
    chat: false,
    divider: false,
    events: false,
    exercises: false,
    gameStats: false,
    players: false,
    reports: false,
    teams: !!pendingPlayers?.totalCount,
    trainingGoals: false,
    trainingStats: false,
    unlockAllFeatures: false,
    videoClips: false,
    videos: false,
    videoTags: false,
    payments: false,
  };
}
