import { useEffect, useState } from 'react';

export const MINIMUM_SCREEN_WIDTH_FOR_ANALYSIS = 800;

export const useDisableAnalysis = () => {
  const [isAnalysisDisabled, setIsAnalysisDisabled] = useState(false);

  const onScreenWidthCheck = () => {
    setIsAnalysisDisabled(window.innerWidth < MINIMUM_SCREEN_WIDTH_FOR_ANALYSIS);
  };

  useEffect(() => {
    onScreenWidthCheck();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onScreenWidthCheck);
    window.addEventListener('change', onScreenWidthCheck);

    return () => {
      window.removeEventListener('resize', onScreenWidthCheck);
      window.removeEventListener('change', onScreenWidthCheck);
    };
  });

  return { isAnalysisDisabled };
};
