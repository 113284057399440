import { localStorageKeys } from 'src/context/authContext/localStorageKeys';

interface ParseJWTReturn {
  userId: number;
}

export function parseJSONWebToken(token: string): ParseJWTReturn | null {
  const tokenPayload = token.split('.')[1];
  if (!tokenPayload) return null;

  const jsonPayload = JSON.parse(atob(tokenPayload));
  return {
    userId: jsonPayload.sub,
  };
}

export function getJWT(): string {
  return localStorage.getItem('TS_JWT') || '';
}

export function removeJWT(): void {
  localStorage.removeItem('TS_JWT');
}

export function clearLocalStorage(): void {
  localStorageKeys.forEach(key => localStorage.removeItem(key));
}

export function deleteAllCookies(): void {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
