import { useQuery, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'src/context/authContext/apis/getCurrentUser';
import { getIntercomHash } from 'src/context/authContext/apis/getIntercomHash';
import { CurrentUser, CURRENT_USER_ROLE } from 'src/context/authContext/domain';
import { getJWT, parseJSONWebToken } from 'src/context/authContext/utils';
import { useAppRoutesContext } from 'src/context/routes';
import { getTeams } from 'src/entities/teams/apis/getTeams';
import { teamCacheKey } from 'src/entities/teams/ui/TeamPicker';
import { ApiError } from 'src/shared/utils/apiError';
import { stringFromJoinedParts } from 'src/shared/utils/strings';
import { useIsLeagueContext } from 'src/context/isLeagueContext';

export const AUTH_CONTEXT_QUERY_KEY = (id: number) => ['authContext', id];

export function useAuthContextQuery(userId?: number): UseQueryResult<CurrentUser, ApiError> {
  const navigate = useNavigate();
  const routes = useAppRoutesContext();
  const token = getJWT();
  const parsedToken = parseJSONWebToken(token);
  const currentUserId = userId || parsedToken?.userId;
  const { updateIsLeague } = useIsLeagueContext();

  return useQuery<CurrentUser, Error>(
    AUTH_CONTEXT_QUERY_KEY(currentUserId as number),
    async () => {
      const user = await getCurrentUser(currentUserId as number, { token, clubId: null, userRole: null });
      const { response: intercomHash } = await getIntercomHash({ token, clubId: null, userRole: null });

      const currentUser = new CurrentUser({
        avatarUrl: user[0].profile_avatar,
        email: user[0].email,
        name: stringFromJoinedParts([user[0].profile_first_name, user[0].profile_last_name], ' '),
        emailReminders: Boolean(user[0].email_personal),
        emailNewsletter: Boolean(user[0].email_newsletter),
        emailTeamManagement: Boolean(user[0].email_team),
        emailGlobal: Boolean(user[0].email_notifications),
        id: currentUserId as number,
        intercomHash,
        onboardingCompleted: Boolean(user[0].onboarding_coach),
        onboardingPaymentCompleted: Boolean(user[0].onboarding_payment),
        clubId: user[0].org_id,
        clubName: user[0].org_name,
        gender: user[0].profile_gender || 'N/A',
        paymentPriceId: user[0].payment_price_id || '',
        paymentSubscriptionId: user[0].payment_subscription_id || '',
        paymentQuantity: user[0].payment_quantity || NaN,
        pendingVerification: Boolean(user[0].email_confirmation),
        paymentPlan: user[0].payment_plan,
        paymentAmount: user[0].payment_amount,
        paymentFrequency: user[0].payment_frequency,
        pushNewsletter: Boolean(user[0].push_newsletter),
        pushReminders: Boolean(user[0].push_personal),
        pushGlobal: Boolean(user[0].push_notifications),
        pushTeamManagement: Boolean(user[0].push_team),
        joined: new Date(user[0].created_at),
        role: user[0].profile_role as CURRENT_USER_ROLE,
        sport: user[0].profile_sport || '',
        teamId: user[0].team_id || NaN, // FIXME: setting to NaN might cause issues
        boroughId: user[0].lyg_borough_id,
        boroughName: user[0].lyg_borough_name,
        schoolId: user[0].lyg_school_id,
        schoolName: user[0].lyg_school_name,
        boroughLogo: user[0].lyg_borough_image,
        permissions:
          user[0].permissions?.map(permission => ({
            clubName: permission.org_name,
            clubId: permission.org_id,
            userRole: permission.user_role as CURRENT_USER_ROLE,
            sportName: permission.sport_name,
            sportId: permission.sport_id,
            isDefault: Boolean(permission.default_org_flag),
            paymentAmount: permission.payment_amount ?? 0,
            paymentFrequency: permission.payment_frequency?.toString() ?? null,
            paymentPriceId: permission.payment_price_id ?? '',
            paymentSubscriptionId: permission.payment_subscription_id ?? '',
            paymentQuantity: permission.payment_quantity ?? 0,
            paymentPlan: permission.payment_plan || 'free',
            clubAvatar: permission.org_avatar,
            clubSiteCode: permission.org_site_code ?? '',
          })) || [],
      });

      return currentUser;
    },
    {
      async onSuccess(res) {
        const currentClub = res?.permissions.find(club => club.clubId === res.currentClub);
        const isLeague = currentClub?.clubName.toLowerCase().includes('league');
        updateIsLeague(isLeague as boolean);

        const CCRoles = Object.values(CURRENT_USER_ROLE);
        if (!CCRoles.includes(res.currentRole)) return;

        const currPickedTeam = localStorage.getItem(teamCacheKey);
        if (currPickedTeam) return;
        const { data: teams } = await getTeams({ token: { token, clubId: res.currentClub, userRole: res.actualRole } });
        const team = teams.at(0);
        if (!team) return;
        localStorage.setItem(teamCacheKey, JSON.stringify({ id: team.team_id, label: team.team_name }));
      },
      onError(err) {
        if (err instanceof ApiError && err.code === 401) navigate(routes.auth.signIn);
      },
      enabled: Boolean(token),
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    },
  );
}
